import React, { Component } from "react";
import { Dropdown, Card, Image } from "semantic-ui-react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./RegionalPlayer.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class RegionalPlayer extends Component {
    constructor() {
        super();
        this.state = {
            profiles: "",
            warningModalOpen: false,
            warningText: "",
        };
    }

    componentDidMount = () => {
        this.getRegionalProfileData();
    };

    getRegionalProfileData = () => {
        fetch(Common.backendUrl + "/api/player/regional/profile/" + "전라북도")
            .then((res) => {
                if (res.status > 400) {
                    this.handleWarningModalOpen("error❗❗");
                }
                return res.json();
            })
            .then((profiles) => {
                this.setState({
                    profiles,
                });
            });
    };

    getRegionChangeData = (region) => {
        fetch(Common.backendUrl + "/api/player/regional/profile/" + region)
            .then((res) => {
                if (res.status > 400) {
                    this.handleWarningModalOpen("error❗❗");
                }
                return res.json();
            })
            .then((profiles) => {
                this.setState({
                    profiles,
                });
            });
    };

    regionChange = (e, { value }) => {
        this.getRegionChangeData(value);
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        const { profiles } = this.state;
        const { t } = this.props;
        let regionOptions = [
            { key: 1, value: t("info_squad.RegionalPlayer.Gangwon-do"), text: t("info_squad.RegionalPlayer.Gangwon-do") },
            { key: 2, value: t("info_squad.RegionalPlayer.Gyeonggi-do"), text: t("info_squad.RegionalPlayer.Gyeonggi-do") },
            { key: 3, value: t("info_squad.RegionalPlayer.Gyeongsangbuk-do"), text: t("info_squad.RegionalPlayer.Gyeongsangbuk-do") },
            { key: 4, value: t("info_squad.RegionalPlayer.Gyeongsangnam-do"), text: t("info_squad.RegionalPlayer.Gyeongsangnam-do") },
            { key: 5, value: t("info_squad.RegionalPlayer.Jeollabuk-do"), text: t("info_squad.RegionalPlayer.Jeollabuk-do") },
            { key: 6, value: t("info_squad.RegionalPlayer.Jeollanam-do"), text: t("info_squad.RegionalPlayer.Jeollanam-do") },
            { key: 7, value: t("info_squad.RegionalPlayer.Chungcheongbuk-do"), text: t("info_squad.RegionalPlayer.Chungcheongbuk-do") },
            { key: 8, value: t("info_squad.RegionalPlayer.Chungcheongnam-do"), text: t("info_squad.RegionalPlayer.Chungcheongnam-do") },
            { key: 9, value: t("info_squad.RegionalPlayer.Seoul_Capital_City"), text: t("info_squad.RegionalPlayer.Seoul_Capital_City") },
            { key: 10, value: t("info_squad.RegionalPlayer.Gwangju_City"), text: t("info_squad.RegionalPlayer.Gwangju_City") },
            { key: 11, value: t("info_squad.RegionalPlayer.Daegu_City"), text: t("info_squad.RegionalPlayer.Daegu_City") },
            { key: 12, value: t("info_squad.RegionalPlayer.Daejeon_City"), text: t("info_squad.RegionalPlayer.Daejeon_City") },
            { key: 13, value: t("info_squad.RegionalPlayer.Busan_City"), text: t("info_squad.RegionalPlayer.Busan_City") },
            { key: 14, value: t("info_squad.RegionalPlayer.Incheon_City"), text: t("info_squad.RegionalPlayer.Incheon_City") },
            { key: 15, value: t("info_squad.RegionalPlayer.Ulsan_City"), text: t("info_squad.RegionalPlayer.Ulsan_City") },
            { key: 16, value: t("info_squad.RegionalPlayer.Jeju-do"), text: t("info_squad.RegionalPlayer.Jeju-do") },

            { key: 17, value: "경기북부", text: "경기북부" },
            { key: 18, value: "경기남부", text: "경기남부" },
            { key: 19, value: "경북 & 대구", text: "경북 & 대구" },
            { key: 20, value: "충청 & 대전", text: "충청 & 대전" },
            { key: 21, value: "전남 & 광주", text: "전남 & 광주" },
        ];
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
                <Topmenu />
                <div className={styles.wrap_div}>
                    <div className={styles.title_div}>
                        <h1>{t("info_squad.RegionalPlayer.Main_Title")}</h1>
                    </div>
                    <Dropdown
                        className={styles.region_drop}
                        onChange={this.regionChange}
                        options={regionOptions}
                        defaultValue={t("info_squad.RegionalPlayer.Jeollabuk-do")}
                        // search={true}
                        selection
                        placeholder={t("info_squad.RegionalPlayer.Region_selection")}
                        fluid
                    />
                    <div className={styles.content_div}>
                        {profiles ? (
                            profiles.map((profile) => (
                                <Card style={{ width: "200px", margin: "20px" }}>
                                    {profile.image_file === null ? <Image src="/images/default_profile.png" alt="profile image" /> : <Image src={profile.image_file} height="200" alt="profile image" />}
                                    <Card.Content>
                                        <Card.Header>{profile.member_text}</Card.Header>
                                        <Card.Description>{profile.member_position.position_text}</Card.Description>
                                        <Card.Description>{profile.team.team_text}</Card.Description>
                                    </Card.Content>
                                </Card>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(RegionalPlayer);
