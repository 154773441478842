import React, { Component } from "react";

import { Button, Modal, Input, Dropdown, Form } from "semantic-ui-react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from "../../../Common";

const daum = window.daum;

class RegionModifiyModalForm extends Component {
    state = {
        regionText: "",
        chiefName: "",
        email: "",
        phone: "",
        provinceId: "",
        regionData: "",
        provinceOptions: "",
        modal_open: false,
        warningModalOpen: false,
        warningText: "",
        teamName: "",
        image_file: "",
        isReLoad: false,
        reg_date: "",
    };

    getProvinceList() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ provinceData: data });
                let arr = [];
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getRegionDetailData() {
        fetch(Common.backendUrl + "/api/region_detail/" + this.props.region_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ regionData: data });
            });
    }

    componentDidMount() {
        this.getProvinceList();
        this.getRegionDetailData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.region_id !== prevProps.region_id) {
            this.getRegionDetailData();
        }
        if (this.props.province_id !== prevProps.province_id) {
            this.setState({ regionData: [{ region_text: "", chief_name: "", email_text: "", phone_text: "" }] });
        }
    }

    handleProvinceChange = (e, { value }) => {
        this.setState({ provinceId: value });
    };

    handleRegionChange = (e, { value }) => {
        this.setState({ regionText: value });
    };

    handleChiefChange = (e, { value }) => {
        this.setState({ chiefName: value });
    };

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;
        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3);
            let middleNum = checkPhone.slice(3, 7);
            let lastNum = checkPhone.slice(7, 11);
            const strPhone = firstNum + "-" + middleNum + "-" + lastNum;
            this.setState({
                phone: strPhone,
            });
        }
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamName: value });
    };

    handleEmailChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            email: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let province_id = 0;

        if (this.state.provinceId === "") {
            province_id = this.props.province_id;
        } else {
            province_id = this.state.provinceId;
        }

        let form_data = new FormData();

        if (this.state.provinceId !== "") {
            form_data.append("province_id", province_id);
        }

        if (this.state.regionText !== "") {
            form_data.append("region_text", this.state.regionText);
        }

        if (this.state.chiefName !== "") {
            form_data.append("chief_name", this.state.chiefName);
        }

        if (this.state.email !== "") {
            form_data.append("email_text", this.state.email);
        }

        if (this.state.phone !== "") {
            form_data.append("phone_text", this.state.phone);
        }

        if (this.state.teamName !== "") {
            form_data.append("team", this.state.teamName);
        }

        if (this.state.image_file !== "") {
            form_data.append("cheif_image", this.state.image_file);
        }
        if (this.state.reg_date !== "") {
            if (this.state.reg_date.length !== 10) {
                this.handleWarningModalOpen("YYYY-mm-DD 형식을 지켜주세요.");
                return;
            }

            form_data.append("reg_date", this.state.reg_date);
        }

        fetch(Common.backendUrl + "/api/region/update/" + this.props.region_id, {
            method: "put",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                this.handleWarningModalOpen("error");
                this.setState({ modal_open: false });
            } else {
                this.handleWarningModalOpen("지부 수정 완료");
                this.setState({
                    modal_open: false,
                    regionText: "",
                    chiefName: "",
                    eamil: "",
                    phone: "",
                    teamName: "",
                    cheif_image: "",
                });
                this.getRegionDetailData();
                this.props.reloadData();
            }
        });
    };

    handleRegDateChange = (e, { value }) => {
        e.preventDefault();

        let formatNum = value;

        // 공백제거
        value = value.replace(/\s/gi, "");

        if (value.length == 8) {
            formatNum = value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
            document.getElementById("reg_date").value = formatNum;
        }
        this.setState({
            reg_date: formatNum,
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.isReLoad) {
            window.location.reload();
        }
    };

    render() {
        let region_text = "";
        let chief_name = "";
        let email_text = "";
        let phone_text = "";
        let team_text = "";
        let parse = "";
        let reg_date = "";

        if (this.state.regionData !== "") {
            region_text = this.state.regionData[0].region_text;
            chief_name = this.state.regionData[0].chief_name;
            email_text = this.state.regionData[0].email_text;
            team_text = this.state.regionData[0].team;
            parse = this.state.regionData[0].phone_text.split("-");
            phone_text = parse[0] + parse[1] + parse[2];
            reg_date = this.state.regionData[0].reg_date;
        }
        return (
            <>
                <Modal size={"tiny"} onClose={() => this.setState({ modal_open: false })} onOpen={() => this.setState({ modal_open: true })} open={this.state.modal_open} trigger={<Button primary>지부수정</Button>}>
                    <Modal.Header>지부정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field required>
                                    <label className="label">지회</label>
                                    <Dropdown placeholder="지회를 선택하세요" selection options={this.state.provinceOptions} name="province" defaultValue={this.props.province_id} onChange={this.handleProvinceChange} fluid required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">지부명</label>
                                    <Input type="text" name="region_text" onChange={this.handleRegionChange} defaultValue={region_text} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">지부장</label>
                                    <Input type="text" name="chief_name" onChange={this.handleChiefChange} defaultValue={chief_name} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">선수단명</label>
                                    <Input
                                        type="text"
                                        name="team_text"
                                        onChange={this.handleTeamChange}
                                        defaultValue={team_text}
                                        // required
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>이메일</span>
                                    </label>
                                    <Input placeholder="dronesoccer@naver.com" type="text" name="email" maxLength="40" defaultValue={email_text} onChange={this.handleEmailChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">전화번호</label>
                                    <Input type="text" name="phone_text" placeholder="전화번호를 입력해주세요(-생략)" onChange={this.handlePhoneChange} defaultValue={phone_text} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">등록일</label>
                                    <Input type="text" id="reg_date" maxLength="10" defaultValue={reg_date.split("T")[0]} placeholder="YYYY-mm-DD" onChange={this.handleRegDateChange} required />
                                </Form.Field>

                                <Button positive type="submit" className="button is-info">
                                    등록하기
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default RegionModifiyModalForm;
