import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import styles from "./Content.module.css";
import Topmenu from "../../Topmenu/Topmenu.js";
import Footer from "../../footer/Footer.js";
import BoardFilesMobile from "../../board/notice/BoardFilesMobile.js";
import BoardHeaderDesktop from "../../board/notice/BoardHeaderDesktop.js";
import queryString from "query-string";
import nl2br from "react-newline-to-break";
import Common from "../../Common";
import { createMedia } from "@artsy/fresnel";
import { withTranslation } from "react-i18next";
import ModalChoiceOne from "../../warning_modal/ModalChoiceOne.js";
import ModalConfirm from "../../warning_modal/ModalConfirm.js";
import EducationApply from "./EducationApply.js";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
  },
});

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardData: {},
      editCommentIndex: null,
      editCommentCheck: false,
      editCommentContent: null,
      queryStringStr: null,
      warningModalOpen: false,
      warningText: "",
      editComment: false,
      warningLocationDelBoard: false,
      warningLocationPleaseLogin: false,
      confirmBoardModalOpen: false,
      confirmText: "",
      isConfirm: false,
      delCommentId: "",
      eduApplyFormOpen: false,
      eduApplyFormContent: "",
    };
    const queryValue = queryString.parse(this.props.location.search);
    let queryStringStr = "";

    if (queryValue.pageNum) {
      queryStringStr = queryStringStr + "?pageNum=" + queryValue.pageNum;
    }

    if (queryValue.column) {
      queryStringStr = queryStringStr + "&column=" + queryValue.column;
    }

    if (queryValue.searchString) {
      queryStringStr =
        queryStringStr + "&searchString=" + queryValue.searchString;
    }

    this.state.queryStringStr = queryStringStr;
  }

  getBoardData = (page) => {
    fetch(Common.backendUrl + "/board/api/board/" + page)
      .then((response) => {
        if (response.status > 400) {
          console.log("error: no data");
          return;
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ boardData: data });
      });
  };

  componentDidMount() {
    this.getBoardData(this.props.match.params.board_id);
    if (sessionStorage.getItem("id") !== null) {
      this.getProfileData(sessionStorage.getItem("id"));
    }
    window.scrollTo(0, 0);
  }

  isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  }

  reformTime(sourceTime) {
    let newDate = new Date(sourceTime);
    let [month, date, year] = newDate.toLocaleDateString("en-US").split("/");
    let timeStr = newDate.toLocaleTimeString();
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    return [year, month, date].join("-") + "  " + timeStr;
  }

  reformTimeDate(sourceTime) {
    let newDate = new Date(sourceTime);
    let [month, date, year] = newDate.toLocaleDateString("en-US").split("/");
    return [year, month, date].join("-");
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { t } = this.props;

    if (sessionStorage.getItem("id") === null) {
      this.handleWarningModalOpen(t("board.notice.Please_log_in"));
      this.setState({
        warningLocationPleaseLogin: true,
      });
    } else if (document.getElementById("comment_content").value.length === 0) {
      this.handleWarningModalOpen(t("board.edu.Please_write_a_comment"));
      return;
    }

    const formData = new FormData();
    formData.append("profile_id", sessionStorage.getItem("id"));
    formData.append("board_id", this.props.match.params.board_id);
    formData.append(
      "content",
      document.getElementById("comment_content").value
    );
    const conf = {
      method: "post",
      body: formData,
    };
    fetch(Common.backendUrl + "/board/api/board/comment/", conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(t("board.edu.Comment_writing_error"));
          return;
        } else if (response.status === 201) {
          this.handleWarningModalOpen(
            t("board.edu.Your_comment_has_been_posted")
          );
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  };

  handleClickCommentEditBtn(index, content) {
    this.setState({
      editCommentIndex: index,
      editCommentCheck: true,
      editCommentContent: content,
    });
  }

  editComment(id) {
    const formData = new FormData();
    const { t } = this.props;
    formData.append("id", id);
    formData.append(
      "content",
      document.getElementById("editCommentContent").value
    );
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(Common.backendUrl + "/board/api/board/comment/" + id, conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(t("board.edu.Comment_correction_error"));
          return;
        } else if (response.status === 200) {
          this.handleWarningModalOpen(t("board.edu.The_fix_has_been_applied"));
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  }

  delComment(id) {
    const { t } = this.props;

    const formData = new FormData();
    formData.append("del_check", 1);
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(Common.backendUrl + "/board/api/board/comment/" + id, conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(t("board.edu.Comment_deletion_error"));
          return;
        } else if (response.status === 200) {
          this.handleWarningModalOpen(
            t("board.edu.The_comment_has_been_deleted")
          );
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  }

  delBoard() {
    const { t } = this.props;

    const formData = new FormData();
    formData.append("del_check", 1);
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(
      Common.backendUrl +
        "/board/api/board/" +
        this.props.match.params.board_id,
      conf
    ).then((response) => {
      if (response.status > 400) {
        this.handleWarningModalOpen(t("board.edu.Post_deletion_error"));
        return;
      } else if (response.status === 200) {
        this.handleWarningModalOpen(t("board.edu.The_post_has_been_deleted"));
        this.setState({
          warningLocationDelBoard: true,
        });
        return;
      }
    });
  }

  handleWarningModalOpen = (text) => {
    this.setState({
      warningModalOpen: true,
      warningText: text,
      warningLocationDelBoard: false,
      warningLocationPleaseLogin: false,
    });
  };

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });

    if (this.state.warningLocationDelBoard) {
      document.location.href = "/edu/list" + this.state.queryStringStr;
    }

    if (this.state.warningLocationPleaseLogin) {
      window.location.href = "/login";
    }

    if (this.state.editComment) {
      document.location.reload();
    }
  };

  handleBoardDelIsConfirm = () => {
    this.delBoard();
  };

  handleBoardDelConfirmClose = () => {
    this.setState({
      confirmBoardModalOpen: false,
      isConfirm: false,
    });
  };

  handleCommentDelIsConfirm = () => {
    this.delComment(this.state.delCommentId);
  };
  handleCommentDelConfirmClose = () => {
    this.setState({
      confirmCommentModalOpen: false,
      isConfirm: false,
    });
  };

  getProfileData = (id) => {
    fetch(Common.backendUrl + `/api/profile/one/${id}`)
      .then((response) => {
        if (response.status >= 400) {
          alert("error");
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({
          eduApplyFormContent: data,
        });
      });
  };

  handleEduApplyBtn = (e) => {
    e.preventDefault();

    if (sessionStorage.getItem("id") === null) {
      this.setState({
        warningModalOpen: true,
        warningText: "로그인이 필요합니다.",
        warningLocationPleaseLogin: true,
      });
      return;
    }
    this.setState({ eduApplyFormOpen: true });
  };

  handleEduApplyConfirmClose = () => {
    this.setState({
      eduApplyFormOpen: false,
    });
  };

  render() {
    let boardData = this.state.boardData;
    let files = [];
    let comments = [];
    let board_writer = "";
    let board_id = 0;
    let image_files = [];

    const { t } = this.props;

    if (boardData.profile != undefined) {
      board_writer = boardData.profile.member_text;
      board_id = boardData.profile.id;
    }

    if (!this.isEmptyObject(boardData)) {
      let attachfiles = boardData.attachfiles;
      let comment = boardData.comments;

      for (let i = 0; i < attachfiles.length; i++) {
        files.push(
          <div key={i}>
            #{i + 1}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
            <a href={attachfiles[i].filepath} target="_blank">
              {attachfiles[i].filename}
            </a>
          </div>
        );
        if (attachfiles[i].image_check === 1) {
          image_files.push(attachfiles[i].filepath);
        }
      }

      for (let i = 0; i < comment.length; i++) {
        let commentInnerEl;

        if (
          this.state.editCommentCheck === true &&
          this.state.editCommentIndex === i
        ) {
          commentInnerEl = (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px 10px",
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: "bold",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    {comment[i].profile.member_text}
                  </p>
                  <span
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      fontFamily: "Noto Sans KR",
                      fontSize: "10px",
                      color: "#BBBBBB",
                    }}
                    title={this.reformTime(comment[i].created_date)}
                  >
                    {this.reformTimeDate(comment[i].created_date)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={styles.comment_btn}
                    onKeyDown={(e) => e.preventDefault()}
                    style={{ marginRight: "10px" }}
                    onClick={() => this.editComment(comment[i].id)}
                  >
                    {t("board.notice.Apply")}
                  </button>
                  <button
                    className={styles.comment_btn}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={() => this.setState({ editCommentCheck: false })}
                  >
                    {t("board.notice.Cancel")}
                  </button>
                </div>
              </div>
              <hr
                style={{
                  border: 0,
                  borderBottom: "1px dashed #e2e2e2",
                  margin: "5px 0px",
                }}
              />
              <div style={{ flex: 8 }}>
                <textarea
                  autoFocus
                  style={{
                    width: "100%",
                    height: "80px",
                    fontFamily: "Noto Sans KR",
                    fontSize: "1em",
                    color: "#FFFFFF",
                    background: "rgba(255, 255, 255, 0.6)",
                    outline: "none",
                    resize: "none",
                  }}
                  defaultValue={comment[i].content}
                  name="editCommentContent"
                  id="editCommentContent"
                ></textarea>
              </div>
            </div>
          );
        } else {
          commentInnerEl = (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px 10px",
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: "bold",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    {comment[i].profile.member_text}
                  </p>
                  <span
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      fontFamily: "Noto Sans KR",
                      fontSize: "10px",
                      color: "#BBBBBB",
                    }}
                    title={this.reformTime(comment[i].created_date)}
                  >
                    {this.reformTimeDate(comment[i].created_date)}
                  </span>
                </div>
                {comment[i].profile.id ===
                parseInt(sessionStorage.getItem("id")) ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={styles.comment_btn}
                      style={{ marginRight: "10px" }}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() =>
                        this.handleClickCommentEditBtn(i, comment[i].content)
                      }
                    >
                      {t("board.notice.Modify")}
                    </button>
                    {/* <button className={styles.comment_btn} onKeyDown={(e) => e.preventDefault()} onClick={() => this.delComment(comment[i].id)}>{t('board.notice.Delete')}</button> */}
                    <button
                      className={styles.comment_btn}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() =>
                        this.setState({
                          confirmCommentModalOpen: true,
                          confirmText: "삭제하시겠습니까?",
                          delCommentId: comment[i].id,
                        })
                      }
                    >
                      {t("board.notice.Delete")}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <hr
                style={{
                  border: 0,
                  borderBottom: "1px dashed #e2e2e2",
                  margin: "5px 0px",
                }}
              />
              <div style={{ flex: 8 }}>{nl2br(comment[i].content)}</div>
            </div>
          );
        }
        let commentDiv = (
          <div
            key={i}
            style={{
              display: "flex",
              width: "100%",
              padding: "15px 10px",
              borderBottom: "1px dashed #e2e2e2",
            }}
          >
            <div style={{ flex: 1 }}>{comment[i].profile.member_text}</div>
            {commentInnerEl}
          </div>
        );
        comments.push(commentInnerEl);
      }
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Topmenu />
        <div className={styles.wrap_div}>
          <div className={styles.title_div}>
            <h1>{t("board.edu.Qualification_Education_Guide")}</h1>
            <hr style={{ border: 0, borderBottom: "1px solid #e2e2e2" }} />
          </div>
          <div className={styles.content_div}>
            <MediaContextProvider>
              <Media at="mobile">
                <BoardHeaderMobile
                  subject={this.state.boardData.subject}
                  writer={board_writer}
                  created_date={this.reformTime(boardData.created_date)}
                  updated_date={this.reformTime(boardData.updated_date)}
                  files={files}
                />
              </Media>
              <Media greaterThan="mobile">
                <BoardHeaderDesktop
                  subject={this.state.boardData.subject}
                  writer={board_writer}
                  created_date={this.reformTime(boardData.created_date)}
                  updated_date={this.reformTime(boardData.updated_date)}
                  files={files}
                />
              </Media>
            </MediaContextProvider>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={styles.board_content_div}>
                <div
                  className={styles.video_iframe}
                  dangerouslySetInnerHTML={{ __html: boardData.content }}
                ></div>
              </div>

              <hr
                style={{
                  border: 0,
                  borderBottom: "1px solid #e2e2e2",
                  width: "100%",
                }}
              />
              {boardData.is_important ? null : (
                <a
                  onClick={(e) => this.handleEduApplyBtn(e)}
                  style={{
                    color: "#020202",
                    fontWeight: "bold",
                    fontSize: "15px",
                    fontFamily: "Noto Sans KR",
                    margin: "15px 0px",
                    cursor: "pointer",
                  }}
                >
                  신청하기
                </a>
              )}
              <hr
                style={{
                  border: 0,
                  borderBottom: "1px solid #e2e2e2",
                  width: "100%",
                }}
              />
              <MediaContextProvider>
                <Media at="mobile">
                  <BoardFilesMobile files={files} />
                  <hr
                    style={{ border: 0, borderBottom: "1px solid #e2e2e2" }}
                  />
                </Media>
              </MediaContextProvider>
            </div>
            {comments}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <a
                href={"/edu/list" + this.state.queryStringStr}
                style={{
                  color: "#020202",
                  fontWeight: "bold",
                  fontSize: "15px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                {t("board.edu.List")}
              </a>
              {parseInt(sessionStorage.getItem("id")) === board_id ||
              sessionStorage.getItem("isAdmin") ? (
                <>
                  <a
                    onKeyDown={(e) => e.preventDefault()}
                    className={styles.submit_btn}
                    href={
                      "/edu/modify/" +
                      this.props.match.params.board_id +
                      this.state.queryStringStr
                    }
                    style={{
                      color: "#020202",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontFamily: "Noto Sans KR",
                      marginLeft: "20px",
                    }}
                  >
                    {t("board.edu.Modify")}
                  </a>
                  <a
                    onKeyDown={(e) => e.preventDefault()}
                    className={styles.submit_btn}
                    style={{
                      marginLeft: "20px",
                      color: "#020202",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontFamily: "Noto Sans KR",
                    }}
                    onClick={() =>
                      this.setState({
                        confirmBoardModalOpen: true,
                        confirmText: "삭제하시겠습니까?",
                      })
                    }
                  >
                    {t("board.edu.Delete")}
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {this.state.confirmBoardModalOpen ? (
          <ModalConfirm
            isOpen={this.state.confirmBoardModalOpen}
            confirmText={this.state.confirmText}
            confirmMethd={this.handleBoardDelIsConfirm}
            closeMethod={this.handleBoardDelConfirmClose}
          />
        ) : (
          <></>
        )}
        {this.state.confirmCommentModalOpen ? (
          <ModalConfirm
            isOpen={this.state.confirmCommentModalOpen}
            confirmText={this.state.confirmText}
            confirmMethd={this.handleCommentDelIsConfirm}
            closeMethod={this.handleCommentDelConfirmClose}
          />
        ) : (
          <></>
        )}
        <ModalChoiceOne
          isOpen={this.state.warningModalOpen}
          warningText={this.state.warningText}
          closeMethod={this.handleWarningModalClose}
        />
        <EducationApply
          isOpen={this.state.eduApplyFormOpen}
          boardId={this.props.match.params.board_id}
          applicantInfo={this.state.eduApplyFormContent}
          closeMethod={this.handleEduApplyConfirmClose}
        />
        {/* <EducationApply isOpen={this.state.eduApplyFormOpen} confirmMethd={this.handleEduApplyConfirm} closeMethod={this.handleEduApplyConfirmClose} /> */}
        <Footer />
      </div>
    );
  }
}

class BoardHeaderMobile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontFamily: "Noto Sans KR",
            fontSize: "16px",
          }}
        >
          {this.props.subject}
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            fontFamily: "Noto Sans KR",
            fontSize: "12px",
          }}
        >
          {this.props.writer}
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            fontFamily: "Noto Sans KR",
            fontSize: "12px",
          }}
        >
          {this.props.created_date}
        </div>
        <hr
          style={{
            border: 0,
            borderBottom: "1px solid #e2e2e2",
            width: "100%",
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(Content);
