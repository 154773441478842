import React, { Component } from 'react'
import styles from "./ScoreDisplay.module.css"
import Common from '../Common';

// fetch(Common.backendUrl + "/api/competitiongamelist/" + this.props.competition_id + "/" + this.props.match_type + "/" + this.props.league_id )
export default class ScoreDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redScore: '',
            blueScore: '',
            redTeamName: '',
            blueTeamName: '',
        }
    }

    componentDidMount = () => {
        this.getInterval()
        // console.log(this.props.match.params.game_id, "====================")
    }

    getInterval = () => {
        let counterId = setInterval(() => {
            this.getGameList()
        }, 500)
    }

    getGameList() {
        fetch(Common.backendUrl + "/api/gamematchforscore/" + this.props.match.params.game_id)
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                let redScore = "";
                let redTeamName = "";
                let blueScore = "";
                let blueTeamName = "";

                if (this.props.match.params.set_choiced === "1set") {
                    redScore = data["first_set_score"].split('-')[0]
                    blueScore = data["first_set_score"].split('-')[1]
                    redTeamName = data["team_red"]["team"]["team_text"]
                    blueTeamName = data["team_blue"]["team"]["team_text"]
                } else if (this.props.match.params.set_choiced === "2set") {
                    redScore = data["second_set_score"].split('-')[0]
                    blueScore = data["second_set_score"].split('-')[1]
                    redTeamName = data["team_red"]["team"]["team_text"]
                    blueTeamName = data["team_blue"]["team"]["team_text"]
                } else if (this.props.match.params.set_choiced === "3set") {
                    redScore = data["third_set_score"].split('-')[0]
                    blueScore = data["third_set_score"].split('-')[1]
                    redTeamName = data["team_red"]["team"]["team_text"]
                    blueTeamName = data["team_blue"]["team"]["team_text"]
                }

                this.setState({
                    redScore,
                    blueScore,
                    redTeamName,
                    blueTeamName,
                });
            });
    }

    render() {
        const { redScore, blueScore, redTeamName, blueTeamName } = this.state;
        let team_background = "";
        let team_score = "";
        let team_name = "";
        if (this.props.match.params.team === "redteam") {
            team_background = "url('/images/score/scoreboard_red_bg01.png')"
            team_score = redScore;
            team_name = redTeamName;
        } else {
            team_background = "url('/images/score/scoreboard_blue_bg01.png')"
            team_score = blueScore;
            team_name = blueTeamName;
        }

        return (
            <div className={styles.wrap_div} style={{ backgroundImage: team_background }}>
                <p>{team_name}</p>
                <p>{team_score}</p>
            </div>
        );
    }
}