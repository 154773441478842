import React, { Component } from "react";
import { Pagination } from "semantic-ui-react";
import styles from "./List.module.css";
import queryString from "query-string";
import Topmenu from "../../Topmenu/Topmenu.js";
import Footer from "../../footer/Footer.js";
import Common from "../../Common";
import DesktopApp from "./DesktopApp.js";
import MobileApp from "./MobileApp.js";
import { createMedia } from "@artsy/fresnel";
import { withTranslation } from "react-i18next";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
    },
});

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            boardData: null,
            pageNum: 1,
            column: null,
            pageCount: 1,
            pageSize: 20,
            searchString: null,
            queryStringStr: null,
            boardKind: "reference_room",
            changeScroll: 0,
        };

        const queryValue = queryString.parse(this.props.location.search);
        let queryStringStr = "?";
        if (queryValue.pageNum) {
            this.state.pageNum = queryValue.pageNum;
            queryStringStr = queryStringStr + "pageNum=" + queryValue.pageNum;
        } else {
            this.state.pageNum = 1;
            queryStringStr = queryStringStr + "pageNum=1";
        }

        if (queryValue.column) {
            this.state.column = queryValue.column;
            queryStringStr = queryStringStr + "&column=" + this.state.column;
        }
        if (queryValue.searchString) {
            this.state.searchString = queryValue.searchString;
            queryStringStr = queryStringStr + "&searchString=" + this.state.searchString;
        }
        this.state.queryStringStr = queryStringStr;
    }

    componentDidMount() {
        this.get_boardData(this.state.pageNum);
        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (this.state.changeScroll !== 0) {
            window.scrollTo(this.state.changeScroll, this.state.changeScroll);
        } else {
            window.scrollTo(0, 0);
        }
    }

    get_boardData = (page) => {
        let url = Common.backendUrl + "/board/api/board/?board_kind=" + this.state.boardKind + "&page=" + page;
        if (!(this.state.column === "" || this.state.column === undefined || this.state.column === null)) {
            url = url + "&column=" + this.state.column + "&searchString=" + this.state.searchString;
        }

        fetch(url)
            .then((response) => {
                if (response.status > 400) {
                    console.log("error: no data");
                    return;
                }
                return response.json();
            })
            .then((data) => {
                if (data != undefined) {
                    if (data.count % this.state.pageSize === 0) {
                        this.state.pageCount = parseInt(data.count / this.state.pageSize);
                    } else {
                        this.state.pageCount = parseInt(data.count / this.state.pageSize) + 1;
                    }
                }
                this.setState({
                    boardData: data,
                });
            });
    };

    searchFormSubmit = (e) => {
        e.preventDefault();
        let column;
        let searchString = this.state.searchString;
        this.state.pageNum = 1;
        if (this.state.column === "" || this.state.column === undefined || this.state.column === null) {
            column = "subject";
        } else {
            column = this.state.column;
        }

        document.location.href = "/reference_room/list?pageNum=" + this.state.pageNum + "&column=" + column + "&searchString=" + searchString;
        document.location.reload();
    };

    handleChange = (e, nodeId) => {
        this.setState({
            [e.target.name]: e.target.value,
            changeScroll: document.getElementById(nodeId).offsetTop,
        });
    };

    render() {
        let data_html = [];
        let boardData = this.state.boardData;
        let new_date = new Date();
        let create_date_str = "";
        const { t } = this.props;
        if (boardData) {
            for (let i = 0; i < boardData.results.length; i++) {
                new_date = new Date(boardData.results[i].created_date);
                let [month, date, year] = new_date.toLocaleDateString("en-US").split("/");
                if (month < 10) month = "0" + month;
                if (date < 10) date = "0" + date;
                create_date_str = [year, month, date].join("-");

                data_html.push(
                    <div title={boardData.results[i].subject} className={styles.table_content_line} key={boardData.results[i].id} onClick={() => (window.location.href = `/reference_room/content/${boardData.results[i].id}${this.state.queryStringStr}`)}>
                        <div>
                            {boardData.results[i].is_important ? (
                                <>
                                    {window.screen.width < 768 ? (
                                        <div style={{ flex: 1, textAlign: "center" }}>
                                            <span style={{ fontWeight: "800", color: "#ff4e59", padding: "0px 2px", fontSize: "10px", backgroundColor: "#ffe3e4", border: "1px solid #ffc6c9", borderRadius: "3px" }}>공지</span>
                                        </div>
                                    ) : (
                                        <div style={{ flex: 1, textAlign: "center" }}>
                                            <span style={{ width: "40%", fontWeight: "800", display: "inline-block", color: "#ff4e59", fontSize: "10px", backgroundColor: "#ffe3e4", border: "1px solid #ffc6c9", borderRadius: "3px", padding: "0px 3px" }}>공지</span>
                                        </div>
                                    )}

                                    <div className={styles.scroll_hidden} style={{ display: "flex", flex: 5, flexDirection: "row", alignItems: "center", whiteSpace: "nowrap", textAlign: "left", overflowX: "auto", color: "#ff4e59", fontWeight: "bold" }}>
                                        {window.screen.width < 768 ? boardData.results[i].subject : boardData.results[i].subject.length < 40 ? boardData.results[i].subject : boardData.results[i].subject.slice(0, 40) + "..."}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ flex: 1, textAlign: "center" }}>{boardData.results[i].board_num}</div>
                                    <div className={styles.scroll_hidden} style={{ flex: 5, whiteSpace: "nowrap", textAlign: "left", overflowX: "auto" }}>
                                        {window.screen.width < 768 ? boardData.results[i].subject : boardData.results[i].subject.length < 40 ? boardData.results[i].subject : boardData.results[i].subject.slice(0, 40) + "..."}
                                    </div>
                                </>
                            )}
                            <div style={{ flex: 2, textAlign: "center" }}>{boardData.results[i].profile.member_text}</div>

                            {document.documentElement.clientWidth >= 768 ? (
                                <>
                                    <div style={{ flex: 2, textAlign: "center" }}>{create_date_str}</div>
                                    <div style={{ flex: 1, textAlign: "center" }}>{boardData.results[i].count_comment}</div>
                                </>
                            ) : (
                                <div style={{ flex: 3, textAlign: "center" }}>{create_date_str}</div>
                            )}
                        </div>
                        {i === boardData.results.length - 1 ? <hr style={{ border: "1px solid silver" }} /> : <hr />}
                    </div>
                );
            }

            if (boardData.results.length === 0) {
                data_html.push(
                    <div className={styles.table_content_line}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "80%", maxWidth: "1200px", padding: "10px 0", backgroundColor: "#F7F7F7" }}>
                            <p style={{ flex: 1, textAlign: "center", color: "#020202" }}>{t("board.reference_room.No_Contents")}</p>
                        </div>
                        <hr style={{ border: "1px solid silver" }} />
                    </div>
                );
            }
        } else {
            data_html.push(
                <div className={styles.table_content_line}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "80%", maxWidth: "1200px", padding: "10px 0", backgroundColor: "#F7F7F7" }}>
                        <p style={{ flex: 1, textAlign: "center", color: "#020202" }}>{t("board.reference_room.No_Contents")}</p>
                    </div>
                    <hr style={{ border: "1px solid silver" }} />
                </div>
            );
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Topmenu />
                <div className={styles.wrap_div}>
                    <div className={styles.title_div}>
                        <h1>{t("board.reference_room.title")}</h1>
                        <hr style={{ border: 0, borderBottom: "1px solid #020202" }} />
                    </div>

                    <MediaContextProvider>
                        <Media at="mobile">
                            <MobileApp boardList={data_html} pageNum={this.state.pageNum} pageCount={this.state.pageCount} column={this.state.column} searchString={this.state.searchString} />
                        </Media>
                        <Media greaterThan="mobile">
                            <DesktopApp boardList={data_html} pageNum={this.state.pageNum} pageCount={this.state.pageCount} column={this.state.column} searchString={this.state.searchString} />
                        </Media>
                    </MediaContextProvider>
                    <form method="post" onSubmit={this.searchFormSubmit}>
                        <div style={{ marginTop: "20px" }}>
                            <select name="column" id="column" onClick={(e) => this.handleChange(e, "column")} className={styles.form_select}>
                                <option style={{ color: "black" }} value="subject">
                                    {t("board.reference_room.Title")}
                                </option>
                                <option style={{ color: "black" }} value="writer">
                                    {t("board.reference_room.Writer")}
                                </option>
                                <option style={{ color: "black" }} value="content">
                                    {t("board.reference_room.Contents")}
                                </option>
                            </select>
                            <input className={styles.search_text} type="text" name="searchString" id="searchString" onChange={(e) => this.handleChange(e, "searchString")} required></input>
                            <input className={styles.search_btn} type="submit" value={t("board.reference_room.Search")}></input>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(List);
