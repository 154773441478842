import React, { Component } from "react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

import {
    Modal,
    Button,
    Input,
    Dropdown,
    Form,
    Segment,
    List,
    Checkbox,
} from 'semantic-ui-react'
import Common from '../../../Common';

class PaymentsDetailModal extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <>
                {this.props.paymentData &&
                    <Modal
                        size={'tiny'}
                        open={this.props.isOpen}
                    >
                        <Modal.Header>결제 정보</Modal.Header>
                        <Modal.Content>
                                <Form style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} onSubmit={this.handleSubmit}>
                                    <Form.Field style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <label style={{margin: 0, flex: 1}}>고유넘버</label>
                                        <p style={{flex: 4, textAlign: "center"}}>{this.props.paymentData.unique_num}</p>
                                    </Form.Field>
                                    <Form.Field style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <label style={{margin: 0, flex: 1}}>구매자 (연락처)</label>
                                        <p style={{flex: 4, textAlign: "center"}}>{this.props.paymentData.profile.member_text} ({this.props.paymentData.profile.member_phone})</p>
                                    </Form.Field>
                                    <Form.Field style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <label style={{margin: 0, flex: 1}}>구매상품</label>
                                        <p style={{flex: 4, textAlign: "center"}}>{this.props.paymentData.product}</p>
                                    </Form.Field>
                                    <Form.Field style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <label style={{margin: 0, flex: 1}}>인쇄</label>
                                        {this.props.paymentData.checking === 1 ?
                                            <p style={{flex: 4, textAlign: "center"}}>인쇄 전</p>
                                            :
                                            <p style={{flex: 4, textAlign: "center"}}>인쇄 후</p>
                                        }
                                    </Form.Field>
                                    <Form.Field style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                    <label style={{margin: 0, flex: 1}}>상태</label>
                                    {this.props.paymentData.is_refund ?
                                        <p style={{flex: 4, textAlign: "center"}}>환불</p>
                                        :
                                        <p style={{flex: 4, textAlign: "center"}}>결제완료</p>
                                    }
                                    </Form.Field>
                                    {/* <Form.Field style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                        <label style={{margin: 0, flex: 1}}>환불사유</label>
                                        <textarea id="refund_reason" style={{ resize: "none", height: "60px", flex: 4 }}></textarea>
                                    </Form.Field> */}
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        {/* <Button positive type="submit" className="button is-info" style={{marginRight: "10px"}}>환불하기</Button> */}
                                        <Button color='black' onClick={this.props.modalCloseMethod} >닫기</Button>
                                    </div>
                                </Form>
                        </Modal.Content>
                    </Modal>
                }
            </>
        );
    }
}

export default PaymentsDetailModal