import React, { Component } from 'react';
import {
    Table,
    TableRow,
    Segment,
    Header,
    Pagination
} from 'semantic-ui-react'
import Common from '../../../Common.js';


class HistoryManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            participateHistory: "",
            teamId: '',
            selectedPage: 1,
            totalPages: 0,
        }
    }

    componentDidMount = () => {
        this.getTeamInfomation();
    }

    async getTeamInfomation() {
        if (sessionStorage.getItem('token')) {
            await fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    'Authorization': `JWT ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(json => {
                    this.setState({ teamId: json.profile.team.id })
                    this.getParticipateHistory(json.profile.team.id, this.state.selectedPage);
                });
        }
    }

    async getParticipateHistory(teamId, page) {
        await fetch(Common.backendUrl + `/api/team_aplication/${teamId}?page=${page}`)
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data["count"] % 10 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10) + 1
                    })
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10)
                    })
                }
                this.setState({ participateHistory: data.results });
            });
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page
        })
        this.getParticipateHistory(this.state.teamId, page)
    }

    convertDateTime(dateStr) {
        if (dateStr) {
            let parts = dateStr.split('-')
            let day = parts[2].split('T')
            let time = day[1].split('.')
            let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0] + " " + time[0]
            return covertedDate;
        } else {
            return;
        }
    }

    render() {
        let history = []
        if (this.state.participateHistory !== '') {
            history[0] = this.state.participateHistory.map((info, index) => (
                index === this.state.participateHistory.length -1 ?
                    <TableRow key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                        <Table.Cell textAlign='center'>{info.competition.competition_text}</Table.Cell>
                        <Table.Cell textAlign='center'>{info.team.league.league_text}</Table.Cell>
                        <Table.Cell textAlign='center'>{this.convertDateTime(info.reg_date)}</Table.Cell>
                        { info.register_state == 0 &&
                            <Table.Cell textAlign='center'>신청</Table.Cell>
                        }
                        { info.register_state == 1 &&
                            <Table.Cell textAlign='center'>승인</Table.Cell>
                        }
                        { info.register_state == 2 &&
                            <Table.Cell textAlign='center'>반려</Table.Cell>
                        }
                        { info.receipt !== null &&
                            <Table.Cell textAlign='center'><a href={info.receipt} download={info.receipt}>다운로드</a></Table.Cell>
                        }
                        { info.receipt === null &&
                            <Table.Cell textAlign='center'></Table.Cell>
                        }
                    </TableRow>
                    :
                    <TableRow key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                        <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.competition.competition_text}</Table.Cell>
                        <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.team.league.league_text}</Table.Cell>
                        <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{this.convertDateTime(info.reg_date)}</Table.Cell>
                        {info.register_state == 0 &&
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>신청</Table.Cell>
                        }
                        {info.register_state == 1 &&
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>승인</Table.Cell>
                        }
                        {info.register_state == 2 &&
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>반려</Table.Cell>
                        }
                        {info.receipt !== null &&
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'><a href={info.receipt} download={info.receipt}>다운로드</a></Table.Cell>
                        }
                        {info.receipt === null &&
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'></Table.Cell>
                        }
                    </TableRow>
            ))
        }

        return (
            <Segment style={{ border: "1px solid #000000" }}>
                <Segment secondary clearing attached='top' style={{border: "1px solid #000000"}}>
                    <Segment style={{border: "1px solid #000000"}}>
                        <Header as='h3' textAlign='center'>대회 참가 이력</Header>
                    </Segment>
                </Segment>
                <Segment attached style={{display: 'flex', flexDirection: 'column', alignItems: 'center', border: "1px solid #000000", borderTop: "0"}} >
                    <Table striped unstackable style={{border:"1px solid #000000"}}>
                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>대회명</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>리그</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청일자</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>접수상태</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>영수증</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>
                            {history[0]}
                        </Table.Body>
                    </Table>
                    {this.state.totalPages > 0 ?
                        <Pagination
                            style={{ marginTop: "10px"}}
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={this.state.totalPages}
                            onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                        />
                        :
                        <></>
                    }
                </Segment>
            </Segment>
        );
    }



}


export default HistoryManager;