import React, { Component } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import TeamListInProvince from "./TeamListInProvince";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./SquadManager.module.css";
import TeamModal from "../modal_team_detail/TeamModal.js";
import Common from "../Common";
import TeamList from "./TeamList.js";
import { withTranslation } from "react-i18next";

class SquadManager extends Component {
    state = {
        provinceData: "",
        provinceOptions: "",
        regionOptions: "",
        selProvinceId: "",
        selRegionId: "",
        selTeamId: "",
        teamData: "",
        leagueData: "",
        modalOpen: false,
        modalTeamId: "",

        teamList1th: "",
        teamList2th: "",
        teamList3th: "",
        teamListYouth: "",
        negTeamList1th: "",
        negTeamList2th: "",
        negTeamList3th: "",
        negTeamListYouth: "",
        crntYear: new Date().getFullYear(),
    };

    componentDidMount() {
        this.getLeagueData();
        this.getProvinceData();

        this.getTeamDSPoint(1);
        this.getTeamDSPoint(2);
        this.getTeamDSPoint(3);
        this.getTeamDSPoint(4);
        this.getNotJoinTeam(1);
        this.getNotJoinTeam(2);
        this.getNotJoinTeam(3);
        this.getNotJoinTeam(4);
    }

    getProvinceData() {
        fetch(Common.backendUrl + "/api/province/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];

                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let regions = [];
                for (let region of data) {
                    regions.push({ key: region.id, text: region.region_text, value: region.id });
                }
                this.setState({ regionOptions: regions });
            });
    }

    getLeagueData() {
        fetch(Common.backendUrl + "/api/league/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ leagueData: data });
            });
    }

    provinceChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            selProvinceId: value,
            selRegionId: "",
            teamList1th: "",
            teamList2th: "",
            teamList3th: "",
            teamListYouth: "",
            negTeamList1th: "",
            negTeamList2th: "",
            negTeamList3th: "",
            negTeamListYouth: "",
        });

        this.getRegionData(value);
    };

    regionChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            selRegionId: value,
            teamList1th: "",
            teamList2th: "",
            teamList3th: "",
            teamListYouth: "",
            negTeamList1th: "",
            negTeamList2th: "",
            negTeamList3th: "",
            negTeamListYouth: "",
        });
    };

    resetTeamData = (e) => {
        e.preventDefault();
        const { t } = this.props;

        this.setState({
            selProvinceId: "",
            selRegionId: "",
            regionOptions: "",
        });

        if (document.getElementById("provinceDropDown").children[0].innerText !== t("info_squad.NationalPlayer.Branch")) {
            document.getElementById("provinceDropDown").children[1].click();
        }
        if (document.getElementById("regionDropDown").children[0].innerText !== t("info_squad.NationalPlayer.Chapter")) {
            document.getElementById("regionDropDown").children[1].click();
        }
        this.getTeamDSPoint(1);
        this.getTeamDSPoint(2);
        this.getTeamDSPoint(3);
        this.getTeamDSPoint(4);
        this.getNotJoinTeam(1);
        this.getNotJoinTeam(2);
        this.getNotJoinTeam(3);
        this.getNotJoinTeam(4);
    };

    getNotJoinTeam(league_id) {
        fetch(Common.backendUrl + "/api/team/not_join_rank/" + this.state.crntYear + "/" + league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                if (league_id === 1) {
                    this.setState({ negTeamList1th: data });
                } else if (league_id === 2) {
                    this.setState({ negTeamList2th: data });
                } else if (league_id === 3) {
                    this.setState({ negTeamList3th: data });
                } else if (league_id === 4) {
                    this.setState({ negTeamListYouth: data });
                }
            });
    }

    getTeamDSPoint(league_id) {
        fetch(Common.backendUrl + "/api/team/win_rank/" + this.state.crntYear + "/" + league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                if (league_id === 1) {
                    this.setState({ teamList1th: data });
                } else if (league_id === 2) {
                    this.setState({ teamList2th: data });
                } else if (league_id === 3) {
                    this.setState({ teamList3th: data });
                } else if (league_id === 4) {
                    this.setState({ teamListYouth: data });
                }
            });
    }

    handleClose = (e) => {
        this.setState({
            modalOpen: false,
            modalTeamId: "",
        });
    };

    render() {
        const { t } = this.props;
        let list1th = [];
        let list2th = [];
        let list3th = [];
        let listYouth = [];

        if (this.state.teamList1th.length > 0) {
            this.state.teamList1th.map((team) =>
                list1th.push(
                    <tr key={team.winner__id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.winner__id })}>
                        <th>{team.rank}</th>
                        <th>{team.winner__team_text}</th>
                        <th>{team.win_rank_point}</th>
                    </tr>
                )
            );
        }

        if (this.state.negTeamList1th.length > 0) {
            this.state.negTeamList1th.map((team) =>
                list1th.push(
                    <tr key={team.id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.id })}>
                        <th>-</th>
                        <th>{team.team_text}</th>
                        <th>0</th>
                    </tr>
                )
            );
        }

        if (this.state.teamList2th.length > 0) {
            this.state.teamList2th.map((team) =>
                list2th.push(
                    <tr key={team.winner__id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.winner__id })}>
                        <th>{team.rank}</th>
                        <th>{team.winner__team_text}</th>
                        <th>{team.win_rank_point}</th>
                    </tr>
                )
            );
        }

        if (this.state.negTeamList2th.length > 0) {
            this.state.negTeamList2th.map((team) =>
                list2th.push(
                    <tr key={team.id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.id })}>
                        <th>-</th>
                        <th>{team.team_text}</th>
                        <th>0</th>
                    </tr>
                )
            );
        }

        if (this.state.teamList3th.length > 0) {
            this.state.teamList3th.map((team) =>
                list3th.push(
                    <tr key={team.winner__id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.winner__id })}>
                        <th>{team.rank}</th>
                        <th>{team.winner__team_text}</th>
                        <th>{team.win_rank_point}</th>
                    </tr>
                )
            );
        }

        if (this.state.negTeamList3th.length > 0) {
            this.state.negTeamList3th.map((team) =>
                list3th.push(
                    <tr key={team.id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.id })}>
                        <th>-</th>
                        <th>{team.team_text}</th>
                        <th>0</th>
                    </tr>
                )
            );
        }

        if (this.state.teamListYouth.length > 0) {
            this.state.teamListYouth.map((team) =>
                listYouth.push(
                    <tr key={team.winner__id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.winner__id })}>
                        <th>{team.rank}</th>
                        <th>{team.winner__team_text}</th>
                        <th>{team.win_rank_point}</th>
                    </tr>
                )
            );
        }

        if (this.state.negTeamListYouth.length > 0) {
            this.state.negTeamListYouth.map((team) =>
                listYouth.push(
                    <tr key={team.id} onClick={() => this.setState({ modalOpen: true, modalTeamId: team.id })}>
                        <th>-</th>
                        <th>{team.team_text}</th>
                        <th>0</th>
                    </tr>
                )
            );
        }

        return (
            <>
                <Topmenu />
                <div className={styles.wrap_div}>
                    <div>
                        <h1 title="드론축구클럽" style={{ color: "white" }}>
                            {t("info_squad.SquadManager.Main_Title")}
                        </h1>
                    </div>
                    <div>
                        <div>
                            <Button inverted color="grey" onClick={(e) => this.resetTeamData(e)}>
                                {t("info_squad.TeamList.Total")} {t("info_squad.TeamList.Team")}
                            </Button>
                            <div>
                                <Dropdown id="provinceDropDown" style={{ marginRight: "5px" }} onChange={this.provinceChange} defaultValue={this.state.selProvinceId} options={this.state.provinceOptions} selection clearable placeholder={t("info_squad.NationalPlayer.Branch")} fluid />
                                <Dropdown id="regionDropDown" onChange={this.regionChange} options={this.state.regionOptions} defaultValue={this.state.selRegionId} selection clearable placeholder={t("info_squad.NationalPlayer.Chapter")} fluid />
                                {/* <Dropdown style={{ marginRight: "5px" }} onChange={this.provinceChange} options={this.state.provinceOptions} defaultValue={1015} selection placeholder='지회목록' fluid />
                                <Dropdown onChange={this.regionChange} options={this.state.regionOptions} selection placeholder='지역목록' defaultValue={1235} fluid /> */}
                            </div>
                        </div>

                        {/* {this.state.leagueData ? */}
                        {this.state.selRegionId ? (
                            <>
                                <div>
                                    <TeamList region_id={this.state.selRegionId} league_id={1} league_text={this.state.leagueData[0]} />
                                </div>
                                <div>
                                    <TeamList region_id={this.state.selRegionId} league_id={2} league_text={this.state.leagueData[1]} />
                                </div>
                                <div>
                                    <TeamList region_id={this.state.selRegionId} league_id={3} league_text={this.state.leagueData[2]} />
                                </div>
                                <div>
                                    <TeamList region_id={this.state.selRegionId} league_id={4} league_text={this.state.leagueData[3]} />
                                </div>
                            </>
                        ) : this.state.selProvinceId ? (
                            // 지회만 선택되었을떄
                            <>
                                <div>
                                    <TeamListInProvince province_id={this.state.selProvinceId} league_id={1} league_text={this.state.leagueData[0]} />
                                </div>
                                <div>
                                    <TeamListInProvince province_id={this.state.selProvinceId} league_id={2} league_text={this.state.leagueData[1]} />
                                </div>
                                <div>
                                    <TeamListInProvince province_id={this.state.selProvinceId} league_id={3} league_text={this.state.leagueData[2]} />
                                </div>
                                <div>
                                    <TeamListInProvince province_id={this.state.selProvinceId} league_id={4} league_text={this.state.leagueData[3]} />
                                </div>
                            </>
                        ) : (
                            // 전체 목록시 확인하기 -> selRegionId 를 ''로 설정하면 다시 되돌아감.
                            <>
                                {list1th.length > 0 && (
                                    <div>
                                        <h1 style={{ marginTop: "100px", textAlign: "center" }}>{t("competition_info_detail.CompetitionDetailManager.Division_1")}</h1>
                                        <p style={{ textAlign: "right" }}>
                                            {t("info_squad.TeamList.Total")} {list1th.length}
                                            {t("info_squad.TeamList.Team")}
                                        </p>
                                        <table className={styles.squad_table}>
                                            <thead>
                                                <tr>
                                                    <th>{t("info_squad.TeamList.Rank")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Name")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Record")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>{list1th}</tbody>
                                        </table>
                                    </div>
                                )}
                                {list2th.length > 0 && (
                                    <div>
                                        <h1 style={{ marginTop: "100px", textAlign: "center" }}>{t("competition_info_detail.CompetitionDetailManager.Division_2")}</h1>
                                        <p style={{ textAlign: "right" }}>
                                            {t("info_squad.TeamList.Total")} {list2th.length}
                                            {t("info_squad.TeamList.Team")}
                                        </p>
                                        <table className={styles.squad_table}>
                                            <thead>
                                                <tr>
                                                    <th>{t("info_squad.TeamList.Rank")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Name")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Record")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>{list2th}</tbody>
                                        </table>
                                    </div>
                                )}
                                {list3th.length > 0 && (
                                    <div>
                                        <h1 style={{ marginTop: "100px", textAlign: "center" }}>{t("competition_info_detail.CompetitionDetailManager.Division_3")}</h1>
                                        <p style={{ textAlign: "right" }}>
                                            {t("info_squad.TeamList.Total")} {list3th.length}
                                            {t("info_squad.TeamList.Team")}
                                        </p>
                                        <table className={styles.squad_table}>
                                            <thead>
                                                <tr>
                                                    <th>{t("info_squad.TeamList.Rank")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Name")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Record")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>{list3th}</tbody>
                                        </table>
                                    </div>
                                )}
                                {listYouth.length > 0 && (
                                    <div>
                                        <h1 style={{ marginTop: "100px", textAlign: "center" }}>{t("competition_info_detail.CompetitionDetailManager.Division_Youth")}</h1>
                                        <p style={{ textAlign: "right" }}>
                                            {t("info_squad.TeamList.Total")} {listYouth.length}
                                            {t("info_squad.TeamList.Team")}
                                        </p>
                                        <table className={styles.squad_table}>
                                            <thead>
                                                <tr>
                                                    <th>{t("info_squad.TeamList.Rank")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Name")}</th>
                                                    <th>{t("info_squad.TeamList.Team_Record")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>{listYouth}</tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <TeamModal isOpen={this.state.modalOpen} teamId={this.state.modalTeamId} teamId_check={this.handleTeamId} close_method={this.handleClose} />

                    {/* <TeamModal open_state={true} /> */}
                </div>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(SquadManager);
