import React, { Component } from "react";
import { Grid, Tab, Loader } from "semantic-ui-react";

import CompetitionGameList from "./CompetitionGameList.js";
import CompetitionJoinTeamList from "./CompetitionJoinTeamList.js";
import Common from "../Common";

class CompetitionLeagueTab extends Component {
    constructor() {
        super();
        this.state = {
            aplicationTeamList: [],
            joinTeamList: [],
            gameList: [],
        };
        this.getGameList = this.getGameList.bind(this);
        this.getJoinTeamData = this.getJoinTeamData.bind(this);
        this.getCompetitionAplication = this.getCompetitionAplication.bind(this);
    }

    getCompetitionAplication() {
        fetch(Common.backendUrl + "/api/competitionaplication/" + this.props.competition_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ aplicationTeamList: data });
            });
    }

    getJoinTeamData() {
        fetch(Common.backendUrl + "/api/competitionteam/" + this.props.competition_id)
            .then((response) => {
                if (response.status > 400) {
                    this.setState({ placeholder: "Something went wrong!" });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ joinTeamList: data });
            });
    }

    getGameList() {
        document.getElementById("loader").style.display = "flex";
        fetch(Common.backendUrl + "/api/competitiongamelist/" + this.props.competition_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    gameList: data,
                });
                document.getElementById("loader").style.display = "none";
            });
    }

    componentDidMount() {
        this.getCompetitionAplication();
        this.getGameList();
        this.getJoinTeamData();
        this.getCompetitionData(this.props.competition_id);
    }

    getCompetitionData() {
        fetch(Common.backendUrl + "/api/competition/" + this.props.competition_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    competitionData: data,
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.competition_id !== prevProps.competition_id) {
            this.getCompetitionAplication();
            this.getGameList();
            this.getJoinTeamData();
            this.getCompetitionData(this.props.competition_id);
        }
    }

    setLeagueTap() {
        const panes = [
            {
                menuItem: "1부리그",
                render: () => (
                    <Tab.Pane key="tab1">
                        <Grid divided="vertically">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {/* 1부리그 신청팀 현황 */}
                                    <CompetitionJoinTeamList
                                        joinTeamList={this.state.joinTeamList.filter((data) => data.league === 1)}
                                        aplicationTeamList={this.state.aplicationTeamList.filter((data) => data.league === 1)}
                                        getJoinTeamData={this.getJoinTeamData}
                                        getCompetitionAplication={this.getCompetitionAplication}
                                        competition_id={this.props.competition_id}
                                        competitionState={this.state.competitionData.competition_state.state_text}
                                        league_id={1}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 1 && data.match_type.id >= 13)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 1)} competition_id={this.props.competition_id} league_id={1} match_type={2} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 1 && data.match_type.id >= 11 && data.match_type.id <= 12)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 1)}
                                            competition_id={this.props.competition_id}
                                            league_id={1}
                                            match_type={4}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 1 && data.match_type.id >= 9 && data.match_type.id <= 10)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 1)}
                                            competition_id={this.props.competition_id}
                                            league_id={1}
                                            match_type={8}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 1 && data.match_type.id <= 8)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 1)} competition_id={this.props.competition_id} league_id={1} match_type={"예선"} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "2부리그",
                render: () => (
                    <Tab.Pane key="tab2">
                        <Grid divided="vertically">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {/* 2부리그 신청팀 현황 */}
                                    <CompetitionJoinTeamList
                                        joinTeamList={this.state.joinTeamList.filter((data) => data.league === 2)}
                                        aplicationTeamList={this.state.aplicationTeamList.filter((data) => data.league === 2)}
                                        getJoinTeamData={this.getJoinTeamData}
                                        getCompetitionAplication={this.getCompetitionAplication}
                                        competition_id={this.props.competition_id}
                                        competitionState={this.state.competitionData.competition_state.state_text}
                                        league_id={2}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        {/* 게임목록 */}
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 2 && data.match_type.id >= 13)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 2)} competition_id={this.props.competition_id} league_id={2} match_type={2} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 2 && data.match_type.id >= 11 && data.match_type.id <= 12)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 2)}
                                            competition_id={this.props.competition_id}
                                            league_id={2}
                                            match_type={4}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 2 && data.match_type.id >= 9 && data.match_type.id <= 10)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 2)}
                                            competition_id={this.props.competition_id}
                                            league_id={2}
                                            match_type={8}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 2 && data.match_type.id <= 8)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 2)} competition_id={this.props.competition_id} league_id={2} match_type={"예선"} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "3부리그",
                render: () => (
                    <Tab.Pane key="tab3">
                        <Grid divided="vertically">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {/* 3부리그 신청팀 현황 */}
                                    <CompetitionJoinTeamList
                                        joinTeamList={this.state.joinTeamList.filter((data) => data.league === 3)}
                                        aplicationTeamList={this.state.aplicationTeamList.filter((data) => data.league === 3)}
                                        getJoinTeamData={this.getJoinTeamData}
                                        getCompetitionAplication={this.getCompetitionAplication}
                                        competition_id={this.props.competition_id}
                                        competitionState={this.state.competitionData.competition_state.state_text}
                                        league_id={3}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 3 && data.match_type.id >= 13)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 3)} competition_id={this.props.competition_id} league_id={3} match_type={2} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 3 && data.match_type.id >= 11 && data.match_type.id <= 12)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 3)}
                                            competition_id={this.props.competition_id}
                                            league_id={3}
                                            match_type={4}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 3 && data.match_type.id >= 9 && data.match_type.id <= 10)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 3)}
                                            competition_id={this.props.competition_id}
                                            league_id={3}
                                            match_type={8}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 3 && data.match_type.id <= 8)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 3)} competition_id={this.props.competition_id} league_id={3} match_type={"예선"} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: "유소년 리그",

                render: () => (
                    <Tab.Pane key="tab4">
                        <Grid divided="vertically">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {/* 유소년 리그 신청팀 현황 */}
                                    <CompetitionJoinTeamList
                                        joinTeamList={this.state.joinTeamList.filter((data) => data.league === 4)}
                                        aplicationTeamList={this.state.aplicationTeamList.filter((data) => data.league === 4)}
                                        getJoinTeamData={this.getJoinTeamData}
                                        getCompetitionAplication={this.getCompetitionAplication}
                                        competition_id={this.props.competition_id}
                                        competitionState={this.state.competitionData.competition_state.state_text}
                                        league_id={4}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 4 && data.match_type >= 13)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 4)} competition_id={this.props.competition_id} league_id={4} match_type={2} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 4 && data.match_type >= 11 && data.match_type.id <= 12)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 4)} competition_id={this.props.competition_id} league_id={4} match_type={4} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList
                                            gameList={this.state.gameList.filter((data) => data.league === 4 && data.match_type.id >= 9 && data.match_type.id <= 10)}
                                            joinTeamList={this.state.joinTeamList.filter((data) => data.league === 4)}
                                            competition_id={this.props.competition_id}
                                            league_id={4}
                                            match_type={8}
                                            getGameList={this.getGameList}
                                            getJoinTeamData={this.getJoinTeamData}
                                        />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid.Column>
                                        <CompetitionGameList gameList={this.state.gameList.filter((data) => data.league === 4 && data.match_type.id <= 8)} joinTeamList={this.state.joinTeamList.filter((data) => data.league === 4)} competition_id={this.props.competition_id} league_id={2} match_type={"예선"} getGameList={this.getGameList} getJoinTeamData={this.getJoinTeamData} />
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Tab.Pane>
                ),
            },
        ];
        return panes;
    }

    render() {
        return (
            <div>
                <div id="loader" style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.2)", width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0, zIndex: 99, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Loader style={{ display: "flex", fontFamily: "Noto Sans KR", fontSize: "30px" }}>loading ...</Loader>
                </div>
                {this.state.competitionData && <Tab panes={this.setLeagueTap()} />}
            </div>
        );
    }
}

export default CompetitionLeagueTab;
