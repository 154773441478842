import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import styles from "./Membersignup.module.css";
import { Button, Input, Dropdown, Form } from "semantic-ui-react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class MemberSignUp extends Component {
    state = {
        provinceId: "",
        // provinceData: "",
        provinceOptions: "",
        regionId: "",
        regionOptions: "",
        teamId: "",
        teamOptions: "",
        // member_homepage_id: "",
        // member_password: "",
        // name: "",
        birth: "",
        positionId: "",
        positionOptions: "",
        kindOptions: "",
        // phone: "",
        email: "",
        image_file: null,
        isDuplicateId: null,
        isDuplicateEmail: null,
        validEmail: false,
        errors: {
            duplicate_homepage_id: "",
            duplicate_email: "",
        },
        warningModalOpen: false,
        warningText: "",
        warningLocation: false,
        enc_data: "",
        userCookie: "",
        locationHeight: 0,
        tempSignInfoName: "",
        tempSignInfoPhone: "",
    };

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getProvinceList() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                // this.setState({ provinceData: data });
                let arr = [];
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let regions = [];
                let regionDict = {};
                for (let region of data) {
                    regions.push({ key: region.id, text: region.region_text, value: region.id });
                    regionDict[region.id] = region.region_text;
                }
                this.setState({
                    regionOptions: regions,
                    regionData: regionDict,
                });
            });
    }

    getTeamData(region_id) {
        fetch(Common.backendUrl + "/api/team/region/" + region_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ teamData: data });
                let arr = [];
                data.forEach((team) => arr.push({ key: team.id, text: team.team_text, value: team.id }));
                this.setState({ teamOptions: arr });
            });
    }

    getPositionData() {
        fetch(Common.backendUrl + "/api/position/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((position) => arr.push({ key: position.id, text: position.position_text, value: position.id }));
                this.setState({ positionOptions: arr });
            });
    }

    getAuthLevelData() {
        fetch(Common.backendUrl + "/api/authlevel/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((authlevel) => arr.push({ key: authlevel.id, text: authlevel.auth_text, value: authlevel.id }));
                this.setState({ authlevelOptions: arr });
            });
    }

    getKindList() {
        let arr = [];
        const kind = [
            { id: 1, kind: "일반" },
            { id: 2, kind: "유소년" },
        ];
        kind.forEach((data) => arr.push({ key: data.id, text: data.kind, value: data.id }));
        this.setState({ kindOptions: arr });
    }

    componentDidMount() {
        this.getTempSignInfo(this.props.match.params.tempSignInfoId);
        // this.getUserInfoCookie();
        this.getProvinceList();
        // this.getRegionData(this.state.provinceId);
        this.getPositionData();
        this.getAuthLevelData();
        this.getKindList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.province_id !== prevProps.province_id) {
            this.getRegionData(this.props.province_id);
        }

        if (this.props.region_id !== prevProps.region_id) {
            this.getTeamData(this.props.region_id);
        }
    }

    handleProvinceChange = (e, { value }) => {
        this.setState({ provinceId: value });
        this.getRegionData(value);
    };

    handleRegionChange = (e, { value }) => {
        this.setState({ regionId: value });
        this.getTeamData(value);
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamId: value });
    };

    // handleBirthChange = (event) => {
    //     this.setState({
    //         birth: event.target.value
    //     });
    // };

    handlePositionChange = (e, { value }) => {
        this.setState({ positionId: value });
    };

    // handleKindChange = (e, { value }) => {
    //     this.setState({ kind: value });
    // };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleDuplicateIdCheck = (e) => {
        e.preventDefault();
        const { t } = this.props;
        if (document.getElementById("member_hompage_id").value === "") {
            this.handleWarningModalOpen(t("signup.MemberSignUp.ID_Alert"));
            return;
        }
        fetch(Common.backendUrl + "/api/userid/" + document.getElementById("member_hompage_id").value)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                const isMemberIdFound = data.length;
                isMemberIdFound
                    ? this.setState({
                          // errors: {
                          //     duplicate_hompage_id: t('signup.MemberSignUp.Dup_Id_Alert'),
                          // },
                          warningModalOpen: true,
                          warningText: "중복된 아이디입니다. 아이디를 변경해주세요.",
                          isDuplicateId: true,
                      })
                    : this.setState({
                          // errors: {
                          //     duplicate_hompage_id: t('signup.MemberSignUp.Success_ID'),
                          // },
                          warningModalOpen: true,
                          warningText: "사용 가능한 아이디입니다.",
                          isDuplicateId: false,
                      });
            });
    };

    handleDuplicateEmailCheck = (e) => {
        e.preventDefault();
        const { t } = this.props;
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        if (document.getElementById("email_input").value === "") {
            this.handleWarningModalOpen(t("mypage.MemberMyPage.Change_Email_Alert"));
            return;
        }

        if (regExp.test(document.getElementById("email_input").value) === false) {
            this.handleWarningModalOpen(t("mypage.MemberMyPage.Email_Type_Alert"));
            return;
        }

        fetch(Common.backendUrl + "/api/useremail/" + document.getElementById("email_input").value)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                const isMemberEmailFound = data.length;
                isMemberEmailFound
                    ? this.setState({
                          // errors: {
                          //     duplicate_email: t('mypage.MemberMyPage.Dup_Email_Alert'),
                          // },
                          warningModalOpen: true,
                          warningText: "중복된 이메일입니다.",
                          isDuplicateEmail: true,
                      })
                    : this.setState({
                          // errors: {
                          //     duplicate_email: t('mypage.MemberMyPage.Success_email'),
                          // },
                          warningModalOpen: true,
                          warningText: "사용 가능한 이메일입니다.",
                          isDuplicateEmail: false,
                      });
            });
    };

    handleCancle = (e) => {
        e.preventDefault();
        window.location.href = "/login/";
        // this.props.history.goBack();
        //window.history.back();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();
        const { t } = this.props;
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        const csrftoken = this.getCookie("csrftoken");

        if (document.getElementById("kind").innerText === "일반") {
            form_data.append("kind", 1);
        } else if (document.getElementById("kind").innerText === "유소년") {
            form_data.append("kind", 2);
        } else {
            this.handleWarningModalOpen("일반/유소년을 선택해주세요.");
        }

        if (this.state.image_file !== null) {
            form_data.append("image", this.state.image_file.name);
            form_data.append("image_file", this.state.image_file);
        }

        if (document.getElementById("name").value === "") {
            this.handleWarningModalOpen("이름을 입력해주세요.");
            return;
        } else {
            form_data.append("member_text", document.getElementById("name").value);
        }

        if (document.getElementById("birth_date").value.length === 0) {
            this.handleWarningModalOpen("생년월일을 입력해주세요.");
            return;
        } else {
            form_data.append("birth_date", document.getElementById("birth_date").value);
        }

        if (this.state.isDuplicateEmail === true) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Dup_Email_Alert"));
            return;
        } else if (this.state.isDuplicateEmail === null) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Dup_Email_Check_Alert"));
            return;
        } else if (regExp.test(document.getElementById("email_input").value) === false) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Email_Type_Alert"));
            return;
        } else {
            form_data.append("member_email", document.getElementById("email_input").value);
            form_data.append("email", document.getElementById("email_input").value);
        }

        // form_data.append('email', this.state.email)
        form_data.append("member_position_id", 99);
        form_data.append("username", document.getElementById("member_hompage_id").value);
        form_data.append("password", document.getElementById("member_password").value);
        form_data.append("team_id", this.state.teamId);
        form_data.append("member_authlevel_id", 1);

        if (this.state.provinceId === "") {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Province_Alert"));
            return;
        } else if (this.state.regionId === "") {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Region_Alert"));
            return;
        } else if (this.state.teamId === "") {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Team_Alert"));
            return;
        } else if (document.getElementById("member_hompage_id").value === "") {
            this.handleWarningModalOpen(t("signup.MemberSignUp.ID_Alert"));
            return;
        } else if (this.state.isDuplicateId === true) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Dup_Id_Alert"));
            return;
        } else if (document.getElementById("member_password").value.length < 8 || document.getElementById("member_password").value.length > 16) {
            this.handleWarningModalOpen("비밀번호 8~16자리를 입력해주세요.");
            return;
        } else if (this.state.isDuplicateId === null) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Dup_Id_Check_Alert"));
            return;
        }

        // form_data.append('member_phone', this.state.userCookie[1]);
        form_data.append("member_phone", this.state.tempSignInfoPhone);
        if (sessionStorage.getItem("adult") === "false") {
            // form_data.append('parent_name', this.state.userCookie[0])
            form_data.append("parent_name", this.state.tempSignInfoName);
        }

        if (document.getElementById("member_password").value !== document.getElementById("member_password_check").value) {
            this.handleWarningModalOpen("비밀번호가 서로 일치하지 않습니다.");
            return;
        }

        if (this.state.isDuplicateEmail === false && this.state.isDuplicateId === false) {
            const request = new Request(Common.backendUrl + "/api/signup/", { headers: { "X-CSRFToken": csrftoken } });

            fetch(request, {
                method: "post",
                body: form_data,
            }).then((response) => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen("error");
                } else {
                    this.handleWarningModalOpen(t("signup.MemberSignUp.Success"));
                    this.setState({
                        warningLocation: true,
                    });
                    return response.json();
                }
            });
        }
    };

    getTempSignInfo = (id) => {
        fetch(Common.backendUrl + `/api/tempsigninfo/${id}`)
            .then((response) => {
                if (response.status >= 400) {
                    // this.setState({
                    //     warningModalOpen: true,
                    //     warningText: "error",
                    // })
                    alert("만료되었습니다.");
                    window.location.href = "/login";
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    tempSignInfoName: data.name,
                    tempSignInfoPhone: "0" + data.phone,
                });
            });
    };

    // getUserInfoCookie = () => {
    //     const cookie_info = document.cookie.match('(^|;) ?' + 'userinfo' + '=([^;]*)(;|$)');
    //     const value = cookie_info ? cookie_info[2] : null
    //     // console.log( cookie_info? cookie_info[2] : null);
    //     // console.log(value.split('/'))

    //     this.setState({
    //         userCookie: value.split('/')
    //     })
    // }

    handleWarningModalOpen(text) {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    }

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.warningLocation) {
            document.location.href = "/";
        }
    };

    render() {
        const { t } = this.props;

        return (
            <>
                <Topmenu />
                <div style={{ minHeight: "1600px", height: "100vh", backgroundColor: "red", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div className={styles.stars}></div>
                    <div className={styles.twinkling}></div>

                    <div className={styles.login_form_wrap}>
                        <div className={styles.login_form_div} style={{ width: "800px", maxWidth: "800px" }}>
                            <h3 style={{ fontSize: "18px", marginTop: "30px", fontFamily: "Noto Sans KR", color: "white" }}></h3>
                            <h1 style={{ fontSize: "30px", marginBottom: "45px", fontFamily: "Noto Sans KR", color: "white" }}>{t("signup.MemberSignUp.Header")}</h1>

                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group widths={2} style={{ marginBottom: "30px" }}>
                                    <Form.Field required>
                                        <label className={styles.modalLabel}>
                                            <span>{t("signup.MemberSignUp.Province")}</span>
                                        </label>
                                        <Dropdown placeholder={t("signup.MemberSignUp.Province_Select")} selection options={this.state.provinceOptions} name="province" onChange={this.handleProvinceChange} fluid />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className={styles.modalLabel}>
                                            <span>{t("signup.MemberSignUp.Region")}</span>
                                        </label>
                                        <Dropdown placeholder={t("signup.MemberSignUp.Region_Select")} selection options={this.state.regionOptions} name="region_text" onChange={this.handleRegionChange} fluid />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field required style={{ margin: "0px" }}>
                                    <label className={styles.modalLabel}>
                                        <span>{t("signup.MemberSignUp.Team_Name")}</span>
                                    </label>
                                    <Dropdown search placeholder={t("signup.MemberSignUp.Team_PlaceHolder")} selection options={this.state.teamOptions} name="team_text" onChange={this.handleTeamChange} fluid />
                                </Form.Field>
                                <label style={{ display: "block", margin: "0 0 30px", color: "white" }}>※ 소속팀이 리스트에 없을시 회원가입이 불가능합니다. 선수단장이 선수단 창단신청을 먼저 진행하세요.</label>
                                <Form.Field required>
                                    <label className={styles.modalLabel}>
                                        <span>{t("signup.MemberSignUp.General&Youth")}</span>
                                    </label>
                                    <Dropdown
                                        placeholder={t("signup.MemberSignUp.General&Youth")}
                                        selection
                                        options={this.state.kindOptions}
                                        name="kind"
                                        id="kind"
                                        fluid
                                        // onChange={this.handleKindChange}
                                    />
                                </Form.Field>
                                {sessionStorage.getItem("adult") === "true" ? (
                                    <>
                                        <Form.Field required style={{ marginBottom: "30px" }}>
                                            <label className={styles.modalLabel}>
                                                <span>{t("signup.MemberSignUp.Name")}</span>
                                            </label>
                                            <Input
                                                placeholder={t("signup.MemberSignUp.Name")}
                                                type="text"
                                                name="name"
                                                id="name"
                                                // value={this.state.userCookie[0]}
                                                value={this.state.tempSignInfoName}
                                                maxLength="20"
                                                style={{ pointerEvents: "none" }}
                                                required
                                            />
                                        </Form.Field>
                                        <Form.Field required style={{ marginBottom: "30px" }}>
                                            <label className={styles.modalLabel}>
                                                <span>{t("signup.MemberSignUp.Phone_Number")}</span>
                                            </label>
                                            <Input
                                                placeholder={t("signup.MemberSignUp.Phone_PlaceHolder")}
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                maxLength="20"
                                                // value={this.state.userCookie[1]}
                                                value={this.state.tempSignInfoPhone}
                                                style={{ pointerEvents: "none" }}
                                                required
                                            />
                                        </Form.Field>
                                        <Form.Field required style={{ marginBottom: "30px" }}>
                                            <label className={styles.modalLabel}>
                                                <span>{t("signup.MemberSignUp.Birth_Day")}</span>
                                            </label>
                                            <input type="date" id="birth_date" name="birth_date" required />
                                            {/* onChange={this.handleBirthChange} required /> */}
                                        </Form.Field>
                                    </>
                                ) : (
                                    <>
                                        <Form.Field required style={{ marginBottom: "30px" }}>
                                            <label className={styles.modalLabel}>
                                                <span>{t("signup.MemberSignUp.Name")}</span>
                                            </label>
                                            <Input placeholder={t("signup.MemberSignUp.Name")} type="text" name="name" id="name" maxLength="20" required />
                                        </Form.Field>

                                        <Form.Field required style={{ marginBottom: "30px" }}>
                                            <label className={styles.modalLabel}>
                                                <span>{t("signup.MemberSignUp.Birth_Day")}</span>
                                            </label>
                                            <input type="date" id="birth_date" name="birth_date" required />
                                            {/* onChange={this.handleBirthChange} required /> */}
                                        </Form.Field>
                                    </>
                                )}
                                <Form.Field required style={{ marginBottom: "30px" }}>
                                    <label className={styles.modalLabel}>
                                        <span>{t("signup.MemberSignUp.Id")}</span>
                                    </label>
                                    <Input
                                        placeholder={t("signup.MemberSignUp.Id_PlaceHolder")}
                                        type="text"
                                        name="member_hompage_id"
                                        id="member_hompage_id"
                                        minLength="4"
                                        maxLength="20"
                                        // onChange={this.handleMemberHomepageIdChange}
                                        required
                                    />
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                                        {/* {this.state.isDuplicateId === true &&
                                            <div className={styles.invalid}>{this.state.errors.duplicate_hompage_id}</div>
                                        }
                                        {this.state.isDuplicateId === false &&
                                            <div className={styles.valid}>{this.state.errors.duplicate_hompage_id}</div>
                                        } */}
                                        <a className={`${styles.style_a1} ${styles.mouse_hover} `} style={{ marginTop: "5px", width: "fit-content" }} onClick={this.handleDuplicateIdCheck}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("signup.MemberSignUp.Dup_Id")}
                                        </a>
                                    </div>
                                </Form.Field>
                                <Form.Field required style={{ marginBottom: "30px" }}>
                                    <label className={styles.modalLabel}>
                                        <span>{t("signup.MemberSignUp.Password")}</span>
                                    </label>
                                    <Input
                                        placeholder={t("signup.MemberSignUp.Password_placeholder")}
                                        type="password"
                                        name="member_password"
                                        id="member_password"
                                        maxLength="16"
                                        minLength="8"
                                        // onChange={this.handlePasswordChange}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field required style={{ marginBottom: "30px" }}>
                                    <label className={styles.modalLabel}>
                                        <span>비밀번호 확인</span>
                                    </label>
                                    <Input
                                        placeholder="비밀번호 확인"
                                        type="password"
                                        name="member_password_check"
                                        id="member_password_check"
                                        maxLength="16"
                                        minLength="8"
                                        // onChange={this.handlePasswordChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field required style={{ marginBottom: "30px" }}>
                                    <label className={styles.modalLabel}>
                                        <span>{t("signup.MemberSignUp.Email")}</span>
                                    </label>
                                    <Input
                                        placeholder="dronesoccer@naver.com"
                                        type="text"
                                        name="member_email"
                                        id="email_input"
                                        maxLength="40"
                                        // onChange={this.handleEmailChange}
                                        required
                                    />

                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}>
                                        {/* {this.state.isDuplicateEmail === true &&
                                            <div className={styles.invalid}>중복된 이메일입니다.</div>
                                        }
                                        {this.state.isDuplicateEmail === false &&
                                            <div className={styles.valid}>사용가능한 이메일입니다.</div>
                                        } */}
                                        <a className={`${styles.style_a1} ${styles.mouse_hover} `} onClick={this.handleLogin} style={{ marginTop: "5px", width: "fit-content" }} onClick={this.handleDuplicateEmailCheck}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("signup.MemberSignUp.Dup_Email")}
                                        </a>
                                    </div>
                                </Form.Field>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <a className={`${styles.style_a2} ${styles.mouse_hover} `} style={{ marginTop: "20px" }} onClick={this.handleSubmit}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        {t("signup.MemberSignUp.Sign_Up")}
                                    </a>
                                    <a className={`${styles.style_a3} ${styles.mouse_hover} `} style={{ marginTop: "20px" }} onClick={this.handleCancle}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        {t("signup.MemberSignUp.Cancle")}
                                    </a>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <Footer />
            </>
        );
    }
}

export default withTranslation()(MemberSignUp);
