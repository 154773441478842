import React, { Component } from "react";
import { Button, Segment, Loader, Icon, Modal, Input, Table, TableRow } from "semantic-ui-react";
import Common from "../Common.js";
import styles from "../admin_page/admin_menu/manage_team/PlayerSearch.module.css";

class PlayerAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            playerList: "",
            teamMembers: "",
            teamMembersLen: "",
            prevRegMember: "",
            openSearchLoading: false,
            openTeamLoading: true,
            gameJoinId: "",
        };
    }

    componentDidMount = () => {
        this.getGameJoinData(this.props.competitionId, this.props.teamId);
    };

    getGameJoinData = (competitionId, teamId) => {
        fetch(Common.backendUrl + `/api/competitiont_aplication/get/${competitionId}/${teamId}`)
            // fetch(Common.backendUrl + `/api/competitiont_aplication/get/${competitionId}/${100341}`)
            // fetch(Common.backendUrl + `/api/competitiont_aplication/get/${competitionId}/${100363}`)
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다.");
                }
                return res.json();
            })
            .then((json) => {
                if (json[0]) {
                    if (json[0].reg_member) {
                        this.getTeamMembers(json[0].reg_member.slice(3, -3).split("|||"));
                        this.setState({
                            prevRegMember: json[0].reg_member.slice(3, -3).split("|||"),
                            gameJoinId: json[0].id,
                        });
                    } else {
                        this.setState({
                            teamMembers: "",
                            prevRegMember: "",
                            openTeamLoading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                alert(error + "");
                this.setState({
                    openTeamLoading: false,
                });
            });
        this.setState({
            openTeamLoading: true,
        });
    };

    getTeamMembers = (memberData) => {
        if (memberData.length !== 0) {
            let teamMembersId = new Array();
            teamMembersId.push(memberData.map((member) => member.split(",")[0]));

            const formData = new FormData();
            formData.append("ids", teamMembersId);

            fetch(Common.backendUrl + `/api/profile/getmembers`, {
                method: "put",
                body: formData,
            })
                .then((res) => {
                    if (res.status >= 400) {
                        alert("getTeamMembers error");
                        return;
                    }
                    return res.json();
                })
                .then((json) => {
                    let teamMembers = new Array();
                    if (json) {
                        json.map((data) => {
                            teamMembers.push([data.member_text, data.member_position.position_text, data.id, data.birth_date, data.team.team_text, data.team.league.league_text, data.team.id]);
                        });
                        this.setState({
                            teamMembers,
                        });
                    }
                });
        }
    };

    getPlayerSearchData = (playerName) => {
        fetch(Common.backendUrl + `/api/player/search/${playerName}`)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    playerList: data,
                    openSearchLoading: false,
                });
            });
    };

    clickSearchBtn = (e) => {
        e.preventDefault();
        if (document.getElementById("searchPlayerName").value === "") {
            alert("선수명을 입력하세요");
            return;
        }
        this.setState({
            openSearchLoading: true,
            playerList: "",
        });

        this.getPlayerSearchData(document.getElementById("searchPlayerName").value);
    };

    searchPlayerClick = (e, playerId, playerName, teamName, league, teamId) => {
        // 검색 결과목록 클릭시 추가 후 리로드
        e.preventDefault();
        let newRegMember = new Array();
        let stop = false;
        if (!window.confirm(`${playerName} 선수를 추가하시겠습니까?`)) {
            return;
        }

        if (this.state.prevRegMember) {
            this.state.prevRegMember.map((member) => {
                if (member === (playerId + "," + teamName + "," + teamId + "," + league).toString()) {
                    alert("중복된 선수입니다.");
                    stop = true;
                    return;
                }
                newRegMember.push(member);
            });
        }

        if (stop === false) {
            newRegMember.push(playerId + "," + teamName + "," + teamId + "," + league);
            let newRegMemberString = "|||" + newRegMember.join("|||") + "|||";

            const formData = new FormData();
            formData.append("reg_member", newRegMemberString);

            this.setState({
                prevRegMember: "",
                playerList: "",
                // teamMembers: ""
            });

            fetch(Common.backendUrl + `/api/competitiont_aplication/update/${this.props.competitionId}/${this.props.teamId}`, {
                method: "put",
                body: formData,
            })
                .then((res) => {
                    if (res.status >= 400) {
                        alert("error");
                        return;
                    }
                    return res.json();
                })
                .then((json) => {
                    if (json[0] === "success") {
                        this.getGameJoinData(this.props.competitionId, this.props.teamId);
                        return;
                    } else {
                        alert("error");
                    }
                });
        }
    };

    // teamMembersClick = (e, index, playerName) => {
    teamMembersClick = (e, playerId, playerName) => {
        e.preventDefault();

        if (!window.confirm(`${playerName} 선수를 삭제하시겠습니까?`)) {
            return;
        }

        let newRegMember = new Array();
        let newRegMemberString = "";
        this.state.prevRegMember.map((member) => newRegMember.push(member));

        // newRegMember.splice(index, 1);
        // console.log("test: ", newRegMember, playerId);
        // let findIndex = newRegMember.map((member, index) => {
        let findIndex = "";
        newRegMember.forEach((member, index) => {
            if (parseInt(member.split(",")[0]) === playerId) {
                findIndex = index;
            }
        });
        newRegMember.splice(findIndex, 1);

        if (newRegMember.length !== 0) {
            newRegMemberString = "|||" + newRegMember.join("|||") + "|||";
        }

        const formData = new FormData();
        formData.append("reg_member", newRegMemberString);

        this.setState({
            // prevRegMember: "",
            // playerList: "",
            // teamMembers: "",
            openTeamLoading: false,
        });

        fetch(Common.backendUrl + `/api/competitiont_aplication/update/${this.props.competitionId}/${this.props.teamId}`, {
            method: "put",
            body: formData,
        })
            .then((res) => {
                if (res.status >= 400) {
                    alert("error");
                    return;
                }
                return res.json();
            })
            .then((json) => {
                if (json[0] === "success") {
                    this.getGameJoinData(this.props.competitionId, this.props.teamId);
                    return;
                } else {
                    alert("error");
                }
            });
    };

    // 팀 신청 완료 후 다시 반려상태로 되돌리기
    // competition_aplication 테이블 register_state 변경 및 game_join 테이블 데이터 삭제
    undoTeamApplication = (e) => {
        e.preventDefault();
        this.undoGamjoinCompetitionAplicationData();
    };

    undoGamjoinCompetitionAplicationData = () => {
        const formData = new FormData();
        formData.append("register_state", 2);

        fetch(Common.backendUrl + `/api/competition_aplication/gamejoin/undo/${this.props.id}/${this.state.gameJoinId}`, {
            method: "put",
            body: formData,
        })
            .then((res) => {
                if (res.status >= 400) {
                    alert("신청상태를 변경하지 못했습니다.");
                    return;
                }
                return res.json();
            })
            .then((json) => {
                if (json === "success") {
                    this.props.reloadPage();
                    this.setState({ modalOpen: false });
                }
            });
    };

    render() {
        return (
            <Modal
                size="large"
                onClose={() => this.setState({ modalOpen: false })}
                onOpen={() => this.setState({ modalOpen: true })}
                open={this.state.modalOpen}
                trigger={
                    <Button primary color="google plus" style={{ margin: "0px 0px 0px 0px" }} size="mini">
                        참가선수변경
                    </Button>
                }
            >
                <Modal.Content>
                    <p style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px", textAlign: "center" }}>참가선수변경</p>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ marginRight: "20px" }}>
                            <div style={{ marginBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "38px" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>참가 선수</p>
                            </div>
                            <Table striped unstackable style={{ border: "1px solid #000000", margin: 0 }}>
                                <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed", borderBottom: "1px solid #000000" }}>
                                    <Table.Row style={{ width: "100%" }}>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            소속팀
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            이름
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            생년월일
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            포지션
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>
                                    {this.state.teamMembers !== ""
                                        ? this.state.teamMembers.map((player, index) => (
                                              // <TableRow key={player.id} onClick={(e) => this.teamMembersClick(e, index, player[0])} className={styles.tableRow} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                              <TableRow key={index} onClick={(e) => this.teamMembersClick(e, player[2], player[0])} className={styles.tableRow} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                                  <Table.Cell textAlign="center">{player[4]}</Table.Cell>
                                                  <Table.Cell textAlign="center">{player[0]}</Table.Cell>
                                                  {player[3] !== null ? <Table.Cell textAlign="center">{player[3].split("T")[0]}</Table.Cell> : <Table.Cell textAlign="center">생년월일을 입력하세요.</Table.Cell>}
                                                  <Table.Cell textAlign="center">{player[1]}</Table.Cell>
                                              </TableRow>
                                          ))
                                        : this.state.openTeamLoading && (
                                              <Segment style={{ position: "reletive", width: "100%", height: "100%", borderRadius: 0, backgroundColor: "rgba(0,0,0,0.2)" }}>
                                                  <Loader active inline style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                      Loading
                                                  </Loader>
                                              </Segment>
                                          )}
                                </Table.Body>
                            </Table>
                        </div>
                        <div>
                            <div style={{ marginBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Input id="searchPlayerName" placeholder="검색할 선수명을 입력하세요." style={{ width: "300px", marginRight: "10px", border: "1px solid #000000", borderRadius: ".28571429rem" }} />
                                <Button primary onClick={(e) => this.clickSearchBtn(e)}>
                                    검색
                                </Button>
                            </div>
                            <Table striped unstackable style={{ border: "1px solid #000000", margin: 0 }}>
                                <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed", borderBottom: "1px solid #000000" }}>
                                    <Table.Row style={{ width: "100%" }}>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            소속팀
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            이름
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            생년월일
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width="1" textAlign="center">
                                            포지션
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>
                                    {this.state.playerList !== ""
                                        ? this.state.playerList.map((player) => (
                                              <TableRow key={player.id} onClick={(e) => this.searchPlayerClick(e, player.id, player.member_text, player.team.team_text, player.team.league.league_text, player.team.id)} className={styles.tableRow} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                                  <Table.Cell textAlign="center">{player.team.team_text}</Table.Cell>
                                                  <Table.Cell textAlign="center">{player.member_text}</Table.Cell>
                                                  {player.birth_date !== null ? <Table.Cell textAlign="center">{player.birth_date.split("T")[0]}</Table.Cell> : <Table.Cell textAlign="center">생년월일을 입력하세요.</Table.Cell>}
                                                  <Table.Cell textAlign="center">{player.member_position.position_text}</Table.Cell>
                                              </TableRow>
                                          ))
                                        : this.state.openSearchLoading && (
                                              <Segment style={{ position: "reletive", width: "100%", height: "100%", borderRadius: 0, backgroundColor: "rgba(0,0,0,0.25)" }}>
                                                  <Loader active inline style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                                      Finding
                                                  </Loader>
                                              </Segment>
                                          )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    {this.props.competitionState !== "대회종료" && (
                        <Button color="red" inverted onClick={(e) => this.undoTeamApplication(e)}>
                            <Icon name="undo" /> 반려
                        </Button>
                    )}
                    <Button color="black" onClick={() => this.setState({ modalOpen: false })}>
                        <Icon name="remove" /> 닫기
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default PlayerAddModal;
