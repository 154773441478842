import React, { Component } from "react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import { Container, Header, Segment, Button, Table, Grid, Dropdown, Loader, Pagination } from "semantic-ui-react";
import Common from "../../../Common";
import moment from "moment";
import "moment/locale/ko";
import styles from "./CsvDownloadManager.module.css";

class CsvDownloadManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leagueOptions: [],
            provinceData: [],
            provinceOptions: [],
            regionOptions: [{ key: -1, text: "선택안함", value: -1 }],
            selMembership: -1,
            selProvinceId: -1,
            selRegionId: -1,
            selLeagueId: -1,
            teamData: [],
            totalPage: 0,
            selectedPage: 1,
            isLoading: false,
            warningModalOpen: false,
            warningText: "",
            selectedPage: 0,
        };
    }

    componentDidMount() {
        this.getLeagueData();
        this.getProvinceData();
    }

    getLeagueData() {
        fetch(Common.backendUrl + "/api/league/")
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                arr.push({ key: -1, text: "선택안함", value: -1 });
                data.forEach((league) => arr.push({ key: league.id, text: league.league_text, value: league.id }));
                this.setState({ leagueOptions: arr });
            })
            .catch((error) => {
                this.setState({
                    warningModalOpen: true,
                    warningText: error + "",
                });
            });
    }

    getProvinceData() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ provinceData: data });
                let arr = [];
                arr.push({ key: -1, text: "선택안함", value: -1 });
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            })
            .catch((error) => {
                this.setState({
                    warningModalOpen: true,
                    warningText: error + "",
                });
            });
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                    });
                }
                return response.json();
            })
            .then((data) => {
                let regions = [];
                regions.push({ key: -1, text: "선택안함", value: -1 });
                for (let region of data) {
                    regions.push({ key: region.id, text: region.region_text, value: region.id });
                }
                this.setState({ regionOptions: regions });
            })
            .catch((error) => {
                this.setState({
                    warningModalOpen: true,
                    warningText: error + "",
                });
            });
    }

    membershipChange = (e, { value }) => {
        this.setState({
            selMembership: value,
        });
    };

    leagueChange = (e, { value }) => {
        this.setState({ selLeagueId: value });
    };

    provinceChange = (e, { value }) => {
        this.setState({ selProvinceId: value, selRegionId: -1 });
        if (value !== -1) {
            this.getRegionData(value);
        }
    };

    regionChange = (e, { value }) => {
        this.setState({ selRegionId: value });
    };

    getTeamResult = (page) => {
        if (this.state.selMembership === -1 && this.state.selLeagueId === -1 && this.state.selProvinceId === -1 && this.state.selRegionId === -1) {
            this.setState({
                warningModalOpen: true,
                warningText: "선수단 조회 옵션을 선택해주세요.",
            });
            return;
        }
        this.setState({
            isLoading: true,
        });

        fetch(Common.backendUrl + `/api/team/csv/${this.state.selMembership}/${this.state.selLeagueId}/${this.state.selProvinceId}/${this.state.selRegionId}/?page=${page}`, {
            method: "get",
            headers: {
                Authorization: `JWT ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
                return res.json();
            })
            .then((json) => {
                if (json.count % 30 === 0) {
                    this.setState({
                        totalPage: parseInt(json.count / 30),
                    });
                } else {
                    this.setState({
                        totalPage: parseInt(json.count / 30) + 1,
                    });
                }
                this.setState({
                    teamData: json.results,
                });
            })
            .catch((error) => {
                this.setState({
                    warningModalOpen: true,
                    warningText: error + "",
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    downloadTeamResultCsvFile = () => {
        if (this.state.selMembership === -1 && this.state.selLeagueId === -1 && this.state.selProvinceId === -1 && this.state.selRegionId === -1) {
            this.setState({
                warningModalOpen: true,
                warningText: "선수단 조회 옵션을 선택해주세요.",
            });
            return;
        }

        fetch(Common.backendUrl + `/api/download/team/csv/${this.state.selMembership}/${this.state.selLeagueId}/${this.state.selProvinceId}/${this.state.selRegionId}/`, {
            method: "get",
            headers: {
                Authorization: `JWT ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let _url = window.URL.createObjectURL(blob);

                a.href = _url;
                a.download = `선수단목록_${moment(new Date()).locale("ko").format("lll")}.csv`;
                a.click();
                window.URL.revokeObjectURL(_url);
            });
    };

    downloadTeamMemberCsvFile = (team_id, team_text) => {
        fetch(Common.backendUrl + `/api/download/team/member/csv/${team_id}/`, {
            method: "get",
            headers: {
                Authorization: `JWT ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let _url = window.URL.createObjectURL(blob);

                a.href = _url;
                a.download = `${team_text}_${moment(new Date()).locale("ko").format("lll")}.csv`;
                a.click();
                window.URL.revokeObjectURL(_url);
            });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
            warningText: "",
        });
    };

    navOnClick = (e, page) => {
        if (isNaN(page)) {
            return;
        }

        this.setState({
            selectedPage: page,
        });
        this.getTeamResult(page);
    };

    render() {
        return (
            <div>
                <Container style={{ padding: "1em 5em", height: "100%" }} fluid>
                    <Grid divided="vertically">
                        <Grid.Row columns="equal">
                            <Grid.Column textAlign="center">
                                <Button size="mini" color="blue" onClick={() => this.getTeamResult(1)}>
                                    선수단 조회
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <Dropdown
                                    style={{ border: "1px solid #000000" }}
                                    onChange={this.membershipChange}
                                    value={this.state.selMembership}
                                    options={[
                                        { key: -1, text: "선택안함", value: -1 },
                                        { key: 0, text: "일반회원", value: 0 },
                                        { key: 1, text: "정회원", value: 1 },
                                    ]}
                                    selection
                                    placeholder="지부선택"
                                    fluid
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.leagueChange} value={this.state.selLeagueId} options={this.state.leagueOptions} selection placeholder="리그선택" fluid />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.provinceChange} value={this.state.selProvinceId} options={this.state.provinceOptions} selection placeholder="지회선택" fluid />
                            </Grid.Column>

                            <Grid.Column>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.regionChange} value={this.state.selRegionId} options={this.state.regionOptions} selection placeholder="지부선택" fluid />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="equal">
                            <Grid.Column textAlign="center">
                                <Segment attached="top" clearing secondary style={{ width: "100%", border: "1px solid #000000" }}>
                                    <Header as="h3" floated="left">
                                        조회 결과
                                    </Header>
                                    <Header as="h3" floated="right">
                                        <Button size="mini" color="blue" onClick={this.downloadTeamResultCsvFile}>
                                            조회결과 다운로드
                                        </Button>
                                    </Header>
                                </Segment>
                                <Segment attached style={{ display: "block", width: "100%", overflow: "auto", border: "1px solid #000000", borderTop: "0" }}>
                                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    멤버쉽
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    리그
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    지회
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    지부
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    팀명
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    단장
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                                    연락처
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body style={{ width: "100%" }}>
                                            {this.state.isLoading ? (
                                                <Table.Row style={{ width: "100%", height: "100%", borderRadius: 0, backgroundColor: "rgba(0,0,0,0.2)" }}>
                                                    <Table.Cell colSpan="7" textAlign="center">
                                                        <Loader active inline style={{ width: "100%" }}>
                                                            Loading
                                                        </Loader>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                <>
                                                    {this.state.teamData.length === 0 && (
                                                        <Table.Row>
                                                            <Table.Cell textAlign="center" colSpan="7">
                                                                조회된 결과가 없습니다.
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {this.state.teamData.map((profile) => {
                                                        return (
                                                            <Table.Row className={styles.table_row} key={profile.id} onClick={() => this.downloadTeamMemberCsvFile(profile.team.id, profile.team.team_text)}>
                                                                <Table.Cell textAlign="center">{profile.team.membership ? "정회원" : "일반회원"}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.team.league.league_text}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.team.region.province.province_text}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.team.region.region_text}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.team.team_text}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.member_text}</Table.Cell>
                                                                <Table.Cell textAlign="center">{profile.member_phone}</Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </Table.Body>
                                    </Table>
                                    {this.state.totalPage > 0 && <Pagination style={{ marginTop: "10px" }} boundaryRange={1} activePage={this.state.selectedPage} ellipsisItem={null} firstItem={null} lastItem={null} siblingRange={2} totalPages={this.state.totalPages} size="mini" onPageChange={(e, data) => this.navOnClick(e, data.activePage)} />}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

export default CsvDownloadManager;
