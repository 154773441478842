import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import styles from "./competiton_application.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";

import {
  Button,
  Form,
  Table,
  TableRow,
  Image,
  Header,
  Grid,
  Checkbox,
  Segment,
} from "semantic-ui-react";

import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";

class CompetitionApplication extends Component {
  state = {
    competitionData: "",
    reg_member: "",
    teamId: "",
    leagueId: "",
    memberList: "",
    warningModalOpen: false,
    warningText: "",
    warningLocation: false,
    checkScrollTo: 0,
  };

  async getTeamInfomation() {
    if (sessionStorage.getItem("token")) {
      await fetch(Common.backendUrl + "/api/current/", {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            teamId: json.profile.team.id,
            leagueId: json.profile.team.league.id,
          });
          this.getMemberData(json.profile.team.id);
        });
    }
  }

  async getMemberData(team_id) {
    await fetch(Common.backendUrl + "/api/teammember/" + team_id)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          memberList: data,
        });
      });
  }

  getCompetitionData() {
    fetch(
      Common.backendUrl +
        "/api/competition/" +
        this.props.match.params.competitionId
    )
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let competition_data = {
          competition_id: data.id,
          competition_text: data.competition_text,
          registration_start_date: data.registration_start_date,
          registration_end_date: data.registration_end_date,
          competition_state: data.competition_state.state_text,
          competition_state_id: data.competition_state.id,
        };
        this.setState({
          competitionData: competition_data,
        });
      });
  }

  componentDidMount() {
    this.getTeamInfomation();
    // this.getMembershipList();
    this.getCompetitionData();
  }

  componentDidUpdate() {
    window.scrollTo(this.state.checkScrollTo, this.state.checkScrollTo);
  }

  handleCheckBoxClick(e) {
    this.setState({
      checkScrollTo: document.getElementById("getOffsetTop").offsetTop,
    });

    let members = document.querySelectorAll(".members");
    let idArray = [];
    let idString = "";
    if (members.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].checked == true) {
          idString = idString + "|||" + members[i].value;
          idArray.push(members[i].value);
        }
      }
    }
    idString = idString + "|||";
    this.setState({ reg_member: idString });
  }

  handleCancle = (e) => {
    e.preventDefault();
    window.history.back();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();
    let parse = this.state.reg_member.split("|||");

    if (this.state.reg_member === "" || this.state.reg_member === "|||") {
      this.handleWarningModalOpen("선수를 선택해주세요");
      return;
    }

    if (parse.length < 5) {
      this.handleWarningModalOpen("3명 이상의 선수를 선택해주세요");
      return;
    }

    if (parse.length > 12) {
      this.handleWarningModalOpen("최대 10명의 선수만 참가 가능합니다.");
      return;
    }

    form_data.append("reg_member", this.state.reg_member);
    form_data.append("team_id", this.state.teamId);
    form_data.append("competition_id", this.props.match.params.competitionId);
    form_data.append("league", this.state.leagueId);

    fetch(Common.backendUrl + "/api/competitiont_aplication/make/", {
      method: "post",
      body: form_data,
    }).then((response) => {
      if (response.status >= 400) {
        if (response.status === 409) {
          this.handleWarningModalOpen("이미 참가신청을 하였습니다.");
          return;
        } else {
          this.handleWarningModalOpen("error");
          return;
        }
      } else {
        this.handleWarningModalOpen("대회 참가 신청을 완료하였습니다.");
        this.setState({
          warningLocation: true,
        });
        return response.json();
      }
    });
  };

  cleanDate(dateStr) {
    if (dateStr) {
      let parts = dateStr.split("-");
      let day = parts[2].split("T");

      let d = parts[0] + "-" + parts[1] + "-" + day[0];
      return d;
    } else {
      return;
    }
  }

  handleWarningModalOpen = (text) => {
    this.setState({
      warningLocation: false,
      warningModalOpen: true,
      warningText: text,
    });
  };

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });
    if (this.state.warningLocation) {
      document.location.href = "/";
    }
  };

  render() {
    let list = [];
    if (this.state.memberList !== "") {
      list[0] = this.state.memberList.map((info) => (
        <TableRow key={info.id}>
          <Table.Cell textAlign="center">{info.member_text}</Table.Cell>
          <Table.Cell textAlign="center">{info.member_id}</Table.Cell>
          {info.member_position !== null && (
            <Table.Cell textAlign="center">
              {info.member_position.position_text}
            </Table.Cell>
          )}
          {info.member_position === null && (
            <Table.Cell textAlign="center">{info.member_position}</Table.Cell>
          )}
          <Table.Cell textAlign="center">
            <input
              className="members"
              name="test"
              type="checkbox"
              value={
                info.id +
                "," +
                info.team.team_text +
                "," +
                info.team.id +
                "," +
                info.team.league.league_text
              }
              onClick={(e) => this.handleCheckBoxClick(e)}
            ></input>
          </Table.Cell>
        </TableRow>
      ));
    }

    return (
      <>
        <Topmenu />
        <div
          style={{
            minHeight: "1500px",
            backgroundColor: "red",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div className={styles.stars}></div>
          <div className={styles.twinkling}></div>
          <div className={styles.clouds}></div>
          {/*  */}
          {/*  */}
          <div className={styles.login_form_wrap}>
            <div
              className={styles.login_form_div}
              style={{ width: "800px", maxWidth: "800px" }}
            >
              <h3
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginTop: "30px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                Application for participation
              </h3>
              <h1
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginBottom: "100px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                참가 신청
              </h1>
              <Grid divided="vertically">
                <Grid.Row columns={1}>
                  <Header as="h2">
                    <label className={styles.competition}>
                      <span>대회정보</span>
                    </label>
                  </Header>
                  <Grid.Column>
                    <Segment.Group>
                      <Segment>
                        대회명 : {this.state.competitionData.competition_text}
                        <br />
                      </Segment>
                      <Segment>
                        접수 시작일 :{" "}
                        {this.cleanDate(
                          this.state.competitionData.registration_start_date
                        )}
                        <br />
                      </Segment>
                      <Segment>
                        접수 종료일 :{" "}
                        {this.cleanDate(
                          this.state.competitionData.registration_end_date
                        )}
                        <br />
                      </Segment>
                    </Segment.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} id="getOffsetTop">
                  <Header as="h2">
                    <label className={styles.competition}>
                      <span>선수 등록</span>
                    </label>
                  </Header>
                  <Grid.Column>
                    <Form onSubmit={this.handleSubmit}>
                      <Table striped unstackable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={1} textAlign="center">
                              이름
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign="center">
                              선수자격번호
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign="center">
                              포지션
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign="center">
                              참가여부
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>{list[0]}</Table.Body>
                      </Table>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <a
                          className={`${styles.style_a2} ${styles.mouse_hover} `}
                          style={{ marginTop: "20px" }}
                          onClick={this.handleSubmit}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          참가신청
                        </a>
                        <a
                          className={`${styles.style_a3} ${styles.mouse_hover} `}
                          style={{ marginTop: "20px" }}
                          onClick={this.handleCancle}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          취소
                        </a>
                      </div>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <ModalChoiceOne
            isOpen={this.state.warningModalOpen}
            warningText={this.state.warningText}
            closeMethod={this.handleWarningModalClose}
          />
        </div>
        <Footer />
      </>
    );
  }
}

export default CompetitionApplication;
