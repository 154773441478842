import React, { Component } from 'react';
import {
    Button,
    Header,
    Segment,
} from 'semantic-ui-react'
import Common from '../../../Common.js';
import AddBannerModal from './AddBannerModal.js';
import EditBannerModal from "./EditBannerModal.js";

class ManageArena extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: "",
            addModalOpen: false,
            editModalOpen: false,
            selectBanner: "",
        }
    }


    componentDidMount = () => {
        this.getBannerData();
    }

    getBannerData = () => {
        fetch(Common.backendUrl + `/api/banner/list/`)
            .then(res => {
                if (res.status > 400) {
                    alert("error");
                }
                return res.json();
            }).then(banners => {
                // console.log(banners)
                this.setState({
                    banners
                })
            })
    }


    handleAddArenaBtn = (e) => {
        e.preventDefault();
        // alert(" 경기장 추가버튼 클릭!");
        this.setState({
            addModalOpen: true,
        })
    }

    handleEditArenaBtn = (e, arena) => {
        e.preventDefault();
        this.setState({
            editModalOpen: true,
            selectBanner: arena
        })
    }

    handleRemoveArenaBtn = (e, id) => {
        e.preventDefault();
        if (!window.confirm("정말 삭제하시겠습니까?")) {
            return
        }
        // alert("경기장 삭제버튼 클릭!" + id);
        fetch(Common.backendUrl + `/api/banner/update/` + id, {
            method: 'DELETE',
        })
            .then(res => {
                alert("삭제했습니다.")
                this.getBannerData();
            }) // or res.json()
    }

    modalCloseMethod = () => {
        this.setState({
            editModalOpen: false,
            addModalOpen: false,
        })
        this.getBannerData()
    }


    render() {
        const { banners } = this.state;
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Segment attached='top' clearing secondary style={{border: "1px solid #000000"}}>
                    <Header style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} attached='top' >
                        <Header style={{ margin: "0px" }}>배너 관리</Header>
                        <Button color="blue" inverted onClick={(e) => this.handleAddArenaBtn(e)}>배너 추가</Button>
                    </Header>
                </Segment>
                <Segment attached style={{border: "1px solid #000000", borderTop: "0"}}>
                    <Segment clearing secondary style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", border: "1px solid #000000" }} attached='bottom' >
                        <div style={{ width: "100%" }}>
                            {banners.length > 0 ?
                                banners.map((arena, index) => {
                                    return (
                                        <Segment key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", border: "1px solid #000000" }}>
                                            <p style={{ margin: "0px", fontWeight: "bold", fontSize: "1em", color: "#000000" }}>{arena.name}</p>
                                            <div>
                                                <Button size="mini" color="green" inverted onClick={(e) => this.handleEditArenaBtn(e, arena)} >수정</Button>
                                                <Button size="mini" color="red" inverted onClick={(e) => this.handleRemoveArenaBtn(e, arena.id)} >삭제</Button>
                                            </div>
                                        </Segment>
                                    )
                                })
                                :
                                <></>
                            }
                        </div>
                    </Segment>
                </Segment>

                <AddBannerModal isOpen={this.state.addModalOpen} closeMethod={this.modalCloseMethod} />
                <EditBannerModal isOpen={this.state.editModalOpen} banner={this.state.selectBanner} closeMethod={this.modalCloseMethod} />
            </div>
        );
    }
}

export default ManageArena;