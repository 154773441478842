import React, { Component } from 'react'
import styles from "./MainVideo.module.css";
import {isMobile, isTablet, isAndroid, isIOS} from "react-device-detect"
import ReactPlayer from 'react-player';


export default class MainVideo extends Component {

    renderContent = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <div style={{ overflow: "hidden" }}>
                <div className={styles.iframe_div} style={{ position: "relative", width: "100vw", height: "100%", pointerEvents: "none" }}>
                    <ReactPlayer
                        playsinline
                        muted
                        loop
                        playing
                        style={{ position: "absolute", top: "0", left: "0" }}
                        url="https://www.youtube.com/embed/vbrXrw0eh4k"
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        } else {
            return <div style={{ overflow: "hidden" }}>
                <div className={styles.iframe_div} style={{ position: "relative", width: "100vw", height: "100%", pointerEvents: "none" }}>
                    <ReactPlayer
                        playsinline
                        muted
                        loop
                        playing
                        style={{ position: "absolute", top: "0", left: "0" }}
                        url="https://www.youtube.com/embed/vbrXrw0eh4k"
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        }
    }

    render() {
        return this.renderContent();
    }
}

