import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';

class BoardHeaderDesktop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div >
                    <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #000000", lineHeight: '29px' }}>
                        <div style={{ backgroundColor: "#E4EAF8", fontWeight: "bold", width: "12%", borderRight: "1px solid #020202", textAlign: "center", fontFamily: "Noto Sans KR", fontSize: "14px" }}>{t('board.notice.Title')}</div>
                        {this.props.isImportent ?
                            <div style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "14px" }}>&nbsp;&nbsp;<span style={{ fontWeight: "800", marginRight: "5px", color: "#ff4e59", fontSize: "10px", backgroundColor: "#ffe3e4", border: "1px solid #ffc6c9", borderRadius: "3px", padding: "0px 3px" }}>공지</span>{this.props.subject}</div>
                            :
                            <div style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "14px" }}>&nbsp;&nbsp;{this.props.subject}</div>
                        }

                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #000000", lineHeight: '29px' }}>
                        <div style={{backgroundColor: "#E4EAF8", fontWeight: "bold", width: "12%", borderRight: "1px solid #020202", textAlign: "center", fontFamily: "Noto Sans KR", fontSize: "14px" }}>{t('board.notice.Writer')}</div>
                        <div style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "14px" }}>&nbsp;&nbsp;{this.props.writer}</div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #000000", lineHeight: '29px' }}>
                        <div style={{backgroundColor: "#E4EAF8", fontWeight: "bold", width: "12%", borderRight: "1px solid #020202", textAlign: "center", fontFamily: "Noto Sans KR", fontSize: "14px" }}>{t('board.notice.Writing_time')}</div>
                        <div style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "14px" }}>&nbsp;&nbsp;{this.props.created_date}&nbsp;&nbsp;&nbsp;&nbsp;({t('board.notice.Modify')} : {this.props.updated_date})</div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #000000" }}>
                        <p style={{backgroundColor: "#E4EAF8", fontWeight: "bold", width: "12%", borderRight: "1px solid #020202", textAlign: "center", fontFamily: "Noto Sans KR", fontSize: "14px", margin: "0px", lineHeight: '29px' }}>{t('board.notice.Attachments')}</p>
                        <p style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "14px", margin: "0px" }}>{this.props.files}</p>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(BoardHeaderDesktop);