import React, { Component } from 'react'
import styles from "./Kiosk.module.css";

export default class Kiosk extends Component {
    render() {
        return (
            <div className={styles.allWrap}>
                <div className={styles.kioskWrap}>
                    <div className={styles.cardAndTeamWrap}>
                        <div className={styles.cardAndNoticeWrap}>
                            <div className={styles.cardWrap}>
                                <div className={styles.card}>
                                    <div className={styles.playerName}></div>
                                </div>
                            </div>
                            <div className={styles.noticeWrap}></div>
                        </div>
                        <div>
                            <div className={styles.대회명}></div>
                            <div className={styles.리그순위텍스트}></div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        )
    }
}

