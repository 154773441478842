import React, { Component } from "react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import RegionModalForm from "./RegionModalForm.js";
import RegionModifiyModalForm from "./RegionModifiyModalForm.js";
import { Header, Segment, Button, Table, TableRow, Image, Grid } from "semantic-ui-react";
import Common from "../../../Common";

export default class RegionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionData: "",
        };
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    regionData: data,
                });
            });
    }

    render() {
        let regionList = [];
        if (this.props.selRegionData.length > 0) {
            regionList.push(
                this.props.selRegionData.map((region, index) =>
                    index === this.props.selRegionData.length - 1 ? (
                        <TableRow key={region.id}>
                            <Table.Cell textAlign="center">{region.region_text}</Table.Cell>
                            <Table.Cell textAlign="center">{region.chief_name}</Table.Cell>
                            <Table.Cell textAlign="center">{region.phone_text}</Table.Cell>
                            <Table.Cell textAlign="center">{region.email_text}</Table.Cell>
                            <Table.Cell textAlign="center">{region.reg_date.split("T")[0]}</Table.Cell>
                            <Table.Cell textAlign="center">
                                <RegionModifiyModalForm province_id={region.province.id} region_id={region.id} reloadData={this.props.reloadData} />
                            </Table.Cell>
                        </TableRow>
                    ) : (
                        <TableRow key={region.id}>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                {region.region_text}
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                {region.chief_name}
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                {region.phone_text}
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                {region.email_text}
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                {region.reg_date.split("T")[0]}
                            </Table.Cell>
                            <Table.Cell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                <RegionModifiyModalForm province_id={region.province.id} region_id={region.id} reloadData={this.props.reloadData} />
                            </Table.Cell>
                        </TableRow>
                    )
                )
            );
        }

        return (
            <div>
                <Segment attached="top" clearing secondary style={{ width: "100%", border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        지부목록
                    </Header>
                    <Header as="h3" floated="right">
                        <RegionModalForm province_id={this.state.selProvinceId} />
                    </Header>
                </Segment>
                <Segment attached style={{ display: "block", width: "100%", overflow: "auto", border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    지부명
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    지부장
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    연락처
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    e-mail
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    등록일
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center" style={{ borderBottom: "1px solid #000000" }}>
                                    수정
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>{regionList}</Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}
