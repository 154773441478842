import React, { Component } from 'react'
import TeamModal from "../modal_team_detail/TeamModal.js";
import styles from "./SquadManager.module.css";
import Common from '../Common';
import {withTranslation} from 'react-i18next';

class TeamList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          teamList: [],
          leagueData: "",
          negTeamList: [],
          teamCount: "",
          crntYear: new Date().getFullYear(),
        }
    }

    getTeamDSPoint(league_id){
        fetch(Common.backendUrl + "/api/team/win_rank/" + this.state.crntYear + "/" + league_id)
          .then(response => {
            if (response.status > 400) {
              return this.setState(() => {
                return { placeholder: "Something went wrong!" };
              });
            }
            return response.json();
          })
          .then(data=>{
              let teams = data.filter(data => data.winner__region == this.props.region_id)
              this.setState({teamList: teams});
          })
    }

    getTeamCount(){
      let data = new FormData();
      data.append('league_id', this.props.league_id);
      // fetch(Common.backendUrl + "/api/team/count/",{
      fetch(Common.backendUrl + "/api/dronesoccerclub/count/",{
        method: 'post',
        body: data,
      })
        .then(response => {
          if (response.status > 400) {
            return this.setState(() => {
              return { placeholder: "Something went wrong!" };
            });
          } else {
            return response.json();
          }
        })
        .then(data => {
            this.setState({teamCount: data});
        })
  }

    getNotJoinTeam(league_id){
      fetch(Common.backendUrl + "/api/team/not_join_rank/" + this.state.crntYear + "/" + league_id + "/" + this.props.region_id)
            .then(response => {
              if (response.status > 400) {
                return this.setState(() => {
                  return { placeholder: "Something went wrong!" };
                });
              }
              return response.json();
            })
            .then(data=>{
                this.setState({negTeamList: data});
            })
    }

    componentDidMount(){
      this.getTeamDSPoint(this.props.league_id);
      this.getNotJoinTeam(this.props.league_id);
      this.getTeamCount();
    }

    componentDidUpdate(prevProps) {
      if (this.props.region_id !== prevProps.region_id) {
        this.getTeamDSPoint(this.props.league_id);
        this.getNotJoinTeam(this.props.league_id);
      }
    }

    handleTeamModal = (e, team_id) => {
      e.preventDefault(); 
      this.setState({
          modalOpen: true,
          modalTeamId: team_id,
      })
    }

    handleClose = (e) => {
        this.setState({
            modalOpen: false,
            modalTeamId: '',
        })
    }

    handleTeamId = (e) => {
        this.setState({
            modalTeamId: '',
        })
    }

    render() {
      const { t } = this.props;
      let list = []
      let team_length = 0
      
      // if (this.state.teamList.length > 0){
      if (this.state.teamList){
        team_length = this.state.teamList.length
        
        this.state.teamList.map(
          team => (
            list.push(
            <tr key={team.winner__id} onClick={(e) => this.handleTeamModal(e, team.winner__id)}>
              <td>{team.rank}</td>
              <td>{team.winner__team_text}</td>
              <td>{team.win_rank_point}</td>
            </tr>
            )
          )
        );
      }

      // if (this.state.negTeamList.length > 0){
      if (this.state.negTeamList){
        team_length += this.state.negTeamList.length
        let ds_point = 0
        // let rank = this.state.teamCount.team_count + 1
        this.state.negTeamList.map(
          team => (
            list.push(
            <tr key={team.id} onClick={(e) => this.handleTeamModal(e, team.id)}>
              <td>-</td>
              <td>{team.team_text}</td>
              <td>{ds_point}</td>
            </tr>
            )
          )
        );
      }

    return (
      <>
        { team_length > 0 ?
          <div>
            {this.props.league_text !== undefined &&
              <h1 style={{ marginTop: "100px", textAlign: 'center' }}>{this.props.league_text.league_text}</h1>
            }
            <p style={{ textAlign: "right" }} >{t('info_squad.TeamList.Total')} {team_length}{t('info_squad.TeamList.Team')}</p>
            <table className={styles.squad_table} >
              <thead>
                <tr>
                  <th>{t('info_squad.TeamList.Rank')}</th>
                  <th>{t('info_squad.TeamList.Team_Name')}</th>
                  <th>{t('info_squad.TeamList.Team_Record')}</th>
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>
            </table>
            <TeamModal isOpen={this.state.modalOpen} league_id={this.props.league_id} teamId={this.state.modalTeamId} teamId_check={this.handleTeamId} close_method={this.handleClose} />
          </div>
          :
          <div>
            {this.props.league_text !== undefined &&
              <h1 style={{ marginTop: "100px", textAlign: 'center' }}>{this.props.league_text.league_text}</h1>
            }
            <p style={{ textAlign: "right" }} >{t('info_squad.TeamList.Total')} {team_length}{t('info_squad.TeamList.Team')}</p>
            <table className={styles.squad_table} >
              <thead>
                <tr>
                  <th>{t('info_squad.TeamList.Rank')}</th>
                  <th>{t('info_squad.TeamList.Team_Name')}</th>
                  <th>{t('info_squad.TeamList.Team_Record')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3">팀이 존재하지 않습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </>
    )
  }
}

export default withTranslation()(TeamList);