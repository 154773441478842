import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./private/PrivateRoute";
import AuthManagerRoute from "./private/AuthManagerRoute";
import AuthLeaderRoute from "./private/AuthLeaderRoute";
import IntroDS from "./intro_DS/IntroDS.js";
import IntroArena from "./intro_DS/IntroArena.js";
import IntroKDSA from "./introduction/IntroKDSA.js";
import IntroStatus from "./introduction/IntroStatus.js";
import IntroMascot from "./introduction/IntroMascot.js";
import IntroLocation from "./introduction/IntroLocation.js";
import MemberSignUp from "./signup/MemberSignUp.js";
import MemberSignUpGeneral from "./signup/MemberSignUpGeneral.js";
import MemberMyPage from "./mypage/MemberMyPage.js";
import MemberLogin from "./login/MemberLogin.js";
import FindId from "./find_id/FindId.js";
import FindPassword from "./find_password/FindPassword.js";
import SendEmail from "./find_password/SendEmail.js";
import Mainpage from "./main/Mainpage";
import SquadManager from "./info_squad/SquadManager.js";
import NationalPlayer from "./info_squad/NationalPlayer.js";
import RegionalPlayer from "./info_squad/RegionalPlayer.js";
import PrivacyPolicy from "./privacypolicy/PrivacyPolicy.js";
import CompetitionApplication from "./competition_application/CompetitionApplication.js";
import CompetitionDetailManager from "./competition_info_detail/CompetitionDetailManager.js";
import CompetitionInfo from "./competition_info/CompetitionInfo.js";

import RankPlayer from "./rank/RankPlayer.js";
import RankTeam from "./rank/RankTeam.js";
import RankYouthTeam from "./rank/RankYouthTeam.js";
import ScoreDisplay from "./competition/ScoreDisplay.js";
import ScoreInput from "./competition/ScoreInput.js";
import NoticeList from "./board/notice/List.js";
import NoticeContent from "./board/notice/Content.js";
import NoticeWriter from "./board/notice/Writer.js";
import NoticeModify from "./board/notice/Modify.js";
import NewsList from "./board/news/List.js";
import NewsContent from "./board/news/Content.js";
import NewsWriter from "./board/news/Writer.js";
import NewsModify from "./board/news/Modify.js";
import EduList from "./board/edu/List.js";
import EduContent from "./board/edu/Content.js";
import EduWriter from "./board/edu/Writer.js";
import EduModify from "./board/edu/Modify.js";
import ApplyTeamRegistration from "./team/ApplyTeamRegistration.js";
import FreeList from "./board/free/List.js";
import FreeContent from "./board/free/Content.js";
import FreeWriter from "./board/free/Writer.js";
import FreeModify from "./board/free/Modify.js";
import PressReleaseList from "./board/press_release/List.js";
import PressReleaseContent from "./board/press_release/Content.js";
import PressReleaseModify from "./board/press_release/Modify.js";
import PressReleaseWriter from "./board/press_release/Writer.js";
import CompetitionNoticeList from "./board/competition_notice/List.js";
import CompetitionNoticeContent from "./board/competition_notice/Content.js";
import CompetitionNoticeModify from "./board/competition_notice/Modify.js";
import CompetitionNoticeWriter from "./board/competition_notice/Writer.js";
import CompetitionPictureList from "./board/competition_picture/List.js";
import CompetitionPictureContent from "./board/competition_picture/Content.js";
import CompetitionPictureModify from "./board/competition_picture/Modify.js";
import CompetitionPictureWriter from "./board/competition_picture/Writer.js";
import CompetitionVideoList from "./board/competition_video/List.js";
import CompetitionVideoContent from "./board/competition_video/Content.js";
import CompetitionVideoModify from "./board/competition_video/Modify.js";
import CompetitionVideoWriter from "./board/competition_video/Writer.js";
import SquadBoardList from "./board/squad_board/List.js";
import SquadBoardContent from "./board/squad_board/Content.js";
import SquadBoardModify from "./board/squad_board/Modify.js";
import SquadBoardWriter from "./board/squad_board/Writer.js";
import InformationExchangeList from "./board/information_exchange/List.js";
import InformationExchangeContent from "./board/information_exchange/Content.js";
import InformationExchangeModify from "./board/information_exchange/Modify.js";
import InformationExchangeWriter from "./board/information_exchange/Writer.js";
import OtherNewsList from "./board/reference_room/List.js";
import OtherNewsContent from "./board/reference_room/Content.js";
import OtherNewsModify from "./board/reference_room/Modify.js";
import OtherNewsWriter from "./board/reference_room/Writer.js";
import QnaBoardList from "./board/qna_board/List.js";
import QnaBoardContent from "./board/qna_board/Content.js";
import QnaBoardModify from "./board/qna_board/Modify.js";
import QnaBoardWriter from "./board/qna_board/Writer.js";

import PrivacyPolicyInFooter from "./footer/PrivacyPolicy.js";
import TermsOfServiceInFooter from "./footer/TermsOfService.js";

import Kiosk from "./competition/Kiosk";

import AdminManager from "./admin_page/AdminManager.js";
import AdminPageRoute from "./private/AdminPageRoute.js";

import AdultSelect from "./signup/AdultSelect.js";
import IdentityVerification from "./signup/IdentityVerification.js";
import NiceSuccess from "./nice_identity/NiceSuccess.js";
import NiceFail from "./nice_identity/NiceFail.js";

import FailedToFetchPage from "./FailedToFetchPage.js";

import Payment from "./payment/Payment.js";
import Credentials from "./certificate_for_print/Credentials.js";
import CertificateOfExperience from "./certificate_for_print/CertificateOfExperience.js";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <AdminPageRoute exact path="/management" component={AdminManager} />

                <AuthManagerRoute exact path="/competitions/score/:team/:game_id/:set_choiced" component={ScoreDisplay} />
                <AuthManagerRoute exact path="/competitions/score/input/:team/:game_id/:set_choiced" component={ScoreInput} />
                <Route exact path="/signup/check/" component={AdultSelect} />
                <Route exact path="/signup/verification" component={IdentityVerification} />
                <Route exact path="/signup/info/:tempSignInfoId" component={MemberSignUp} />
                <Route exact path="/checkplus/fail/" component={NiceFail} />
                <Route exact path="/policy/privacy/" component={PrivacyPolicyInFooter} />
                <Route exact path="/teams/service/" component={TermsOfServiceInFooter} />

                <PrivateRoute exact path="/payment/:kind" component={Payment} />
                <PrivateRoute exact path="/printtest1/" component={Credentials} />
                <PrivateRoute exact path="/certificate/:number/" component={CertificateOfExperience} />

                <Route exact path="/login" component={MemberLogin} />
                <Route exact path="/findid/" component={FindId} />
                <Route exact path="/findpassword/" component={FindPassword} />
                <Route exact path="/sendemail/" component={SendEmail} />
                <PrivateRoute path="/mypage" component={MemberMyPage} />
                <Route exact path="/apply/teamregistration" component={ApplyTeamRegistration} />
                <AuthLeaderRoute exact path="/competition_aplication/:competitionId" component={CompetitionApplication} />
                <Route exact path="/info/team" component={SquadManager} />
                <Route exact path="/info/nationalplayer" component={NationalPlayer} />
                <Route exact path="/info/regionalplayer" component={RegionalPlayer} />
                <Route exact path="/info/competition/detail/:competitionYear/:competitionType/:competitionId" component={CompetitionDetailManager} />
                <Route exact path="/info/competition" component={CompetitionInfo} />
                <Route exact path="/privacypolicy/" component={PrivacyPolicy} />
                <Route exact path="/member_signup_general" component={MemberSignUpGeneral} />
                <Route exact path="/notice/list" component={NoticeList} />
                <Route exact path="/notice/content/:board_id" component={NoticeContent} />
                <AuthManagerRoute exact path="/notice/modify/:board_id" component={NoticeModify} />
                <AuthManagerRoute exact path="/notice/write" component={NoticeWriter} />
                <Route exact path="/news/list" component={NewsList} />
                <Route exact path="/news/content/:board_id" component={NewsContent} />
                <AuthManagerRoute exact path="/news/modify/:board_id" component={NewsModify} />
                <AuthManagerRoute exact path="/news/write" component={NewsWriter} />
                <Route exact path="/edu/list" component={EduList} />
                <Route exact path="/edu/content/:board_id" component={EduContent} />
                <AuthManagerRoute exact path="/edu/modify/:board_id" component={EduModify} />
                <AuthManagerRoute exact path="/edu/write" component={EduWriter} />
                <Route exact path="/free/list" component={FreeList} />
                <Route exact path="/free/content/:board_id" component={FreeContent} />
                <PrivateRoute exact path="/free/modify/:board_id" component={FreeModify} />
                <PrivateRoute exact path="/free/write" component={FreeWriter} />
                <Route exact path="/press/list" component={PressReleaseList} />
                <Route exact path="/press/content/:board_id" component={PressReleaseContent} />
                <PrivateRoute exact path="/press/modify/:board_id" component={PressReleaseModify} />
                <PrivateRoute exact path="/press/write" component={PressReleaseWriter} />
                <Route exact path="/competition_notice/list" component={CompetitionNoticeList} />
                <Route exact path="/competition_notice/content/:board_id" component={CompetitionNoticeContent} />
                <PrivateRoute exact path="/competition_notice/modify/:board_id" component={CompetitionNoticeModify} />
                <PrivateRoute exact path="/competition_notice/write" component={CompetitionNoticeWriter} />
                <Route exact path="/competition_picture/list" component={CompetitionPictureList} />
                <Route exact path="/competition_picture/content/:board_id" component={CompetitionPictureContent} />
                <PrivateRoute exact path="/competition_picture/modify/:board_id" component={CompetitionPictureModify} />
                <PrivateRoute exact path="/competition_picture/write" component={CompetitionPictureWriter} />
                <Route exact path="/competition_video/list" component={CompetitionVideoList} />
                <Route exact path="/competition_video/content/:board_id" component={CompetitionVideoContent} />
                <PrivateRoute exact path="/competition_video/modify/:board_id" component={CompetitionVideoModify} />
                <PrivateRoute exact path="/competition_video/write" component={CompetitionVideoWriter} />
                <Route exact path="/squad_board/list" component={SquadBoardList} />
                <Route exact path="/squad_board/content/:board_id" component={SquadBoardContent} />
                <PrivateRoute exact path="/squad_board/modify/:board_id" component={SquadBoardModify} />
                <PrivateRoute exact path="/squad_board/write" component={SquadBoardWriter} />
                <Route exact path="/information_exchange/list" component={InformationExchangeList} />
                <Route exact path="/information_exchange/content/:board_id" component={InformationExchangeContent} />
                <PrivateRoute exact path="/information_exchange/modify/:board_id" component={InformationExchangeModify} />
                <PrivateRoute exact path="/information_exchange/write" component={InformationExchangeWriter} />
                <Route exact path="/reference_room/list" component={OtherNewsList} />
                <Route exact path="/reference_room/content/:board_id" component={OtherNewsContent} />
                <PrivateRoute exact path="/reference_room/modify/:board_id" component={OtherNewsModify} />
                <PrivateRoute exact path="/reference_room/write" component={OtherNewsWriter} />
                <Route exact path="/qna_board/list" component={QnaBoardList} />
                <Route exact path="/qna_board/content/:board_id" component={QnaBoardContent} />
                <PrivateRoute exact path="/qna_board/modify/:board_id" component={QnaBoardModify} />
                <PrivateRoute exact path="/qna_board/write" component={QnaBoardWriter} />
                <Route exact path="/rank/team/" component={RankTeam} />
                <Route exact path="/rank/player" component={RankPlayer} />
                <Route exact path="/rank/youthteam" component={RankYouthTeam} />
                <Route exact path="/intro/dronesoccer" component={IntroDS} />
                <Route exact path="/intro/arena" component={IntroArena} />
                <Route exact path="/intro/kdsa" component={IntroKDSA} />
                <Route exact path="/intro/status" component={IntroStatus} />
                <Route exact path="/intro/mascot" component={IntroMascot} />
                <Route exact path="/intro/location" component={IntroLocation} />
                <Route exact path="/main" component={Mainpage} />
                <Route exact path="/kiosk" component={Kiosk} />
                <Route exact path="/maintain" component={FailedToFetchPage} />
                <Route exact path="/" component={Mainpage} />
            </BrowserRouter>
        );
    }
}

export default App;
