import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

import { Modal, Button, Input, Dropdown, Form, Segment, List, FormField, Checkbox } from "semantic-ui-react";

import Common from "../../../Common";
import RfidInputModal from "../../RfidInputModal.js";

class MemberUpdateModalForm extends Component {
    state = {
        provinceId: "",
        provinceData: "",
        provinceOptions: "",
        regionId: "",
        regionOptions: "",
        teamId: "",
        teamOptions: "",
        name: "",
        birth: "",
        positionId: "",
        positionOptions: "",
        kind: "",
        kindOptions: "",
        memberId: "",
        phone: "",
        email: "",
        rfidCode: "",
        authlevelOptions: "",
        authLevelId: "",
        certGrade: "",
        authLevelTime: "",
        national_check: "",
        regional_check: "",
        isAdmin: false,
        isHidden: false,
        image_file: null,
        modal_open: false,
        siteName: "",
        regionNames: [
            { key: 0, value: null, text: "지역을 선택하세요" },
            { key: 1, value: "강원도", text: "강원도" },
            { key: 2, value: "경기도", text: "경기도" },
            { key: 3, value: "경상북도", text: "경상북도" },
            { key: 4, value: "경상남도", text: "경상남도" },
            { key: 5, value: "전라북도", text: "전라북도" },
            { key: 6, value: "전라남도", text: "전라남도" },
            { key: 7, value: "충청북도", text: "충청북도" },
            { key: 8, value: "충청남도", text: "충청남도" },
            { key: 9, value: "서울특별시", text: "서울특별시" },
            { key: 10, value: "광주광역시", text: "광주광역시" },
            { key: 11, value: "대구광역시", text: "대구광역시" },
            { key: 12, value: "대전광역시", text: "대전광역시" },
            { key: 13, value: "부산광역시", text: "부산광역시" },
            { key: 14, value: "인천광역시", text: "인천광역시" },
            { key: 15, value: "울산광역시", text: "울산광역시" },
            { key: 16, value: "제주도", text: "제주도" },
            { key: 17, value: "경기북부", text: "경기북부" },
            { key: 18, value: "경기남부", text: "경기남부" },
            { key: 19, value: "경북 & 대구", text: "경북 & 대구" },
            { key: 20, value: "충청 & 대전", text: "충청 & 대전" },
            { key: 21, value: "전남 & 광주", text: "전남 & 광주" },
        ],
        isRegionalName: "",
        warningModalOpen: false,
        warningText: "",
        rfidModalOpen: false,
        selLeaderCert: "",
        selRefereeCert: "",
    };

    constructor() {
        super();
        this.toDate = this.toDate.bind(this);
    }

    getProvinceList() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ provinceData: data });
                let arr = [];
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let regions = [];
                let regionDict = {};
                for (let region of data) {
                    regions.push({ key: region.id, text: region.region_text, value: region.id });
                    regionDict[region.id] = region.region_text;
                }
                this.setState({
                    regionOptions: regions,
                    regionData: regionDict,
                });
            });
    }

    getTeamData(region_id) {
        fetch(Common.backendUrl + "/api/team/region/" + region_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.state.teamData = data;
                let arr = [];
                data.forEach((team) => arr.push({ key: team.id, text: team.team_text, value: team.id }));
                this.setState({ teamOptions: arr });
            });
    }

    getPositionData() {
        fetch(Common.backendUrl + "/api/position/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((position) => arr.push({ key: position.id, text: position.position_text, value: position.id }));
                this.setState({ positionOptions: arr });
            });
    }

    getAuthLevelData() {
        fetch(Common.backendUrl + "/api/authlevel/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((authlevel) => arr.push({ key: authlevel.id, text: authlevel.auth_text, value: authlevel.id }));
                this.setState({ authlevelOptions: arr });
            });
    }

    getKindList() {
        let arr = [];
        const kind = [
            { id: 1, kind: "일반" },
            { id: 2, kind: "유소년" },
        ];
        kind.forEach((data) => arr.push({ key: data.id, text: data.kind, value: data.id }));
        this.setState({ kindOptions: arr });
    }

    componentDidMount() {
        if (this.props.national) {
            this.setState({
                national_check: this.props.national.national,
            });
        }
        if (this.props.regional) {
            this.setState({
                isRegionalName: this.props.regional.region_name,
                regional_check: this.props.regional.regional,
            });
        }
        this.getProvinceList();
        this.getRegionData(this.props.province_id);
        this.getTeamData(this.props.region_id);
        this.getPositionData();
        this.getAuthLevelData();
        this.getKindList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.province_id !== prevProps.province_id) {
            this.getRegionData(this.props.province_id);
        }

        if (this.props.region_id !== prevProps.region_id) {
            this.getTeamData(this.props.region_id);
        }
    }

    handleProvinceChange = (e, { value }) => {
        this.setState({ provinceId: value });
        this.getRegionData(value);
    };

    handleRegionChange = (e, { value }) => {
        this.setState({ regionId: value });
        this.getTeamData(value);
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamId: value });
    };

    handleNameChange = (e, { value }) => {
        this.setState({ name: value });
    };

    handleSiteNameChange = (e, { value }) => {
        this.setState({ siteName: value });
    };

    handleBirthChange = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "00:00:00.000000";
            this.setState({
                birth: strDate,
            });
        }
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handlePositionChange = (e, { value }) => {
        this.setState({ positionId: value });
    };

    handleKindChange = (e, { value }) => {
        this.setState({ kind: value });
    };

    handleAuthLevelChange = (e, { value }) => {
        this.setState({ authLevelId: value });
    };

    handleMemberIdChange = (e, { value }) => {
        this.setState({ memberId: value });
    };

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;
        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3);
            let middleNum = checkPhone.slice(3, 7);
            let lastNum = checkPhone.slice(7, 11);
            const strPhone = firstNum + "-" + middleNum + "-" + lastNum;
            this.setState({
                phone: strPhone,
            });
        }
    };

    handleEmailChange = (e, { value }) => {
        this.setState({ email: value });
    };

    handleRfidCodeChange = (e, { value }) => {
        this.setState({ rfidCode: value });
    };

    handleAuthLevelTimeChange = (e, { value }) => {
        this.setState({ authLevelTime: value });
    };

    handleCertGradeChange = (e, { value }) => {
        this.setState({ certGrade: value });
    };

    handleNationalCheck = (e) => {
        this.setState({
            national_check: e.target.checked,
        });
        if (e.target.checked) {
            let form_national_data = new FormData();
            form_national_data.append("profile", this.props.id);
            form_national_data.append("national", true);

            this.postNational(form_national_data);
        } else {
            this.deleteNational();
        }
    };

    postNational = (form_data) => {
        fetch(Common.backendUrl + "/api/player/national/", {
            method: "post",
            body: form_data,
        }).then((res) => {
            if (res > 400) {
                this.handleWarningModalOpen("national player error❗❗");
            } else {
                this.handleWarningModalOpen("국가대표로 등록하였습니다😀");
            }
        });
    };

    deleteNational = () => {
        fetch(Common.backendUrl + "/api/player/national/delete/" + this.props.id, {
            method: "delete",
        }).then((res) => {
            if (res > 400) {
                this.handleWarningModalOpen("national player error❗❗");
            } else {
                this.handleWarningModalOpen("국가대표에서 삭제했습니다😃");
            }
        });
    };

    handleRegionalCheck = (e) => {
        this.setState({
            regional_check: e.target.checked,
        });
        if (this.state.isRegionalName) {
            if (e.target.checked) {
                let form_regional_data = new FormData();
                form_regional_data.append("profile", this.props.id);
                form_regional_data.append("regional", true);
                form_regional_data.append("region_name", this.state.isRegionalName);

                this.postRegional(form_regional_data);
            } else {
                this.deleteRegional();
            }
        } else {
            e.preventDefault();
            this.handleWarningModalOpen("지역을 먼저 선택하세요😁");
        }
    };

    handleRegionNamesChange = (e, { value }) => {
        this.setState({
            isRegionalName: value,
        });
    };

    postRegional = (form_data) => {
        fetch(Common.backendUrl + "/api/player/regional/", {
            method: "post",
            body: form_data,
        }).then((res) => {
            if (res > 400) {
                this.handleWarningModalOpen("regional player error❗❗");
            } else {
                this.handleWarningModalOpen("지역대표로 등록하였습니다😀");
            }
        });
    };

    deleteRegional = () => {
        fetch(Common.backendUrl + "/api/player/regional/delete/" + this.props.id, {
            method: "delete",
        }).then((res) => {
            if (res > 400) {
                this.handleWarningModalOpen("regional player error❗❗");
            } else {
                this.setState({ isRegionalName: "" });
                this.handleWarningModalOpen("지역대표에서 삭제했습니다😃");
            }
        });
    };

    changeLeaderOption = (e, { value }) => {
        e.preventDefault();
        this.setState({
            selLeaderCert: value,
        });
    };

    changeRefereeOption = (e, { value }) => {
        e.preventDefault();
        this.setState({
            selRefereeCert: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let member = {
            member_id: "",
            member_text: "",
            member_rfid: "",
            member_phone: "",
            member_email: "",
            birth_date: "",
            kind: "",
            member_authlevel_id: "",
            member_position_id: "",
            member_authlevel_time: "",
            cert_grade: "",
            team_id: "",
            isAdmin: false,
            isHidden: false,
            siteName: "",
        };

        if (this.state.memberId === "") {
            member.member_id = this.props.member_id;
        } else {
            if (this.state.memberId.search(/\s/) != -1) {
                this.handleWarningModalOpen("선수자격번호의 공백을 제거해주세요.");
                return;
            }
            member.member_id = this.state.memberId;
        }

        if (this.state.name === "") {
            member.member_text = this.props.member_text;
        } else {
            member.member_text = this.state.name;
        }

        if (this.state.siteName === "") {
            member.siteName = this.props.siteName;
        } else {
            member.siteName = this.state.siteName;
        }

        if (this.state.rfidCode === "") {
            member.member_rfid = this.props.member_rfid;
        } else {
            member.member_rfid = this.state.rfidCode;
        }

        if (this.state.phone === "") {
            member.member_phone = this.props.member_phone;
        } else {
            member.member_phone = this.state.phone;
        }

        if (this.state.email === "") {
            member.member_email = this.props.member_email;
        } else {
            member.member_email = this.state.email;
        }

        if (this.state.birth === "") {
            member.birth_date = this.props.birth_date;
        } else {
            member.birth_date = this.state.birth;
        }

        if (this.state.kind === "") {
            member.kind = this.props.kind;
        } else {
            member.kind = this.state.kind;
        }

        if (this.state.authLevelId === "") {
            if (this.props.authlevel_id !== null) {
                member.member_authlevel_id = this.props.authlevel_id.id;
            }
        } else {
            member.member_authlevel_id = this.state.authLevelId;
        }

        if (this.state.positionId !== "") {
            //     if (this.props.member_position !== null) {
            //         member.member_position_id = this.props.member_position.id
            //     }
            // } else {
            member.member_position_id = this.state.positionId;
        }

        if (this.state.authLevelTime === "") {
            member.member_authlevel_time = this.props.authlevel_time;
        } else {
            member.member_authlevel_time = this.state.authLevelTime;
        }

        if (this.state.certGrade === "") {
            member.cert_grade = this.props.cert_grade;
        } else {
            member.cert_grade = this.state.certGrade;
        }

        if (this.state.teamId === "") {
            member.team_id = this.props.team_id;
        } else {
            member.team_id = this.state.teamId;
        }

        if (document.getElementById("isAdmin").checked) {
            member.isAdmin = true;
        } else {
            member.isAdmin = false;
        }

        if (document.getElementById("isHidden").checked) {
            member.isHidden = true;
        } else {
            member.isHidden = false;
        }

        let form_data = new FormData();

        if (this.state.image_file !== null) {
            form_data.append("image", this.state.image_file.name);
            form_data.append("image_file", this.state.image_file, this.state.image_file.name);
        }

        if (member.member_authlevel_id !== null) {
            form_data.append("member_authlevel_id", member.member_authlevel_id);
        }

        if (member.member_position_id !== "") {
            form_data.append("member_position_id", member.member_position_id);
        }

        form_data.append("member_id", member.member_id);
        form_data.append("member_text", member.member_text);
        form_data.append("member_rfid", member.member_rfid);
        form_data.append("member_phone", member.member_phone);
        form_data.append("member_email", member.member_email);
        form_data.append("birth_date", member.birth_date);
        form_data.append("member_authlevel_time", member.member_authlevel_time);
        form_data.append("cert_grade", member.cert_grade);
        form_data.append("kind", member.kind);
        form_data.append("team_id", member.team_id);
        form_data.append("isAdmin", member.isAdmin);
        form_data.append("isHidden", member.isHidden);
        form_data.append("leader_certificate", this.state.selLeaderCert);
        form_data.append("referee_certificate", this.state.selRefereeCert);
        form_data.append("user_site_name", member.siteName);

        fetch(Common.backendUrl + "/api/profileupdate/" + this.props.id, {
            method: "put",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    if (response.status === 406) {
                        throw new Error("이미 사용중인 선수자격번호입니다.");
                    }
                    return response.json();
                } else {
                    return 200;
                }
            })
            .then((json) => {
                if (json !== undefined) {
                    if (json === 200) {
                        this.handleWarningModalOpen("수정 완료");
                        this.setState({ modal_open: false });
                        this.props.reloadPage();
                        return;
                    } else if (json.username) {
                        this.handleWarningModalOpen(json.username[0]);
                        return;
                    } else if (json.member_authlevel_id) {
                        this.handleWarningModalOpen(json.member_authlevel_id[0]);
                        return;
                    }
                    this.handleWarningModalOpen("오류가 발생했습니다. 관리자에게 문의하세요.");
                } else {
                    this.handleWarningModalOpen("개인기록 소수점을 체크해주세요");
                    this.setState({ modal_open: false });
                }
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    handlePlayerDelete = (e) => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append("isDel", true);

        fetch(Common.backendUrl + "/api/profileupdate/" + this.props.id, {
            method: "put",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                this.handleWarningModalOpen("error");
                this.setState({ modal_open: false });
            } else {
                this.handleWarningModalOpen("삭제 완료");
                this.setState({ modal_open: false });
                this.props.reloadPage();
                return response.json();
            }
        });
    };

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let day = parts[2].split("T");

            let d = day[0] + " " + monthNames[parseInt(parts[1]) - 1] + " " + parts[0];
            return new Date(d);
        } else {
            return;
        }
    }

    handleRfidInputClick = (e) => {
        this.setState({
            rfidModalOpen: true,
        });
    };

    handleRfidModalClose = () => {
        this.setState({
            rfidModalOpen: false,
            rfidCode: "",
        });
    };

    rfidCodeChange = (text) => {
        this.setState({
            rfidCode: text,
            rfidModalOpen: false,
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    downloadProfile = (e, image) => {
        e.preventDefault();
        window.open(image);
    };

    render() {
        let auth_level = null;

        if (this.props.authlevel_id === null) {
            auth_level = null;
        } else {
            auth_level = this.props.authlevel_id.id;
        }

        let parse = "";
        let phone_text = "";

        if (this.props.member_phone !== null && this.props.member_phone !== "") {
            parse = this.props.member_phone.split("-");
            phone_text = parse[0] + parse[1] + parse[2];
        }

        const leaderCertOptions = [
            { key: 0, value: 0, text: "미보유" },
            { key: 1, value: 1, text: "1급" },
            { key: 2, value: 2, text: "2급" },
            { key: 3, value: 3, text: "3급" },
        ];

        const refereeCertOptions = [
            { key: 0, value: 0, text: "미보유" },
            { key: 1, value: 1, text: "1급" },
            { key: 2, value: 2, text: "2급" },
            { key: 3, value: 3, text: "3급" },
            { key: 4, value: 4, text: "국제심판" },
        ];

        return (
            <>
                <Modal
                    size={"tiny"}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={
                        <Button primary size="tiny">
                            선수정보수정
                        </Button>
                    }
                >
                    <Modal.Header>선수정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">지회</label>
                                    <Dropdown placeholder="지회 선택" selection options={this.state.provinceOptions} name="province" defaultValue={this.props.province_id} onChange={this.handleProvinceChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">지부</label>
                                    <Dropdown placeholder="지부 선택" selection options={this.state.regionOptions} name="region_text" defaultValue={this.props.region_id} onChange={this.handleRegionChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">팀명</label>
                                    <Dropdown placeholder="팀 선택" selection options={this.state.teamOptions} name="tean_text" defaultValue={this.props.team_id} onChange={this.handleTeamChange} fluid />
                                </Form.Field>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Form.Field>
                                        <label className="label">이름</label>
                                        <Input type="text" name="name" maxLength="20" defaultValue={this.props.member_text} onChange={this.handleNameChange} required />
                                    </Form.Field>
                                    {this.props.siteName && (
                                        <Form.Field style={{ marginBottom: "14px" }}>
                                            <label className="label">아이디</label>
                                            <Input type="text" name="name" maxLength="20" defaultValue={this.props.siteName} onChange={this.handleSiteNameChange} required />
                                        </Form.Field>
                                    )}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Form.Field>
                                        <label className="label">생년월일</label>
                                        <SemanticDatepicker name="birth_date" onChange={this.handleBirthChange} value={this.toDate(this.props.birth_date)} required />
                                    </Form.Field>
                                    <Form.Field style={{ margin: "0px" }}>
                                        <Checkbox label="관리자" defaultChecked={this.props.isAdmin} name="isAdmin" id="isAdmin" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label="숨기기" defaultChecked={this.props.isHidden} name="isHidden" id="isHidden" />
                                    </Form.Field>
                                </div>
                                <Form.Field>
                                    <label className="label">포지션</label>
                                    <Dropdown placeholder="포지션 선택" selection options={this.state.positionOptions} name="position_text" defaultValue={this.props.member_position} onChange={this.handlePositionChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">일반/유소년</label>
                                    <Dropdown placeholder="일반/유소년" selection options={this.state.kindOptions} defaultValue={this.props.kind} name="kind" fluid onChange={this.handleKindChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">레벨</label>
                                    <Dropdown placeholder="레벨 선택" selection options={this.state.authlevelOptions} defaultValue={auth_level} name="authlevel" onChange={this.handleAuthLevelChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">국가대표</label>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input type="checkbox" defaultChecked={this.state.national_check} onChange={(e) => this.handleNationalCheck(e)} />
                                        <span style={{ marginLeft: "15px" }}>👈국가대표일 경우 체크하세요❗❗</span>
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">지역대표</label>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Dropdown
                                            style={{ marginBottom: "10px" }}
                                            selection
                                            options={this.state.regionNames}
                                            // name="province"
                                            defaultValue={this.state.isRegionalName}
                                            placeholder="지역을 선택하세요"
                                            onChange={this.handleRegionNamesChange}
                                            value={this.state.isRegionalName}
                                            fluid
                                        />
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <input type="checkbox" defaultChecked={this.state.regional_check} onChange={(e) => this.handleRegionalCheck(e)} /> <span style={{ marginLeft: "15px" }}>👈지역대표일 경우 체크하세요❗❗</span>
                                        </div>
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">급수</label>
                                    <Input type="number" name="cert garde" defaultValue={this.props.cert_grade} onChange={this.handleCertGradeChange} required />
                                </Form.Field>
                                {this.props.leaderCert > 0 && (
                                    <Form.Field>
                                        <label className="label">지도자 자격증</label>
                                        <Dropdown selection options={leaderCertOptions} defaultValue={this.props.leaderCert} name="leaderCert" onChange={this.changeLeaderOption} fluid />
                                    </Form.Field>
                                )}
                                {this.props.refereeCert > 0 && (
                                    <Form.Field>
                                        <label className="label">심판 자격증</label>
                                        <Dropdown selection options={refereeCertOptions} defaultValue={this.props.refereeCert} name="refereeCert" onChange={this.changeRefereeOption} fluid />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label className="label">개인 기록</label>
                                    <Input type="float" name="level time" placeholder="소수점 2자리까지 입력" defaultValue={this.props.authlevel_time} onChange={this.handleAuthLevelTimeChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">자격번호</label>
                                    <Input name="member_id" defaultValue={this.props.member_id} onChange={this.handleMemberIdChange} required />
                                </Form.Field>

                                <Form.Field>
                                    <label className="label">전화번호</label>
                                    <Input type="text" name="phone" placeholder="-을 제외한 11자리 입력" defaultValue={phone_text} onChange={this.handlePhoneChange} required />
                                </Form.Field>
                                <Form.Field id="form-input-control-error-email" control={Input} label="이메일" defaultValue={this.props.member_email} onChange={this.handleEmailChange} placeholder="drone@naver.com" />
                                <Form.Field>
                                    <label className="label">RFID</label>
                                    {this.state.rfidCode === "" ? <Input onClick={(e) => this.handleRfidInputClick(e)} type="text" name="rfid_code" value={this.props.member_rfid} required /> : <Input onClick={(e) => this.handleRfidInputClick(e)} type="text" name="rfid_code" value={this.state.rfidCode} required />}
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">프로필 사진 (300x300 ~ 500x500)</label>
                                    <Input type="file" name="profile" onChange={this.handleImageChange} accept="image/png, image/jpeg" />
                                    {this.props.image !== null && (
                                        <div style={{ marginTop: "7px", cursor: "pointer" }} onClick={(e) => this.downloadProfile(e, this.props.image_file)}>
                                            {/* // <div style={{ marginTop: '7px' }}> */}
                                            <Segment>
                                                <List.Item>
                                                    <List.Content>기존 프로필: {this.props.image}</List.Content>
                                                </List.Item>
                                            </Segment>
                                            {/* <a href={this.props.image_file} download={this.props.image}>Download</a> */}
                                        </div>
                                    )}
                                    {this.props.image === null && (
                                        <div style={{ marginTop: "7px" }}>
                                            <Segment>
                                                <List.Item>프로필 사진 없음</List.Item>
                                            </Segment>
                                        </div>
                                    )}
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    수정하기
                                </Button>
                                <Button negative className="button is-info" onClick={(e) => this.handlePlayerDelete(e)}>
                                    삭제
                                </Button>
                                <Button color="black" onClick={() => this.setState({ rfidCode: "", modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <RfidInputModal isOpen={this.state.rfidModalOpen} changeRfid={this.rfidCodeChange} closeMethod={this.handleRfidModalClose} />
            </>
        );
    }
}

export default MemberUpdateModalForm;
