import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Input } from 'semantic-ui-react'
import Common from '../../../Common.js';

const daum = window.daum;

class AddArenaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editName: "",
            editAddress: "",
            editLatLng: "",
            editPhoneText: "",
            editImg: "",
        }
    }


    
    handleSubmit = (e) => {
        e.preventDefault();
    
        if (this.state.editName.length === 0) {
            alert("경기장 이름을 입력해주세요")
        } else if (this.state.editPhoneText.length === 0) {
            alert("연락처를 입력해주세요.")
        } else if (this.state.editImg.length === 0) {
            alert("사진을 선택해주세요.")
        } else if (this.state.editAddress.length === 0) {
            alert("주소를 입력해주세요")
        } else if (this.state.editLatLng.length === 0) {
            alert("주소확인을 해주세요")
        }

        let formData = new FormData();
        formData.append("name", this.state.editName)
        formData.append("address", this.state.editAddress);
        formData.append("address_latLng", this.state.editLatLng);
        formData.append("phone_text", this.state.editPhoneText);
        formData.append("stadium_img", this.state.editImg);
        const conf = {
            method: "post",
            body: formData
        };
        fetch(Common.backendUrl + `/api/arena/list/`, conf)
            .then(response => {
                if (response.status > 400) {
                    alert("error")
                    return;
                } else if (response.status === 201) {
                    this.props.closeMethod();
                    return;
                }
            });

        
    }

    handleAddressConvert = (e) => {
        e.preventDefault();

        let naverMap = window.naver;
        let mapOptions = {
            zoom: 15
        }
        let map = new naverMap.maps.Map(document.getElementById('map'), mapOptions)

        let latLng = ''
        e.preventDefault();
        
        naverMap.maps.Service.geocode({
            address: this.state.editAddress
        }, (status, res) => {
            if (status !== naverMap.maps.Service.Status.OK){
                return alert('Something wrong!');
            }

            try {
                latLng = res.result.items[0].point.y + ", " + res.result.items[0].point.x;

                this.setState({
                    editLatLng: latLng,
                })
                // console.log(latLng)
    
                let lat = parseFloat(res.result.items[0].point.y)
                let lng = parseFloat(res.result.items[0].point.x)
                let tempLatLng = new naverMap.maps.LatLng(lat, lng)
    
                let marker = new naverMap.maps.Marker({
                    position: tempLatLng,
                    map: map
                });
                map.setCenter(tempLatLng)
              }
              catch(err) {
                alert('찾을 수 없는 주소입니다.');
              }
        })
    }

    searchAddr(objStr) {
        new daum.Postcode({
            oncomplete: function (data) {
                var addr = ''; // 주소 변수
                var extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else { // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                if (data.userSelectedType === 'R') {
                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if (extraAddr !== '') {
                        extraAddr = ' (' + extraAddr + ')';
                    }
                    // 조합된 참고항목을 해당 필드에 넣는다.
                } else {

                }
                document.getElementById(objStr).value = addr + " " + extraAddr;
                this.setState({ editAddress: addr + " " + extraAddr })
                //주소 정보를 해당 필드에 넣는다.
            }.bind(this)
        }).open();
    }


    render() {
        return (
            <Modal
            open={this.props.isOpen}
            size="tiny"
        >
                <Header>경기장 생성하기</Header>


                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{margin: "0px"}}>경기장 이름</p>
                    <Input type="text" onChange={(e) =>this.setState({editName: e.target.value})}></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{ margin: "0px" }}>경기장 연락처</p>
                    <Input type="text" onChange={(e) =>this.setState({editPhoneText: e.target.value})} placeholder="010-0000-0000" ></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ margin: "0px" }}>경기장 사진</p>
                        <Input type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({editImg: e.target.files[0]})} alt="이미지 선택" />
                    </div>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ margin: "0px" }}>경기장 주소</p>
                        <div>
                            <Input 
                                type="text" 
                                id="address"
                                onClick={function (e) { this.searchAddr('address') }.bind(this)}
                                onChange={(e, {value}) => this.setState({editAddress: value})} 
                                style={{marginRight: "5px"}} />
                            <Button color="blue" inverted size="tiny" onClick={(e) => this.handleAddressConvert(e)}>주소 확인</Button>
                        </div>
                    </div>
                    <div id="map" style={{ width: "200px", height: "200px", marginTop: "10px" }}></div>
                </Modal.Content>



                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    <Button color='green' inverted onClick={(e) => this.handleSubmit(e)}>
                        <Icon name='check' />적용
                    </Button>
                    <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                        <Icon name='remove' />닫기
                    </Button>
                </Modal.Actions>

        </Modal>
        );
    }
}

export default AddArenaModal;