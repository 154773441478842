import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import TeamModal from "../modal_team_detail/TeamModal.js";
import styles from "./RankTeam.module.css";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class TeamWinCountRank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamList: [],
            modalOpen: false,
            modalTeamId: "",
            negTeamList: [],
        };
    }

    getTeamWinCount(league_id) {
        fetch(Common.backendUrl + "/api/team/win_count/" + league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ teamList: data });
            });
    }

    getNotJoinTeam(league_id) {
        fetch(Common.backendUrl + "/api/team/not_join/" + league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                this.setState({ negTeamList: data });
            });
    }

    componentDidMount() {
        this.getTeamWinCount(this.props.league);
        this.getNotJoinTeam(this.props.league);
    }

    componentDidUpdate(prevProps) {
        if (this.props.league !== prevProps.league) {
            this.getTeamWinCount(this.props.league);
            this.getNotJoinTeam(this.props.league);
        }
    }

    handleTeamModal = (e, team_id) => {
        e.preventDefault();
        this.setState({
            modalOpen: true,
            modalTeamId: team_id,
        });
    };

    handleClose = (e) => {
        this.setState({
            modalOpen: false,
            modalTeamId: "",
        });
    };

    handleTeamId = (e) => {
        this.setState({
            modalTeamId: "",
        });
    };

    render() {
        let list = [];
        const team = this.state.teamList;
        const { t } = this.props;

        if (this.state.teamList.length > 0) {
            for (let i = 0; i < team.length; i++) {
                let teamLog = "https://kdsa-react-s3.s3.amazonaws.com/" + team[i]["winner__team_image_file"];
                if (team[i].rank == 1) {
                    list.push(
                        <tr className={styles.tableRow} onClick={(e) => this.handleTeamModal(e, team[i].winner__id)}>
                            <td className={styles.trophyRow}>
                                <img className={styles.trophy} src="/images/rank/trophy.png"></img>
                            </td>
                            <td className={styles.rank}>{team[i].rank}</td>
                            {team[i].winner__pre_win_rank - team[i].rank > 0 && team[i].winner__pre_win_rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankUp} src="/images/rank/rank_up.png"></img>
                                    {team[i].winner__pre_win_rank - team[i].rank}
                                </td>
                            )}
                            {team[i].winner__pre_win_rank - team[i].rank === 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                </td>
                            )}
                            {team[i].winner__pre_win_rank === 0 && team[i].rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankSame} src="/images/rank/rank_up.png"></img>
                                    {team.length + 1 - team[i].rank}
                                </td>
                            )}
                            {team[i].winner__pre_win_rank - team[i].rank < 0 && team[i].winner__pre_win_rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankDown} src="/images/rank/rank_down.png"></img>
                                    {team[i].rank - team[i].winner__pre_win_rank}
                                </td>
                            )}
                            {team[i]["winner__team_image_file"] !== "" && (
                                <td lassName={styles.teamLogRow}>
                                    <img className={styles.teamLog} src={teamLog}></img>
                                </td>
                            )}
                            {team[i]["winner__team_image_file"] === "" && (
                                <td className={styles.teamLogRow}>
                                    <img className={styles.teamLog} src="/images/default_teamlogo_02.png"></img>
                                </td>
                            )}
                            <td className={styles.teamText}>{team[i].winner__team_text}</td>
                            <td className={styles.record}>
                                <div className={styles.rankRecord}>
                                    <spna>{team[i].win_count}</spna>
                                </div>
                            </td>
                        </tr>
                    );
                } else {
                    list.push(
                        <tr className={styles.tableRow} onClick={(e) => this.handleTeamModal(e, team[i].winner__id)}>
                            <td className={styles.trophyRow}></td>
                            <td className={styles.rank}>{team[i].rank}</td>
                            {team[i].winner__pre_win_rank - team[i].rank > 0 && team[i].winner__pre_win_rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankUp} src="/images/rank/rank_up.png"></img>
                                    {team[i].winner__pre_win_rank - team[i].rank}
                                </td>
                            )}
                            {team[i].winner__pre_win_rank - team[i].rank === 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                </td>
                            )}
                            {team[i].winner__pre_win_rank === 0 && team[i].rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankSame} src="/images/rank/rank_up.png"></img>
                                    {team.length + 1 - team[i].rank}
                                </td>
                            )}
                            {team[i].winner__pre_win_rank - team[i].rank < 0 && team[i].winner__pre_win_rank !== 0 && (
                                <td className={styles.rankImgRow}>
                                    <img className={styles.rankDown} src="/images/rank/rank_down.png"></img>
                                    {team[i].rank - team[i].winner__pre_win_rank}
                                </td>
                            )}
                            {team[i]["winner__team_image_file"] !== "" && (
                                <td lassName={styles.teamLogRow}>
                                    <img className={styles.teamLog} src={teamLog}></img>
                                </td>
                            )}
                            {team[i]["winner__team_image_file"] === "" && (
                                <td className={styles.teamLogRow}>
                                    <img className={styles.teamLog} src="/images/default_teamlogo_02.png"></img>
                                </td>
                            )}
                            <td className={styles.teamText}>{team[i].winner__team_text}</td>
                            <td className={styles.record}>
                                <div className={styles.rankRecord}>
                                    <spna>{team[i].win_count}</spna>
                                </div>
                            </td>
                        </tr>
                    );
                }
            }

            let neg_team_rank = team.length + 1;
            let win_count = 0;

            if (this.state.negTeamList.length > 0) {
                for (let i = 0; i < this.state.negTeamList.length; i++) {
                    let teamLog = this.state.negTeamList[i]["team_image_file"];

                    list.push(
                        <tr className={styles.tableRow} onClick={(e) => this.handleTeamModal(e, this.state.negTeamList[i].id)}>
                            {neg_team_rank === 1 && (
                                <>
                                    <td className={styles.trophyRow}>
                                        <img className={styles.trophy} src="/images/rank/trophy.png"></img>
                                    </td>
                                    <td className={styles.rank}>{neg_team_rank}</td>
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                    {this.state.negTeamList[i]["team_image_file"] !== null && (
                                        <td className={styles.teamLogRow}>
                                            <img className={styles.teamLog} src={teamLog}></img>
                                        </td>
                                    )}
                                    {this.state.negTeamList[i]["team_image_file"] === null && (
                                        <td className={styles.teamLogRow}>
                                            <img className={styles.teamLog} src="/images/default_teamlogo_02.png"></img>
                                        </td>
                                    )}
                                    <td className={styles.teamText}>{this.state.negTeamList[i].team_text}</td>
                                    <td className={styles.record}>
                                        <div className={styles.rankRecord}>
                                            <spna>{win_count}</spna>
                                        </div>
                                    </td>
                                </>
                            )}
                            {neg_team_rank >= 2 && (
                                <>
                                    <td className={styles.trophyRow}></td>
                                    <td className={styles.rank}>{neg_team_rank}</td>
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                    {this.state.negTeamList[i]["team_image_file"] !== null && (
                                        <td className={styles.teamLogRow}>
                                            <img className={styles.teamLog} src={teamLog}></img>
                                        </td>
                                    )}
                                    {this.state.negTeamList[i]["team_image_file"] === null && (
                                        <td className={styles.teamLogRow}>
                                            <img className={styles.teamLog} src="/images/default_teamlogo_02.png"></img>
                                        </td>
                                    )}
                                    <td className={styles.teamText}>{this.state.negTeamList[i].team_text}</td>
                                    <td className={styles.record}>
                                        <div className={styles.rankRecord}>
                                            <spna>{win_count}</spna>
                                        </div>
                                    </td>
                                </>
                            )}
                        </tr>
                    );
                }
            }
        }
        return (
            <div className={styles.table_wrap}>
                <div style={{ marginBottom: "20px" }}></div>
                <table className={styles.rank_table}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("rank.RankTeam.Ranking")}</th>
                            <th>{t("rank.RankTeam.Rank_Change")}</th>
                            <th>{t("rank.RankTeam.Team_Logo")}</th>
                            <th>{t("rank.RankTeam.Team_Name")}</th>
                            <th>{t("rank.RankTeam.Many_Wins")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="6">
                                <div className={styles.bodyTable}>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        {list}
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <TeamModal isOpen={this.state.modalOpen} league_id={this.props.league} teamId={this.state.modalTeamId} teamId_check={this.handleTeamId} close_method={this.handleClose} />
            </div>
        );
    }
}

export default withTranslation()(TeamWinCountRank);
