import React, { Component } from 'react'
import styles from "./AuthLevelTab.module.css";
import { withTranslation } from 'react-i18next';

class RankTypeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levelTypeList: [],
      level_type_id: 3,
    }
  }

  geLevelList() {
    const { t } = this.props;

    let arr = [];
    const level_type = [{ id: 3, level_type: t('rank.RankPlayer.Pro') }, { id: 2, level_type: t('rank.RankPlayer.Amateur') }, { id: 1, level_type: t('rank.RankPlayer.Rookie') }]
    level_type.forEach(data => arr.push({ id: data.id, level_type_text: data.level_type, value: data.id }))
    this.setState({ levelTypeList: arr });
  }

  componentDidMount() {
    this.geLevelList();
  }

  onClickHandler(level_type) {
    this.setState({ level_type_id: level_type });
    this.props.onSellevelType(level_type);
  }

  render() {
    let itemDiv = [];
    for (let i = 0; i < this.state.levelTypeList.length; i++) {
      if (parseInt(this.state.level_type_id) === parseInt(this.state.levelTypeList[i].id)) {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.levelTypeList[i].id)} className={styles.item_div} key={this.state.levelTypeList[i].id} style={{ backgroundColor: "#5814E1", color: "white", fontWeight: "bold" }}>
          <div>{this.state.levelTypeList[i].level_type_text}</div>
        </div>);
      } else {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.levelTypeList[i].id)} className={styles.item_div} key={this.state.levelTypeList[i].id} style={{ fontWeight: "bold" }}>
          {this.state.levelTypeList[i].level_type_text}
        </div>);
      }
    }

    return (
      <div style={{ display: "flex" }}>
        {itemDiv}
      </div>
    )
  }
}
export default withTranslation()(RankTypeTab);