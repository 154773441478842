import React, { Component } from 'react';
// import styles from "./TeamModal.module.css";
import styles from "./PlayerInfoModal.module.css";
import {
    Modal,
} from "semantic-ui-react"

class PlayerInfoModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal
                open={this.props.isOpen}
                size="mini"
                style={{
                    background: "radial-gradient(circle, rgba(68,69,154,1) 0%, rgba(11,26,42,1) 66%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px"
                }}
            >
                {this.props.playerInfo ?
                    <div className={styles.content_div}>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                            <div className={styles.member_profile}>
                                {this.props.playerInfo.image_file ?
                                    <img src={this.props.playerInfo.image_file} alt="member profile image" />
                                    :
                                    <img src="/images/default_profile.png" style={{ backgroundColor: "#020202" }} alt="member profile image" />
                                }
                                <h1 style={{ color: "white", textAlign: "center", marginBottom: "10px"}}>{this.props.playerInfo.member_text}</h1>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "40%"}}>
                                <div style={{display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px"}}>포지션:</p>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px", textAlign: "left"}}>{this.props.playerInfo.member_position.position_text}</p>
                                </div>
                                <div style={{display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px"}}>레벨:</p>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px", textAlign: "left"}}>{this.props.playerInfo.member_authlevel === 1 ? "루키" : this.props.playerInfo.member_authlevel === 2 ? "아마추어" : "프로"}</p>
                                </div>
                                <div style={{display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px"}}>급수:</p>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px",  textAlign: "left"}}>{this.props.playerInfo.cert_grade} </p>
                                </div>
                                <div style={{display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px"}}>개인기록:</p>
                                    <p style={{margin: 0, fontSize: "Noto Sans KR", color: "#FFFFFF", fontSize: "16px",  textAlign: "left"}}>{this.props.playerInfo.member_authlevel_time} </p>
                                </div>

                            </div>


                        </div>
                        <a className={styles.style_a} onClick={this.props.closeMethod}>
                            닫기
                        </a>
                    </div>
                    :
                    <></>
                }
            </Modal>
        );
    }
}

export default PlayerInfoModal;