import React, { Component } from "react"
import styles from "./Introds.module.css";
import Topmenu from "../Topmenu/Topmenu"
import Footer from "../footer/Footer"
import RULEBOOK_KOREAN from "./rule_books/RULEBOOK_KOREAN.pdf"
import RULEBOOK_CHINESE from "./rule_books/RULEBOOK_CHINESE.pdf"
import RULEBOOK_ENGLISH from "./rule_books/RULEBOOK_ENGLISH.pdf"
import RULEBOOK_JAPANESE from "./rule_books/RULEBOOK_JAPANESE.pdf"
import { withTranslation } from "react-i18next";

class IntroDS extends Component {
    render() {
        window.scrollTo(0, 0);
        const {t} = this.props;

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100vw"}}>
                <Topmenu />
                <div className={styles.wrap_div}>
                    <div className={styles.title_div} style={{margin: "0px"}}>
                        <h1>{t('Intro_DS.IntroDS.Title')}</h1>
                        <hr />
                    </div>
                    <div className={styles.intro_content_text} dangerouslySetInnerHTML={{ __html: t('Intro_DS.IntroDS.Content') }}></div>

                    <div className={styles.title_div}>
                        <h1>{t('Intro_DS.IntroDSBall.Title')}</h1>
                        <hr />
                    </div>

                    <div className={styles.intro_droneball_div}>
                        <img className={styles.intro_third_forth_img} src="/images/intro_DS/droneBall.png" alt="droneBall"></img>

                        <p className={styles.intro_title_sub_text1}>{t('Intro_DS.IntroDSBall.Arrow.First')}</p>
                        <img className={`${styles.droneBall_arrow} ${styles.droneBall_arrow1}`} src="/images/intro_DS/arrow_1.png" alt="arrow" />
                        <img className={`${styles.droneBall_arrow} ${styles.droneBall_arrow2}`} src="/images/intro_DS/arrow_2.png" alt="arrow" />
                        <p className={styles.intro_title_sub_text2}>{t('Intro_DS.IntroDSBall.Arrow.Second1')}<br/>{t('Intro_DS.IntroDSBall.Arrow.Second2')}</p>
                        <img className={`${styles.droneBall_arrow} ${styles.droneBall_arrow3}`} src="/images/intro_DS/arrow_3.png" alt="arrow" />
                        <p className={styles.intro_title_sub_text3}>{t('Intro_DS.IntroDSBall.Arrow.Third1')}<br/>{t('Intro_DS.IntroDSBall.Arrow.Third2')}</p>
                        <img className={`${styles.droneBall_arrow} ${styles.droneBall_arrow4}`} src="/images/intro_DS/arrow_4.png" alt="arrow" />
                        <p className={styles.intro_title_sub_text4}>{t('Intro_DS.IntroDSBall.Arrow.Forth1')}<br/>{t('Intro_DS.IntroDSBall.Arrow.Forth2')}</p>
                    </div>


                    <div className={styles.title_div_stadium}>
                        <h1>{t('Intro_DS.IntroDSRules.Main_Title')}</h1>
                        <hr />
                    </div>

                    <div className={styles.explanation_div} style={{ width: "500px", marginTop: "70px" }}>
                        <p>{t('Intro_DS.IntroDSRules.First_Content.Rule_1')}</p>
                    </div>

                    <div className={styles.rule_image_div1}>
                        <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_1.png"  alt="rule image" />
                        {/* <p>{t('Intro_DS.IntroDSRules.First_Content.Rule_1_ImgText')}</p> */}
                        {/* <p>{t('Intro_DS.IntroDSRules.First_Content.Rule_1_ImgText')}</p> */}
                    </div>

                    <div className={styles.explanation_div} style={{ width: "500px", marginTop: "70px" }}>
                        <div dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.GoalFrame')}}></div>
                    </div>

                    <div className={styles.rule_image_div1}>
                        <img className={styles.rule_image} src="/images/intro_DS/rule_image/rule_droneball2.png"  alt="rule droneball" />
                    </div>

                    <div className={styles.title_div}  style={{marginBottom: "70px"}}>
                        <h1>{t('Intro_DS.IntroDSRules.SubTitle_2')}</h1>
                        <hr />
                    </div>

                    <div className={styles.explanation_div} style={{ width: "750px"}}>
                        <div dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.First_Content.Rule_2')}}></div>
                        {/* <p>{t('Intro_DS.IntroDSRules.First_Content.Rule_2')}</p> */}
                    </div>

                    <div className={styles.rule_image_div2}>
                        <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_2.png" style={{ marginBottom: "33px" }} alt="rule image" />
                    </div>

                    <div className={styles.explanation_div} style={{ width: "700px", marginBottom: "10px" }}>
                        <p>{t('Intro_DS.IntroDSRules.Third_Content.Rule_1')}</p>
                    </div>

                    <div className={styles.explanation_div} style={{ width: "700px" }}>
                        <div dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.Third_Content.Rule_2')}}></div>
                    </div>

                    <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_3.png" alt="rule image" />
                    <img src="/images/intro_DS/rule_image/arrow_down.png" style={{ width: "100px", margin: "30px 0px" }} alt="rule image" />


                    <div className={styles.explanation_div} style={{ width: "700px" }}>
                        <div dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.Third_Content.Rule_3')}}></div>
                    </div>

                    <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_4.png" alt="rule image" />

                    <img src="/images/intro_DS/rule_image/arrow_down.png" style={{ width: "100px", margin: "30px 0px" }} alt="rule image" />
                    <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_5.png" alt="rule image" />

                    <img src="/images/intro_DS/rule_image/arrow_down.png" style={{ width: "100px", margin: "30px 0px" }} alt="rule image" />
                    <img className={styles.rule_image} src="/images/intro_DS/rule_image/rules_6.png" alt="rule image" />



                    <div className={styles.little_title_div}>
                        <p>{t('Intro_DS.IntroDSRules.SubTitle_1')}</p>
                        <hr />
                    </div>

                    <div className={styles.circle_rules}>
                        <div>
                            <img className={styles.circle_rule_image} src="/images/intro_DS/rule_image/rules_circle.png" alt="rule image" />
                            <div>
                                <div>
                                    <h1>3</h1>
                                    <p>Minutes</p>
                                    <hr />
                                </div>
                                <div style={{ width: "100%"}} dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.Second_Content.Rule_1')}}></div>
                            </div>
                        </div>
                        <div>
                            <img className={styles.circle_rule_image} src="/images/intro_DS/rule_image/rules_circle.png" alt="rule image" />
                            <div>
                                <div>
                                    <h1>3</h1>
                                    <p>Set</p>
                                    <hr />
                                </div>
                                <div style={{ width: "100%"}} dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.Second_Content.Rule_2')}}></div>
                            </div>
                        </div>
                        <div>
                            <img className={styles.circle_rule_image} src="/images/intro_DS/rule_image/rules_circle.png" alt="rule image" />
                            <div>
                                <div>
                                    <h1>5</h1>
                                    <p>Break Time</p>
                                    <hr />
                                </div>
                                <div style={{ width: "100%"}} dangerouslySetInnerHTML={{__html: t('Intro_DS.IntroDSRules.Second_Content.Rule_3')}}></div>
                            </div>
                        </div>
                    </div>

                    <h1 className={styles.download_rulebook}>{t('Intro_DS.IntroDSRules.SubTitle_4')}</h1>
                    <div className={styles.download_div}>
                        <a href={RULEBOOK_KOREAN} download>
                            <p>KOREAN</p>
                            <img src="/images/intro_DS/rule_image/bx_bxs-download.png" alt="download button" />
                        </a>
                        <a href={RULEBOOK_ENGLISH} download>
                            <p>ENGLISH</p>
                            <img src="/images/intro_DS/rule_image/bx_bxs-download.png" alt="download button" />
                        </a>
                        <a href={RULEBOOK_CHINESE} download>
                            <p>CHINESE</p>
                            <img src="/images/intro_DS/rule_image/bx_bxs-download.png" alt="download button" />
                        </a>
                        <a href={RULEBOOK_JAPANESE} download>
                            <p>JAPANESE</p>
                            <img src="/images/intro_DS/rule_image/bx_bxs-download.png" alt="download button" />
                        </a>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(IntroDS)