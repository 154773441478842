import React, { Component } from 'react';
import {
    Header,
    Table,
    Pagination,
    Segment
} from 'semantic-ui-react'
import ModalApply from "./ModalApply.js";
import styles from './ChangeManager.module.css';
import Common from '../../../Common.js'
import { Button } from 'react-scroll';


export default class ChangeManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyModalOpen: false,
            selectedPage: 1,
            totalPages: 1,
            teamRequests: "",
            userProfile: "",
            requestModalOpen: false,
            requestContent: "",
            requestsCnt: 0,
        }
    }

    componentDidMount = () => {
        this.getTeamInfomation();
        this.getTeamRequests(this.state.selectedPage);
    }
    async getTeamInfomation() {
        if (sessionStorage.getItem('token')) {
            await fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    'Authorization': `JWT ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        userProfile: json.profile
                    });

                });
        }
    }


    getTeamRequests = (page) => {
        fetch(Common.backendUrl + `/api/team/requests/${sessionStorage.id}?page=${page}`)
            .then(res => {
                if (res.status >= 400) {
                    this.handleWarningModalOpen("error");
                    return;
                }
                return res.json()
            })
            .then(data => {

                if (data["count"] % 5 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 5) + 1
                    })
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 5)
                    })
                }

                this.setState({
                    teamRequests: data.results,
                    requestsCnt: data.count
                })
            })
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page
        })
        this.getTeamRequests(page)
    }


    teamRequestsTableClick = (e, request) => {
        e.preventDefault();
        // this.handleRequestModalOpen(request)
        let requestContent = new Array();
        requestContent.push(request)
        this.setState({
            requestModalOpen: true,
            requestContent,
        })
    }


    
    handleApplyModalOpen = () => {
        this.setState({
            applyModalOpen: true,
        })
    }

    handleApplyModalClose = () => {
        this.setState({
            applyModalOpen: false,
        })
    }
    
    render() {
        const { teamRequests, requestsCnt } = this.state;
        return (
            <Segment style={{border: "1px solid #000000"}}>
                <Segment clearing secondary attached='top' style={{border: "1px solid #000000"}}>
                    <Segment style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: '100%', border: "1px solid #000000" }}>
                        <Header as='h3' style={{ margin: '0px' }}>
                            선수상태 및 선수단 변경 요청
                    </Header>
                        <a className={`${styles.style_a1} ${styles.mouse_hover} `} style={{ marginLeft: '20px' }} onClick={() => this.handleApplyModalOpen()} >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            신청하기
                        </a>
                    </Segment>
                </Segment>
                <Segment attached style={{display: 'flex', flexDirection: "column", alignItems: "center", border: "1px solid #000000", borderTop: "0"}}>
                    <Table striped unstackable style={{border: "1px solid #000000"}}>
                        <Table.Header style={{ width: "100%", tableLayout: "fixed" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>no</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청일자</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>분류</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='center'>요청사항</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>처리상태</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body className={styles.teamRequests_table} >
                            {teamRequests && requestsCnt != 0 ?
                                teamRequests.map((request, index) =>
                                    index === teamRequests.length - 1 ?
                                        <Table.Row key={request.id} style={{ width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell width="1" textAlign='center'>{request.id}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{request.create_at.split("T")[0]}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'> {request.select_menu} </Table.Cell>
                                            {request.content.length < 22 ?
                                                <Table.Cell width="2" textAlign='left'>{request.content}</Table.Cell>
                                                :
                                                <Table.Cell width="2" textAlign='left'>{request.content.slice(0, 20)}...</Table.Cell>
                                            }
                                            <Table.Cell width="1" textAlign='center'>{request.request_state}</Table.Cell>
                                        </Table.Row>
                                        :
                                        <Table.Row key={request.id} style={{ width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.id}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.create_at.split("T")[0]}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'> {request.select_menu} </Table.Cell>
                                            {request.content.length < 22 ?
                                                <Table.Cell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='left'>{request.content}</Table.Cell>
                                                :
                                                <Table.Cell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='left'>{request.content.slice(0, 20)}...</Table.Cell>
                                            }
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.request_state}</Table.Cell>
                                        </Table.Row>
                                )
                                :
                                <Table.Row>
                                    <Table.Cell textAlign='center' colSpan="5" style={{ fontSize: "15px" }}>이적 신청 내역이 없습니다.</Table.Cell>
                                </Table.Row>

                            }
                        </Table.Body>
                    </Table>
                    <Pagination
                        style={{ marginTop: "10px" }}
                        boundaryRange={1}
                        defaultActivePage={1}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={2}
                        totalPages={this.state.totalPages}
                        onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                    />
                </Segment>
                <ModalApply isOpen={this.state.applyModalOpen} profile={this.state.userProfile} closeMethod={this.handleApplyModalClose} />
            </Segment>
        );
    }
}

