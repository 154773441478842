import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Topmenu from "../Topmenu/Topmenu"
import Footer from "../footer/Footer"

class PrivacyPolicy extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Topmenu />
                <div style={{ minHeight: "100vh", height: "100%", padding: "130px 17px", backgroundColor: "#F9F9F9", display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: "17px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", marginBottom: "11px" }}>대한드론축구협회 개인정보처리방침</p>
                    <hr style={{width: "100%", border: 0, borderTop: "1px solid #4d4d4d", marginBottom: "40px"}} />

                    <a href="#rule1" style={{fontWeight: "bold", fontSize: "15px", lineHeight: "35px", verticalAlign: "top", color: "#010101"}} >{t('PrivacyPolicy.rule1')}</a>
                    <a href="#rule2" style={{fontWeight: "bold", fontSize: "15px", lineHeight: "35px", verticalAlign: "top", color: "#010101"}} >{t('PrivacyPolicy.rule2')}</a>
                    <a href="#rule3" style={{fontWeight: "bold", fontSize: "15px", lineHeight: "35px", verticalAlign: "top", color: "#010101"}} >{t('PrivacyPolicy.rule3')}</a>
                    <a href="#rule4" style={{fontWeight: "bold", fontSize: "15px", lineHeight: "35px", verticalAlign: "top", color: "#010101"}} >{t('PrivacyPolicy.rule4')}</a>
                    <hr style={{width: "100%", border: 0, borderTop: "2px solid #4d4d4d", marginTop: "40px", marginBottom: "40px"}} />

                    <p id="rule1" style={{fontSize: "17px", lineHeight: "28px", color: "#010101", fontWeight: "bold"}}>{t('PrivacyPolicy.rule1')}</p>
                    <div style={{lineHeight: "25px", letterSpacing: "-.02em", fontSize: "15px", color: "#666"}} dangerouslySetInnerHTML={{__html: t('PrivacyPolicy.detail1')}}></div>
                    <hr style={{width: "100%", border: 0, borderTop: "1px solid #e6e6e6", marginTop: "40px", marginBottom: "40px"}} />

                    <p id="rule2" style={{fontSize: "17px", lineHeight: "28px", color: "#010101", fontWeight: "bold"}}>{t('PrivacyPolicy.rule2')}</p>
                    <div style={{lineHeight: "25px", letterSpacing: "-.02em", fontSize: "15px", color: "#666"}} dangerouslySetInnerHTML={{__html: t('PrivacyPolicy.detail2')}}></div>
                    <hr style={{width: "100%", border: 0, borderTop: "1px solid #e6e6e6", marginTop: "40px", marginBottom: "40px"}} />

                    <p id="rule3" style={{fontSize: "17px", lineHeight: "28px", color: "#010101", fontWeight: "bold"}}>{t('PrivacyPolicy.rule3')}</p>
                    <div style={{lineHeight: "25px", letterSpacing: "-.02em", fontSize: "15px", color: "#666"}} dangerouslySetInnerHTML={{__html: t('PrivacyPolicy.detail3')}}></div>
                    <hr style={{width: "100%", border: 0, borderTop: "1px solid #e6e6e6", marginTop: "40px", marginBottom: "40px"}} />

                    <p id="rule4" style={{fontSize: "17px", lineHeight: "28px", color: "#010101", fontWeight: "bold"}}>{t('PrivacyPolicy.rule4')}</p>
                    <div style={{lineHeight: "25px", letterSpacing: "-.02em", fontSize: "15px", color: "#666"}} dangerouslySetInnerHTML={{__html: t('PrivacyPolicy.detail4')}}></div>
                    <hr style={{width: "100%", border: 0, borderTop: "1px solid #e6e6e6", marginTop: "40px", marginBottom: "40px"}} />
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(PrivacyPolicy);