import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Input } from 'semantic-ui-react'
import Common from '../../../Common.js';

class AddArenaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editName: "",
            editUrl: "",
            editImg: "",
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
    
        if (this.state.editName.length === 0) {
            alert("배너 이름을 입력해주세요")
        } else if (this.state.editUrl.length === 0) {
            alert("Url을 입력해주세요.")
        } else if (this.state.editImg.length === 0) {
            alert("사진을 선택해주세요.")
        }

        let formData = new FormData();
        formData.append("name", this.state.editName)
        formData.append("url", this.state.editUrl);
        formData.append("banner_img", this.state.editImg);
        const conf = {
            method: "post",
            body: formData
        };
        fetch(Common.backendUrl + `/api/banner/list/`, conf)
            .then(response => {
                if (response.status > 400) {
                    alert("error")
                    return;
                } else if (response.status === 201) {
                    this.props.closeMethod();
                    return;
                }
            });

        
    }



    render() {
        return (
            <Modal
            open={this.props.isOpen}
            size="tiny"
        >
                <Header>배너 생성하기</Header>


                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{margin: "0px"}}>배너 이름</p>
                    <Input type="text" onChange={(e) =>this.setState({editName: e.target.value})}></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{ margin: "0px" }}>Url</p>
                    <Input type="text" onChange={(e) =>this.setState({editUrl: e.target.value})} placeholder="https://dronesoccer.or.kr" ></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ margin: "0px" }}>배너 사진</p>
                        <Input type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({editImg: e.target.files[0]})} alt="이미지 선택" />
                    </div>
                </Modal.Content>

                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    <Button color='green' inverted onClick={(e) => this.handleSubmit(e)}>
                        <Icon name='check' />적용
                    </Button>
                    <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                        <Icon name='remove' />닫기
                    </Button>
                </Modal.Actions>

        </Modal>
        );
    }
}

export default AddArenaModal;