import React, { Component } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import i18next from "../lang/i18n";
import FooterStyle from "./Footer.module.css";
import { withTranslation } from "react-i18next";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageTitle: "ko",
            text: "한국어",
        };
    }

    changeLanguage = (e, data) => {
        i18next.changeLanguage(data.value);
        window.location.reload();
        this.setState({
            languageTitle: data.value,
            text: data.text,
        });
    };

    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;

        const langOptions = [
            { key: "1", text: "한국어", value: "ko" },
            { key: "2", text: "English", value: "en" },
            { key: "3", text: "Japanese", value: "jp" },
            { key: "4", text: "Chinese", value: "ch" },
            { key: "5", text: "French", value: "fr" },
        ];

        return (
            <div className={FooterStyle.wrap_div}>
                <div>
                    <div>
                        <img width="96.67px" height="68.6px" src="/images/footer/ds_icon.png" alt="" />
                        <span>{t("Footer.Title")}</span>
                    </div>

                    <div style={{ marginTop: "15px", fontSize: "9pt" }} dangerouslySetInnerHTML={{ __html: t("Footer.Little_Text") }}></div>
                </div>

                <div>
                    <div>
                        <img src="/images/footer/cafe_icon_shadow.png" onClick={() => window.open("https://cafe.naver.com/dronesoccer/")} alt="" />
                        <img src="/images/footer/google_drive_icon.png" onClick={() => window.open("https://drive.google.com/drive/folders/1-FEOPYh15AMfjzicpN9-v1YNXbw9rlBI")} alt="" />
                        {/* <img src="/images/footer/instagram_icon_shadow.png" alt="" /> */}
                        {/* <img src="/images/footer/facebook_icon_shadow.png" alt="" /> */}
                        <img src="/images/footer/youtube_icon_shadow.png" onClick={() => window.open("https://www.youtube.com/channel/UCAERRS5SB6cr072yI2oXg-A")} alt="" />
                        {/* <img src="/images/footer/twiter_icon_shadow.png" alt="" /> */}
                    </div>
                    <div className={FooterStyle.lang_div}>
                        <Dropdown
                            className={`${FooterStyle.dropdown_tap_language} link item`}
                            floating
                            // text={this.state.text}
                            placeholder="Language"
                            button
                            style={{ marginLeft: "4px", backgroundColor: "rgba(255,255,255, 1)", border: "1px solid #FFFFFF" }}
                            defaultValue={this.props.i18n.language}
                            options={langOptions}
                            onChange={(e, data) => this.changeLanguage(e, data)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);
