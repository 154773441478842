import React, {Component} from 'react';
import {
    Modal,
    Button,
    Input,
    Form,
} from 'semantic-ui-react'

export default class RfidInputModal extends Component {

    handleSubmit = () => {
        this.props.changeRfid(document.getElementById('rfid_code').value)
    }

    
    render() {

        return(
                <Modal
                    size={'tiny'}
                    open={this.props.isOpen}
                >
                    <Modal.Header>RFID 카드를 태그해주세요</Modal.Header>
                    <Modal.Content>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">RFID</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        id='rfid_code'
                                        required
                                    />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">입력</Button>
                                <Button color='black' onClick={this.props.closeMethod}>취소</Button>
                            </Form>
                    </Modal.Content>
                </Modal>
        )
    }
}