import React, { Component } from "react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./IntroMascot.module.css";
import { Icon } from "semantic-ui-react";
import { Link, animateScroll as scroll } from "react-scroll";
import { withTranslation } from "react-i18next";

import MascotImg from "./drone_mascot.zip";
import KdsaLogo from "./kdsa_logo.zip";

class IntroMascot extends Component {
    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                <Topmenu />
                <div id="tap_1" className={styles.secondtab} style={{ backgroundImage: "url('/images/intro/bg_02.jpg')" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <a href={KdsaLogo} download="kdsa_logo.zip">
                                <img className={styles.intro_third_forth_img} src="/images/intro/introMascot_combine.png" alt=""></img>
                            </a>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginBottom: "50px" }}>
                                <span style={{ textAlign: "center", display: "inline", fontSize: "18px", textShadow: "gray 2px 2px 2px", fontFamily: "Noto Sans KR", fontWeight: "normal" }}></span>
                                <h1>{t("Introduction.IntroMascot.Main_Title_1")}</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ padding: "0px 30px" }} dangerouslySetInnerHTML={{ __html: t("Introduction.IntroMascot.Content_1") }}></div>
                            </div>
                        </div>
                    </div>

                    <Link className={styles.nav_link_down} to="tab_2" spy={true} smooth={true} offset={-70} duration={500}>
                        <Icon name="angle down" size="huge" />
                    </Link>
                </div>

                <div id="tab_2" className={styles.thirdtab} style={{ backgroundImage: "url('/images/intro/bg_04.jpg')" }}>
                    <Link className={styles.nav_link_up} to="tap_1" spy={true} smooth={true} offset={-70} duration={500}>
                        <Icon name="angle up" size="huge" />
                    </Link>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginBottom: "50px" }}>
                                <span style={{ textAlign: "center", display: "inline", fontSize: "18px", textShadow: "gray 2px 2px 2px", fontFamily: "Noto Sans KR", fontWeight: "normal" }}></span>
                                <h1>{t("Introduction.IntroMascot.Main_Title_2")}</h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ padding: "0px 30px" }} dangerouslySetInnerHTML={{ __html: t("Introduction.IntroMascot.Content_2") }}></div>
                            </div>
                        </div>
                        <div>
                            <a href={MascotImg} download="drone_mascot.zip">
                                <img className={styles.intro_third_forth_img2} src="/images/intro/intro_forth_img.png" alt=""></img>
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(IntroMascot);
