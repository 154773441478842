import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./IntroStatus.module.css";
import TeamModal from "../modal_team_detail/TeamModal.js";
import { withTranslation } from "react-i18next";
import Common from "../Common";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});

class IntroStatus extends Component {
    constructor() {
        super();

        this.state = {
            provinceData: "",
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.getProvinceData();
        // this.getRegionData();
        // console.log(document.getElementById("regionBody0"))
    };

    getProvinceData = () => {
        fetch(Common.backendUrl + "/api/province/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ provinceData: data });
            });
    };

    render() {
        const { t } = this.props;
        let list = [];
        let noneCnt = 0;
        let notNoneCnt = 0;

        // if (this.state.provinceData !== "") {
        //     for (let i = 0; i < this.state.provinceData.length; i++) {
        //         for (let j = 0; j < this.state.provinceData[j].regions.length; j++) {
        //             if (this.state.provinceData.regions !== null) {
        //                 // if (this.state.provinceData[i].regions[j].team === null || this.state.provinceData[i].regions[j].team === 'null') {
        //                 //     list.push(
        //                 //         <tr key={this.state.provinceData[i].regions[j].id}>
        //                 //         <td colSpan='5'>모집 중입니다.</td>
        //                 //     </tr>
        //                 //     )
        //                 //     continue;
        //                 // }
        //                 list.push(
        //                     <tr key={this.state.provinceData[i].regions[j].id}>
        //                         <td>{this.state.provinceData[i].regions[j].region_text}</td>
        //                         <td>{this.state.provinceData[i].regions[j].chief_name}</td>
        //                         <td>{this.state.provinceData[i].regions[j].team}</td>
        //                         <td>{this.state.provinceData[i].regions[j].email_text}</td>
        //                         <td>{this.state.provinceData[i].regions[j].phone_text}</td>
        //                     </tr>
        //                 )
        //             }
        //         }
        //     }
        //     console.log(list);
        // }

        return (
            <>
                <Topmenu />
                <div className={styles.wrap_div}>
                    <div>
                        <h1 style={{ marginBottom: "10px", color: "white" }}>{t("Introduction.IntroStatus.Main_Title")}</h1>
                    </div>
                    {this.state.provinceData !== "" ? (
                        this.state.provinceData.map((province, index) => (
                            <div key={province.id} className={styles.province_div}>
                                <span className={styles.testtest}>{((noneCnt = 0), (notNoneCnt = 0))}</span>
                                <h1 style={{ color: "#FFFFFF" }}>{province.province_text}</h1>

                                {province.chief_name !== "미정" ? (
                                    <table className={styles.province_chief_table}>
                                        <tbody>
                                            <tr>
                                                <td width="10%" style={{ backgroundColor: "#5814E1" }}>
                                                    지회장
                                                </td>
                                                <td width="20%">{province.chief_name}</td>
                                                {/* <td width='20%'>{province.email_text}</td> */}
                                                <td width="40%">
                                                    {province.phone_text}
                                                    <br />
                                                    {province.email_text}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <table className={styles.province_chief_table}>
                                        <tbody>
                                            <tr>
                                                <td width="10%" style={{ backgroundColor: "#5814E1" }}>
                                                    지회장
                                                </td>
                                                <td width="60%">지회장이 없습니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}

                                <div style={{ marginTop: "10px" }}>
                                    <table className={styles.squad_table}>
                                        <thead>
                                            <tr>
                                                <th>{t("Introduction.IntroStatus.Region")}</th>
                                                <th>{t("Introduction.IntroStatus.Chief_Name")}</th>
                                                <th>{t("Introduction.IntroStatus.Team_Name")}</th>
                                                <th>{t("Introduction.IntroStatus.Phone")}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {province.regions.map(
                                                (region) =>
                                                    region.chief_name !== "미정" && (
                                                        <tr key={region.id}>
                                                            <td className={styles.testtest}>{(notNoneCnt += 1)}</td>
                                                            <td>{region.region_text}</td>
                                                            <td>{region.chief_name}</td>
                                                            <td>{region.team}</td>
                                                            <td>
                                                                {region.phone_text}
                                                                <br />
                                                                {region.email_text}
                                                            </td>
                                                        </tr>
                                                    )
                                            )}
                                        </tbody>

                                        {notNoneCnt === 0 && (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="5">지부장이 없습니다.</td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(IntroStatus);
