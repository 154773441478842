import React, { Component } from "react";
import styles from "./CompetitionInfo.module.css";
import MainModal from '../main/MainModal.js';
import {withTranslation} from "react-i18next";
import {
  Header,
  Segment,
  Button,
  Table,
  TableRow,
} from 'semantic-ui-react'
import Common from '../Common';

class CompetitionGameMatchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamList: [],
      matchTypeCount: [],
      lengthCheck: "",
    }
  }

  getCompetitionData() {
    fetch(Common.backendUrl + "/api/competitiongamelist/" + this.props.competition_id + "/" + this.props.match_type + "/" + this.props.league_id)
      .then(response => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then(data => {
        this.setState({
            teamList: data
        });
      });
  }

  getMatchTypeCountData() {
    const data = { competition_id: this.props.competition_id, league: this.props.league_id};
    
    const conf = {
      method: "post",
      body: JSON.stringify(data),
      headers: new Headers({ "Content-Type": "application/json"}),
    };

    fetch(Common.backendUrl + "/api/matchtypecount/", conf)
      .then(response => {
        if (response.status >= 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then(data => {
        this.setState({matchTypeCount: data});
      });
  }

  componentDidMount() {
    this.getCompetitionData();
    if ( this.props.match_type === '결승'){
      this.getMatchTypeCountData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.competition_id !== prevProps.competition_id) {
      this.getCompetitionData();
    }
    if (this.props.match_type !== prevProps.match_type){
        this.getCompetitionData();
    }
    if (this.props.league_id !== prevProps.league_id){
      this.getCompetitionData();
    }
  }

  render() {
    const { t } = this.props;
    let list = [];

    if(this.state.teamList.length > 0){
        list[0] = this.state.teamList.map(
          info => (
            <tr key={info.id}>
                { this.props.match_type === 2 &&
                    <td>{info.match_type.match_type}</td>
                }
                { this.props.match_type === 4 &&
                    <td>{info.match_type.match_type}</td>
                }
                { this.props.match_type === 8 &&
                    <td>{info.match_type.match_type}</td>
                }
                { this.props.match_type === "예선" &&
                    <td>{info.match_type.match_type + ' ' + info.match_type.match_type_str}</td>
                }
                <td>{info.team_red.team.team_text}</td>
                <td>{info.team_blue.team.team_text}</td>
                <td>{info.first_set_score}</td>
                <td>{info.second_set_score}</td>
                <td>{info.third_set_score}</td>
                { info.winner !== null &&
                  <td>{info.winner.team_text}</td>
                }
                { info.winner === null &&
                  <td></td>
                }
                {/*<td><MainModal blueTeamName={info.team_blue.team.team_text} redTeamName={info.team_red.team.team_text} videoUrl={info.video_url}/></td> */}
                { info.video_url === null &&
                  <td></td>
                }
                { info.video_url !== null &&
                  <td><a target=".blank" href={info.video_url}><img height="30px" src="/images/footer/youtube.png" alt="" /></a></td>
                }
               
            </tr>
          )
        );
      
        if (Object.getOwnPropertyNames(list[0]).length === 1){
          list[0] = 
          <tr>
            <td colSpan={8}>{t('competition_info_detail.CompetitionDetailManager.No_Game')}</td>
          </tr>
        }
        
    }
    
    return (
      <div className={styles.table_wrap}>
          <table className={styles.competition_table}>
            <thead>
                <tr>
                    <th>{t('competition_info_detail.CompetitionDetailManager.Match_Classification')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.Red_Team')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.Blue_Team')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.1_set')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.2_set')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.3_set')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.Winning_Team')}</th>
                    <th>{t('competition_info_detail.CompetitionDetailManager.Game_Video')}</th>
                </tr>
            </thead>
            <tbody>
                {list[0]}
            </tbody>
        </table>  
      </div>
    )
  }
}

export default withTranslation()(CompetitionGameMatchResult)