import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

export default class  ModalChoiceOne extends Component {
    constructor(props){
        super(props);
    }

  render() {
    return (
        <Modal
          open={this.props.isOpen}
          size='mini'
        >
          <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px" }}>
            <Icon name='info' color="yellow" style={{ margin: "0px", fontSize: "15pt" }} />
          </Header>
          <Modal.Content>
            <p style={{ textAlign: "center", fontSize: "10pt", fontFamily: "Noto Sans KR", fontWeight: "bold", color: "black" }}>
              {this.props.warningText}
            </p>
          </Modal.Content>
          <Modal.Actions style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Button color='green' inverted onClick={() => this.props.closeMethod()}>
              <Icon name='remove' /> 닫기
            </Button>
          </Modal.Actions>
        </Modal>
    )
  }
}

