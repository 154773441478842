import React, { Component } from "react";
import styles from "./Writer.module.css";
import Topmenu from "../../Topmenu/Topmenu.js";
import Footer from "../../footer/Footer.js";
import Common from "../../Common";
import { withTranslation } from "react-i18next";
import ModalChoiceOne from "../../warning_modal/ModalChoiceOne.js";
import { Checkbox, Loader } from "semantic-ui-react";

const smartEditor = window.nhn.husky;

let oEditors = [];

class Writer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            content: "",
            attachfiles: [],
            warningModalOpen: false,
            warningText: "",
            warningLocation: false,
            uploadList: [],
        };
    }

    componentDidMount = () => {
        smartEditor.EZCreator.createInIFrame({
            oAppRef: oEditors,
            elPlaceHolder: "se2", // smart editor2를 적용시킬 textarea의 id
            sSkinURI: "/smart_editor2/SmartEditor2Skin.html",
            htParams: {
                // 툴바 사용 여부
                bUseToolbar: true,
                // 입력창 크기 조절바 사용 여부
                bUseVerticalResizer: true,
                // 모드 탭 (Editor | HTML | TEXT) 사용 여부
                bUseModeChanger: true,
                fOnBeforeUnload: function () {},
                // fOnAppLoad : function(){
                //     //예제 코드
                //     alert("ho?")
                //     oEditors.getById["se2"].exec("PASTE_HTML", ["로딩이 완료된 후에 본문에 삽입되는 text입니다."]);
                // },
                // fnOnAppReady: () => {
                //     alert("gi?")
                //     let sHTML = "<span style='color: #FF0000;'>이렇게 넣으면 됩니당~</span>"
                //     oEditors.exec("PASTS_HTML", [sHTML])
                // }
            },
            fCreator: "createSEditor2",
        });
    };

    pasteHTML = (e, url) => {
        e.preventDefault();
        let sHTML = `<img src=${url.split("?X-Amz-Algorithm")[0]} style="max-width: 90%;" alt='image' /> `;

        oEditors.getById.se2.exec("PASTE_HTML", [sHTML]);
    };

    pasteVideoUrl = (e) => {
        e.preventDefault();
        if (document.getElementById("videoURL").value !== "") {
            let sHTML = document.getElementById("videoURL").value;
            oEditors.getById.se2.exec("PASTE_HTML", [sHTML]);
        }
    };

    showHTML = (e) => {
        e.preventDefault();

        let sHTML = oEditors.getById.se2.getIR();
        // console.log(sHTML)
    };

    fileChangedHandler = (e) => {
        const maxSize = 100 * 1024 * 1024; // 100M 제한
        const files = e.target.files;
        let sumFileSize = 0;

        for (let i = 0; i < files.length; i++) {
            sumFileSize += files[i].size;
        }

        if (sumFileSize >= maxSize) {
            this.setState({
                warningModalOpen: true,
                warningText: "최대 업로드 용량(100M)을 초과했습니다",
            });
            return;
        }

        document.getElementById("loader").style.display = "flex";
        document.getElementById("wrap_div").style.pointerEvents = "none";
        this.setState({
            attachfiles: files,
        });

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("attachfiles", files[i]);
        }

        fetch(Common.backendUrl + "/board/api/board/", {
            method: "POST",
            body: formData,
        })
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                if (json.msg === "success") {
                    this.setState({
                        uploadList: json.data,
                    });
                } else if (json.filepath) {
                    // alert(json.filepath[0])
                    this.setState({
                        warningModalOpen: true,
                        warningText: json.filepath[0],
                    });
                } else {
                    // alert(json.msg)
                    this.setState({
                        warningModalOpen: true,
                        warningText: json.msg,
                    });
                }

                document.getElementById("loader").style.display = "none";
                document.getElementById("wrap_div").style.pointerEvents = "auto";
            })
            .catch((e) => {
                document.getElementById("loader").style.display = "none";
                document.getElementById("wrap_div").style.pointerEvents = "auto";
                this.setState({
                    warningModalOpen: true,
                    warningText: "error",
                });
            });
    };

    delTempFile = (e, tempfileId, filepath, index) => {
        e.preventDefault();

        let newAttachfiles = Array.from(this.state.attachfiles);
        newAttachfiles.splice(index, 1);
        let newUploadList = Array.from(this.state.uploadList);
        newUploadList.splice(index, 1);

        const formData = new FormData();
        formData.append("deleteTemp", true);
        formData.append("tempfilename", decodeURIComponent(filepath.split("?")[0].split("/")[filepath.split("?")[0].split("/").length - 1]));

        fetch(Common.backendUrl + `/board/api/board/${tempfileId}`, {
            // method: "DELETE",
            method: "PUT",
            body: formData,
        }).then((res) => {
            if (res.status === 204) {
                this.setState({
                    warningModalOpen: true,
                    warningText: "삭제되었습니다.",

                    attachfiles: newAttachfiles,
                    uploadList: newUploadList,
                });
            }
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { t } = this.props;
        const formData = new FormData();

        if (oEditors.getById.se2.getIR().length < 10) {
            this.setState({
                warningModalOpen: true,
                warningText: "내용을 10글자 이상 입력해주세요.",
            });
            return;
        }

        if (document.getElementById("isImportant")) {
            formData.append("is_important", document.getElementById("isImportant").checked);
        }
        formData.append("profile_id", sessionStorage.getItem("id"));
        formData.append("subject", this.state.subject);
        formData.append("content", oEditors.getById.se2.getIR());
        formData.append("board_kind", "qna_board");

        for (let i = 0; i < this.state.uploadList.length; i++) {
            formData.append("attachfileId", this.state.uploadList[i].id);
        }

        const conf = {
            method: "post",
            body: formData,
        };
        fetch(Common.backendUrl + "/board/api/board/", conf).then((response) => {
            if (response.status >= 400) {
                this.handleWarningModalOpen(t("board.qna_board.Post_writing_error"));
                return;
            } else if (response.status === 201) {
                this.handleWarningModalOpen(t("board.qna_board.The_post_has_been_created"));
                this.setState({
                    warningLocation: true,
                });
                return;
            }
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
            warningLocation: false,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.warningLocation) {
            document.location.href = "/qna_board/list/";
        }
    };

    render() {
        window.scrollTo(0, 0);
        const { subject, content } = this.state;
        const { t } = this.props;

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Topmenu />
                <div id="wrap_div" className={styles.wrap_div}>
                    <div className={styles.title_div}>
                        <h1>{t("board.qna_board.title")}</h1>
                        <hr style={{ border: 0, borderBottom: "1px solid #020202" }} />
                    </div>
                    <div className={styles.content_div}>
                        {sessionStorage.getItem("isAdmin") ? (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                <Checkbox name="isImportant" id="isImportant" />
                                <p style={{ margin: 0, color: "#ff4e59", fontWeight: "600", marginLeft: "5px" }}>공지게시글로 등록</p>
                            </div>
                        ) : null}
                        <form onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <input
                                        type="text"
                                        placeholder={t("board.qna_board.Title_Placeholder")}
                                        name="subject"
                                        id="subject"
                                        style={{ width: "100%", background: "rgba(226, 226, 226, 0.5)", outline: "none", fontFamily: "Noto Sans KR", fontSize: "1em", color: "#020202", textAlign: "center", border: "0px", padding: "10px" }}
                                        onChange={this.handleChange}
                                        value={subject}
                                        required
                                    ></input>
                                </div>
                                <div style={{ marginTop: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "1em", margin: "10px 0px" }}>
                                    {t("board.qna_board.File_Attachments")} ({t("board.qna_board.Allow_multiple_files")})
                                    <input type="file" multiple onChange={this.fileChangedHandler} style={{ background: "#f7f7f7", width: "100%", overflowX: "auto", marginBottom: "10px" }} />
                                    {this.state.uploadList ? (
                                        this.state.uploadList.map((file, index) => {
                                            return (
                                                <div key={file.id} id={"tempfiles" + file.id} style={{ borderTop: "1px dashed #e2e2e2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", padding: "5px 0px" }}>
                                                    <p style={{ margin: 0, marginRight: "5px" }}>#{index + 1}: </p>
                                                    <p style={{ margin: 0 }}>{file.filename}</p>
                                                    <button className={styles.del_btn} onKeyDown={(e) => e.preventDefault()} onClick={(e) => this.delTempFile(e, file.id, file.filepath, index)}>
                                                        DEL
                                                    </button>
                                                    {file.image_check === 1 ? (
                                                        <button onKeyDown={(e) => e.preventDefault()} style={{ marginLeft: "5px" }} onClick={(e) => this.pasteHTML(e, file.filepath)}>
                                                            이미지 삽입
                                                        </button>
                                                    ) : null}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div style={{ borderTop: "1px dashed #e2e2e2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                                            <p style={{ margin: 0 }}>업로드된 파일이 없습니다.</p>
                                        </div>
                                    )}
                                    <input type="text" style={{ width: "50%", marginRight: "5px" }} placeholder="동영상 URL을 입력해주세요." id="videoURL" />
                                    <button onClick={(e) => this.pasteVideoUrl(e)}>동영상 삽입</button>
                                </div>
                                <div id="se2_div" className={styles.se2_div}>
                                    <textarea id="se2" style={{ width: "100%", height: "600px" }}>
                                        내용을 입력하세요.
                                    </textarea>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                                <button className={styles.writer_btn} onKeyDown={(e) => e.preventDefault()} type="submit" style={{ marginRight: "10px" }}>
                                    {t("board.qna_board.Enrollment")}
                                </button>
                                <button className={styles.writer_btn} onKeyDown={(e) => e.preventDefault()} type="reset" onClick={() => (window.location.href = "/qna_board/list")}>
                                    {t("board.qna_board.Cancel")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="loader" style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.2)", width: "100vw", height: "100vh", position: "fixed", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Loader style={{ display: "flex", fontFamily: "Noto Sans KR", fontSize: "30px" }}>uploading ...</Loader>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(Writer);
