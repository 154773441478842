import React, { Component } from "react";
import styles from "./CompetitionInfo.module.css";
import Carousel from "react-spring-3d-carousel";
import { Dropdown, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import Common from "../Common";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
    },
});

// 메인화면 대회페이지
class CompetitionInfo extends Component {
    constructor(props) {
        super(props);

        const maxYear = new Date().getFullYear();
        const minYear = 2020;
        const years = [];

        for (let i = maxYear; i >= minYear; i--) {
            years.push({ key: i, text: i, value: i });
        }

        this.state = {
            crntYear: maxYear,
            yearDropDownOptions: years,
            selType: "종합",
            competitionTypeOptions: [],
            competitionId: 0,
            competitionPosters: [],
            competitionStartYear: "",
            competitionStartDate: "",
            competitionStartDay: "",
            competitionEndYear: "",
            competitionEndDate: "",
            competitionEndDay: "",
            competitionInfo: "",
            competitionTitle: "",
            competitionApplyStart: "",
            competitionApplyEnd: "",
            competitionPlace: "",
            league1Teams: "",
            league2Teams: "",
            league3Teams: "",
            crntIndex: 0,
            totalIndex: 0,
            transitionModalOpen: false,
            isRightBtnHide: false,
            isLeftBtnHide: true,
            competitionType: [],
        };
    }

    componentDidMount = () => {
        this.getLastYearCompetition();
        this.getCompetitionData(this.state.crntYear, "종합");
        this.getCompetitionTypeOptions();
    };
    getLastYearCompetition() {
        fetch(Common.backendUrl + "/api/competition/lastone")
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("error");
                }
                return res.json();
            })
            .then((data) => {
                const lastYear = parseInt(data[0].competition_start_date.split("-")[0]);
                if (this.state.yearDropDownOptions[0].key < lastYear) {
                    this.setState({
                        yearDropDownOptions: [{ key: lastYear, text: lastYear, value: lastYear }, ...this.state.yearDropDownOptions],
                    });
                }
            });
    }

    getCompetitionTypeOptions = () => {
        fetch(Common.backendUrl + "/api/competition/type/", {
            method: "get",
        })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요");
                }
                return res.json();
            })
            .then((json) => {
                const competitionTypeOptions = [];
                json.map((type) => {
                    competitionTypeOptions.push({ key: type.id, text: type.competition_type, value: type.competition_type });
                });
                this.setState({
                    competitionTypeOptions,
                });
            })
            .catch((error) => {
                alert(error + "");
            });
    };

    getCompetitionData = (year, type) => {
        fetch(Common.backendUrl + `/api/show_competition/${year}/${type}/`)
            .then((res) => {
                if (res.status > 400) {
                    //   this.handleWarningModalOpen("대회 정보를 불러오지 못했습니다.");
                    alert("error");
                }
                return res.json();
            })
            .then((data) => {
                const week_day = ["일", "월", "화", "수", "목", "금", "토"];

                let competitionPosters = [];
                let competitionId = [];
                let competitionStartYear = [];
                let competitionStartDate = [];
                let competitionStartDay = [];
                let competitionEndYear = [];
                let competitionEndDate = [];
                let competitionEndDay = [];
                let competitionInfo = [];
                let competitionTitle = [];
                let competitionApplyStart = [];
                let competitionApplyEnd = [];
                let competitionPlace = [];
                let competitionType = [];
                let isLeftBtnHide = true;
                let isRightBtnHide = true;

                if (data.length > 0) {
                    data.map((competition, index) => {
                        competitionPosters.push({
                            key: index,
                            content: <img className={styles.competition_posters} onClick={() => this.posterClick(index)} src={competition.thumbnail_image_file} alt="competition poster" />,
                        });
                        const temp_day1 = week_day[new Date(competition.competition_start_date.split("T")[0]).getDay()];
                        const temp_day2 = week_day[new Date(competition.competition_end_date.split("T")[0]).getDay()];
                        competitionStartYear.push(competition.competition_start_date.split("T")[0].split("-")[0] + ".");
                        competitionStartDate.push(competition.competition_start_date.split("T")[0].split("-")[1] + "." + competition.competition_start_date.split("T")[0].split("-")[2] + ".");
                        competitionStartDay.push("(" + temp_day1 + ")");
                        competitionEndYear.push(competition.competition_end_date.split("T")[0].split("-")[0] + ".");
                        competitionEndDate.push(competition.competition_end_date.split("T")[0].split("-")[1] + "." + competition.competition_end_date.split("T")[0].split("-")[2] + ".");
                        competitionEndDay.push("(" + temp_day2 + ")");
                        if (competition.competition_info) {
                            competitionInfo.push(competition.competition_info);
                        } else {
                            competitionInfo.push("");
                        }
                        competitionId.push(competition.id);
                        competitionTitle.push(competition.competition_text);
                        competitionApplyStart.push(competition.registration_start_date.split("T")[0]);
                        competitionApplyEnd.push(competition.registration_end_date.split("T")[0]);
                        competitionPlace.push(competition.place);
                        competitionType.push(competition.competition_type.competition_type);
                    });
                    competitionPosters.push(
                        {
                            key: competitionPosters.length + 1,
                            content: <div></div>,
                        },
                        {
                            key: competitionPosters.length + 2,
                            content: <div></div>,
                        }
                    );
                }

                if (competitionPosters.length - 2 > 1) {
                    isRightBtnHide = false;
                }
                this.setState({
                    totalIndex: competitionPosters.length - 2,
                    isLeftBtnHide,
                    isRightBtnHide,
                    competitionPosters,
                    competitionId,
                    competitionStartYear,
                    competitionStartDate,
                    competitionStartDay,
                    competitionEndYear,
                    competitionEndDate,
                    competitionEndDay,
                    competitionInfo,
                    competitionTitle,
                    competitionApplyStart,
                    competitionApplyEnd,
                    competitionPlace,
                    competitionType,
                });
            });
    };

    getCompetitionRankData = (competitionId) => {
        this.getLeagueData(competitionId, 1);
        this.getLeagueData(competitionId, 2);
        this.getLeagueData(competitionId, 3);
    };

    getLeagueData = (competitionId, league) => {
        fetch(Common.backendUrl + `/api/show_competition/rank/${competitionId}/${league}`)
            .then((res) => {
                if (res.status > 400) {
                    alert("error");
                }
                return res.json();
            })
            .then((data) => {
                // ToDo -> 팀 이름, 팀 로고이미지: 없을시 디폴트 로고, 랭크, 상금 가져오기.
                if (league === 1) {
                    this.setState({
                        league1Teams: data,
                    });
                } else if (league === 2) {
                    this.setState({
                        league2Teams: data,
                    });
                } else if (league === 3) {
                    this.setState({
                        league3Teams: data,
                    });
                }
            });
    };

    prevClick = () => {
        if (this.state.crntIndex === 0) {
            return;
        }

        this.getCompetitionRankData(this.state.competitionId[this.state.crntIndex - 1]);
        this.setState({
            crntIndex: this.state.crntIndex - 1,
        });
        if (this.state.crntIndex === 1) {
            this.setState({
                isLeftBtnHide: true,
            });
        }
        this.setState({
            isRightBtnHide: false,
        });
    };

    nextClick = () => {
        if (this.state.crntIndex === this.state.totalIndex - 1) {
            return;
        }
        this.getCompetitionRankData(this.state.competitionId[this.state.crntIndex + 1]);
        this.setState({
            crntIndex: this.state.crntIndex + 1,
            isLeftBtnHide: false,
        });
        if (this.state.crntIndex === this.state.totalIndex - 2) {
            this.setState({
                isRightBtnHide: true,
            });
        }
    };

    posterClick = (index) => {
        if (index - this.state.crntIndex === 1) {
            this.getCompetitionRankData(this.state.competitionId[this.state.crntIndex + 1]);
            this.setState({
                crntIndex: index,
            });
        } else if (index - this.state.crntIndex === -1) {
            this.getCompetitionRankData(this.state.competitionId[this.state.crntIndex - 1]);
            this.setState({
                crntIndex: index,
            });
        } else if (index === this.state.crntIndex) {
            window.open(this.state.competitionPosters[index]["content"]["props"]["src"]);
        } else {
            return;
        }
        //첫 포스터일때 왼쪽 버튼 숨김
        if (index === 0) {
            this.setState({
                isLeftBtnHide: true,
            });
        } else {
            this.setState({
                isLeftBtnHide: false,
            });
        }
        //마지막 포스터일때 오른쪽 버튼 숨김
        if (index === this.state.totalIndex - 1) {
            this.setState({
                isRightBtnHide: true,
            });
        } else {
            this.setState({
                isRightBtnHide: false,
            });
        }
    };

    transitionModalCloseMethod = () => {
        this.setState({
            transitionModalOpen: false,
        });
    };

    yearDropdownChange = (e, { value }) => {
        this.getCompetitionData(value, this.state.selType);
        this.setState({
            crntYear: value,
            crntIndex: 0,
        });
    };

    typeDropdownChange = (e, { value }) => {
        this.getCompetitionData(this.state.crntYear, value);
        this.setState({
            selType: value,
            crntIndex: 0,
        });
    };

    render() {
        const { t } = this.props;
        const getDate = new Date();
        let month = "";
        let crntDate = "";

        if (getDate.getMonth() + 1 < 10) {
            month = "0" + (parseInt(getDate.getMonth()) + 1);
        } else {
            month = parseInt(getDate.getMonth()) + 1;
        }

        if (getDate.getDate() < 10) {
            crntDate = getDate.getFullYear() + "-" + month + "-0" + getDate.getDate();
        } else {
            crntDate = getDate.getFullYear() + "-" + month + "-" + getDate.getDate();
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className={styles.content_div}>
                    <h1>{t("Main.CompetitionInfo.Main_Title")}</h1>
                    <hr />
                    <div className={styles.dropdown_div}>
                        <Button.Group>
                            <Button>연도</Button>
                            <Dropdown className="button icon" floating onChange={this.yearDropdownChange} value={this.state.crntYear} options={this.state.yearDropDownOptions} />
                        </Button.Group>
                        <Button.Group>
                            <Button>리그</Button>
                            <Dropdown className="button icon" floating onChange={this.typeDropdownChange} value={this.state.selType} options={this.state.competitionTypeOptions} />
                        </Button.Group>
                    </div>
                    {this.state.competitionPosters.length > 0 ? (
                        <>
                            <MediaContextProvider>
                                <Media at="mobile">
                                    <MobileApp isLeftHide={this.state.isLeftBtnHide} isRightHide={this.state.isRightBtnHide} slides={this.state.competitionPosters} goToSlide={this.state.crntIndex} nextClick={this.nextClick} prevClick={this.prevClick} />
                                </Media>
                                <Media greaterThan="mobile">
                                    <DesktopApp isLeftHide={this.state.isLeftBtnHide} isRightHide={this.state.isRightBtnHide} slides={this.state.competitionPosters} goToSlide={this.state.crntIndex} nextClick={this.nextClick} prevClick={this.prevClick} />
                                </Media>
                            </MediaContextProvider>
                            <div className={styles.infoText_div}>
                                <p>{this.state.competitionType[this.state.crntIndex]} 대회</p>
                                <hr />
                                <h1>{this.state.competitionTitle[this.state.crntIndex]}</h1>
                                <hr style={{ marginBottom: "30px" }} />
                                <p style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                                    <span>대회 기간</span> : {this.state.competitionStartYear[this.state.crntIndex]}
                                    <span className={styles.orange_span}>{this.state.competitionStartDate[this.state.crntIndex]}</span>
                                    {this.state.competitionStartDay[this.state.crntIndex]}&nbsp;~&nbsp;
                                    <p>
                                        {this.state.competitionEndYear[this.state.crntIndex]}
                                        <span className={styles.orange_span}>{this.state.competitionEndDate[this.state.crntIndex]}</span>
                                        {this.state.competitionEndDay[this.state.crntIndex]}
                                    </p>
                                </p>
                                {/* <p><span>예선전</span> : {this.state.competitionStartYear[this.state.crntIndex]}<span className={styles.orange_span}>{this.state.competitionStartDate[this.state.crntIndex]}</span>{this.state.competitionStartDay[this.state.crntIndex]}</p> */}
                                {/* <p><span>4강전 및 결승전</span> : {this.state.competitionEndYear[this.state.crntIndex]}<span className={styles.orange_span}>{this.state.competitionEndDate[this.state.crntIndex]}</span>{this.state.competitionEndDay[this.state.crntIndex]}</p> */}
                                <p>
                                    장소: <span>{this.state.competitionPlace[this.state.crntIndex]}</span>
                                </p>
                                <div style={{ whiteSpace: "break-spaces", fontSize: "18px" }} dangerouslySetInnerHTML={{ __html: this.state.competitionInfo[this.state.crntIndex] }}></div>
                                {this.state.competitionApplyEnd[this.state.crntIndex] >= crntDate && this.state.competitionApplyStart[this.state.crntIndex] <= crntDate ? (
                                    <button className={styles.all_rank_btn} onClick={() => (window.location.href = `/competition_aplication/${this.state.competitionId[this.state.crntIndex]}`)}>
                                        참가 신청
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </>
                    ) : (
                        <h2 style={{ color: "#FFFFFF" }}>조회된 대회가 없습니다.</h2>
                    )}
                </div>
            </div>
        );
    }
}

class DesktopApp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const carousel_config = {
            tension: 210,
            friction: 20,
        };
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "90vw", maxWidth: "1600px", justifyContent: "space-evenly" }}>
                {this.props.isLeftHide ? (
                    <img width="50px" height="50px" src="/images/info_competition/arrow_left.png" className={styles.arrow_btn} style={{ visibility: "hidden" }} onClick={() => this.props.prevClick()} alt="arrow left" />
                ) : (
                    <img width="50px" height="50px" src="/images/info_competition/arrow_left.png" className={styles.arrow_btn} onClick={() => this.props.prevClick()} alt="arrow left" />
                )}
                <div className={styles.competition_carousel}>
                    <Carousel slides={this.props.slides} goToSlide={this.props.goToSlide} offsetRadius={2} showNavigation={false} animationConfig={carousel_config} />
                </div>
                {this.props.isRightHide ? (
                    <img width="50px" height="50px" src="/images/info_competition/arrow_right.png" className={styles.arrow_btn} style={{ visibility: "hidden" }} onClick={() => this.props.nextClick()} alt="arrow right" />
                ) : (
                    <img width="50px" height="50px" src="/images/info_competition/arrow_right.png" className={styles.arrow_btn} onClick={() => this.props.nextClick()} alt="arrow right" />
                )}
            </div>
        );
    }
}

class MobileApp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const carousel_config = {
            tension: 210,
            friction: 20,
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "90vw", maxWidth: "1600px" }}>
                <div className={styles.competition_carousel}>
                    <Carousel slides={this.props.slides} goToSlide={this.props.goToSlide} offsetRadius={2} showNavigation={false} animationConfig={carousel_config} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {this.props.isLeftHide ? (
                        <img width="50px" height="50px" src="/images/info_competition/arrow_left.png" className={styles.arrow_btn} style={{ visibility: "hidden" }} onClick={() => this.props.prevClick()} alt="arrow left" />
                    ) : (
                        <img width="50px" height="50px" src="/images/info_competition/arrow_left.png" className={styles.arrow_btn} onClick={() => this.props.prevClick()} alt="arrow left" />
                    )}
                    {this.props.isRightHide ? (
                        <img width="50px" height="50px" src="/images/info_competition/arrow_right.png" className={styles.arrow_btn} style={{ visibility: "hidden" }} onClick={() => this.props.nextClick()} alt="arrow right" />
                    ) : (
                        <img width="50px" height="50px" src="/images/info_competition/arrow_right.png" className={styles.arrow_btn} onClick={() => this.props.nextClick()} alt="arrow right" />
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(CompetitionInfo);
