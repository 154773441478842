import React, { Component } from 'react'
import styles from "./ScoreInput.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from '../Common';

export default class ScoreInput extends Component {
    constructor(props){
        super(props);
        this.state = {
            blueScore: 0,
            redScore: 0,
            set_choiced: this.props.match.params.choiced_set,
            warningModalOpen: false,
            warningText: "",
        }
    }

    componentDidMount = () => {
        this.getInterval()
    }

    getInterval = () => {
        let counterId = setInterval(() => {
            this.getGameList()
        }, 500)
    }

    getGameList() {
        fetch(Common.backendUrl + "/api/gamematchforscore/" + this.props.match.params.game_id)
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                let redScore = "";
                let blueScore = "";

                if (this.props.match.params.set_choiced === "1set") {
                    redScore = parseInt(data["first_set_score"].split('-')[0])
                    blueScore = parseInt(data["first_set_score"].split('-')[1])
                } else if (this.props.match.params.set_choiced === "2set") {
                    redScore = parseInt(data["second_set_score"].split('-')[0])
                    blueScore = parseInt(data["second_set_score"].split('-')[1])
                } else if (this.props.match.params.set_choiced === "3set") {
                    redScore = parseInt(data["third_set_score"].split('-')[0])
                    blueScore = parseInt(data["third_set_score"].split('-')[1])
                }

                this.setState({
                    redScore,
                    blueScore,
                });
            });
    }

    plusScore = () => {
        let form_data = new FormData();      

        if (this.props.match.params.set_choiced === "1set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("first_set_score", parseInt(this.state.redScore)+1 + "-" + this.state.blueScore)
            }else {
                form_data.append("first_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore+1))
            }
        } else if (this.props.match.params.set_choiced === "2set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("second_set_score", parseInt(this.state.redScore)+1 + "-" + this.state.blueScore)
            }else {
                form_data.append("second_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore+1))
            }
        } else if (this.props.match.params.set_choiced === "3set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("third_set_score", parseInt(this.state.redScore)+1 + "-" + this.state.blueScore)
            }else {
                form_data.append("third_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore+1))
            }
        }


        fetch(Common.backendUrl + "/api/match/update/" + this.props.match.params.game_id, {
            method: "put",
            body : form_data,
        })
            .then(response => {
                if (response.status >= 400){
                    this.handleWarningModalOpen("error");
                }
            })
    }

    minusScore = () => {
        let form_data = new FormData();      

        if (this.props.match.params.set_choiced === "1set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("first_set_score", parseInt(this.state.redScore)-1 + "-" + this.state.blueScore)
            }else {
                form_data.append("first_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore-1))
            }
        } else if (this.props.match.params.set_choiced === "2set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("second_set_score", parseInt(this.state.redScore)-1 + "-" + this.state.blueScore)
            }else {
                form_data.append("second_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore-1))
            }
        } else if (this.props.match.params.set_choiced === "3set") {
            if(this.props.match.params.team === "redteam"){
                form_data.append("third_set_score", parseInt(this.state.redScore)-1 + "-" + this.state.blueScore)
            }else {
                form_data.append("third_set_score", this.state.redScore + "-" + parseInt(this.state.blueScore-1))
            }
        }


        fetch(Common.backendUrl + "/api/match/update/" + this.props.match.params.game_id, {
            method: "put",
            body : form_data,
        })
            .then(response => {
                if (response.status >= 400){
                    this.handleWarningModalOpen("error");
                }
            })
    }

    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }


    render() {
        const { redScore, blueScore } = this.state;
        let team_background = "";
        let team_score = "";

        if (this.props.match.params.team === "redteam") {
            team_background = "url('/images/score/scoreboard_red_bg01.png')"
            team_score = redScore;
        } else {
            team_background = "url('/images/score/scoreboard_blue_bg01.png')"
            team_score = blueScore;
        }

        return (
            <div className={styles.wrap_div} style={{ backgroundImage: team_background }}>
                <h1>{team_score}</h1>
                <p onClick={() => this.plusScore()}>➕</p>
                <p onClick={() => this.minusScore()}>➖</p>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}