import React, { Component } from 'react'
import { Button, Header, Icon, Modal, Divider, Dropdown } from 'semantic-ui-react'
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from "../../../Common";

export default class TeamRequestsManageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: "",
            warningModalOpen: false,
            warningText: "",
            isLocation: false,
        }
    }

    handleRequestUpdate = (e) => {
        e.preventDefault();
        if (this.state.selectedMenu === ""){
            alert("처리상태를 변경해주세요.")
            return;
        }

        let form_data = new FormData();
        
        form_data.append("request_state", this.state.selectedMenu)
        
     
        fetch(Common.backendUrl + `/api/team/requests/update/${this.props.requestContent[0]["id"]}`, {
            method: "put",
            body : form_data,
        })
            .then(response => {
                if (response.status >= 400){
                    this.handleWarningModalOpen("error");
                }else {
                    this.handleWarningModalOpen("업데이트 완료");
                }
            })
    }

    selectMenuChange = (e, {value}) => {
        this.setState({
            selectedMenu: value,
        })
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
        window.location.reload()
    }

    render() {
        const selectMenuOptions = [
            { key: 0, text: "신청", value: "신청" },
            { key: 1, text: "반려", value: "반려" },
            { key: 2, text: "승인", value: "승인" },
        ]
    
        return (
            this.props.requestContent.length !== 0 ?
                <Modal
                    open={this.props.isOpen}
                    size="tiny"
                >
                    <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
                        <h1 style={{ fontFamily: "Noto Sans KR", fontSize: "22px", fontWeight: "bold" }}>요청사항</h1>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", fontWeight: "bold" }}>
                            <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["id"]}</p>
                            <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["create_at"].split("T")[0]}</p>
                        </div>
                        <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["select_menu"]} 신청</p>
                    </Header>
                    <Divider style={{ margin: "0px" }} />
                    <Modal.Content style={{ padding: "20px", height: "200px", overflowY: "auto" }}>
                        <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR" }}>{this.props.requestContent[0]["content"]}</p>
                    </Modal.Content>
                    <Divider style={{margin: "0px"}} />
                    <Modal.Content style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px"}}>
                        <p style={{fontSize: "15px", fontWeight: "bold"}}>처리상태 변경</p>
                        <Dropdown
                            selection
                            placeholder="처리상태를 선택하세요."
                            defaultValue={this.props.requestContent[0]["request_state"]}
                            options={selectMenuOptions}
                            onChange={this.selectMenuChange}
                        />
                    </Modal.Content>
                    <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                        <Button color='green' inverted onClick={this.handleRequestUpdate}>
                            <Icon name='check' />적용
                        </Button>
                        <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                            <Icon name='remove' />닫기
                        </Button>
                    </Modal.Actions>
                    <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                </Modal>
                :
                <></>

        )
    }
}

