import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import styles from "./Membermypage.module.css";
import { Input, Segment, List, Form, Table, TableRow } from "semantic-ui-react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import CertificateModal from "./CertificateModal.js";
import PasswordChangeModal from "./PasswordChangeModal.js";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class MemberMyPage extends Component {
    constructor(props) {
        super(props);
        this.province_id = "";
        this.region_id = "";
    }

    state = {
        regionData: "",
        team_text: "",
        name: "",
        birth: "",
        positionId: "",
        positionOptions: "",
        kind: "",
        kindOptions: "",
        user_name: "",
        phone: "",
        email: "",
        image_file: null,
        modal_open: false,
        isDuplicateId: false,
        isDuplicateEmail: false,
        isDuplicateEmailCheck: false,
        validEmail: false,
        validPhone: false,
        member_pk: "",
        pwdChangeModalOpen: false,

        profile: {
            member_text: "",
            member_email: "",
            member_id: "",
            member_phone: "",
            kind: "",
            image: "",
            member_position: "",
            birth_date: "",
            team_text: "",
            region_text: "",
            province_text: "",
        },

        errors: {
            duplicate_homepage_id: "",
            duplicate_email: "",
        },
        warningModalOpen: false,
        warningText: "",
        warningLocation: false,
        certificateModalOpen: false,

        eduApplyHistory: "",
    };

    getKindList() {
        let arr = [];
        const kind = [
            { id: 1, kind: "일반" },
            { id: 2, kind: "유소년" },
        ];
        kind.forEach((data) => arr.push({ key: data.id, text: data.kind, value: data.id }));
        this.setState({ kindOptions: arr });
    }

    getCurrentUser() {
        if (sessionStorage.getItem("token")) {
            fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    Authorization: `JWT ${sessionStorage.getItem("token")}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    let newProfile = {
                        member_text: json.profile.member_text,
                        member_email: json.profile.member_email,
                        member_phone: json.profile.member_phone,
                        member_id: json.profile.member_id,
                        cert_grade: json.profile.cert_grade,
                        auth_text: json.profile.member_authlevel.auth_text,
                        kind: json.profile.kind,
                        image: json.profile.image,
                        image_file: json.profile.image_file,
                        member_position: json.profile.member_position,
                        birth_date: json.profile.birth_date,
                        team_text: json.profile.team.team_text,
                        region_text: json.profile.team.region.region_text,
                        province_text: json.profile.team.region.province.province_text,
                        leader_certificate: json.profile.leader_certificate,
                        referee_certificate: json.profile.referee_certificate,
                    };
                    this.setState({
                        member_pk: json.id,
                        user_name: json.username,
                        profile: newProfile,
                    });
                });
        }
    }

    getEduApplyHistory = () => {
        fetch(Common.backendUrl + `/board/api/eduapply/${sessionStorage.getItem("id")}`)
            .then((response) => {
                if (response.status >= 400) {
                    alert("error");
                    return;
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                this.setState({
                    eduApplyHistory: data,
                });
            });
    };

    componentDidMount() {
        this.getCurrentUser();
        this.getKindList();
        this.getEduApplyHistory();
        sessionStorage.removeItem("leftMenu");
    }

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;

        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3);
            let middleNum = checkPhone.slice(3, 7);
            let lastNum = checkPhone.slice(7, 11);
            const strPhone = firstNum + "-" + middleNum + "-" + lastNum;
            this.setState({
                phone: strPhone,
                validPhone: true,
            });
        } else {
            this.setState({
                phone: "",
                validPhone: false,
            });
        }
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleEmailChange = (e, { value }) => {
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        this.setState({ validEmail: regExp.test(value) });
        if (regExp.test(value) === true) {
            this.setState({ email: value });
        }
    };

    handleDuplicateEmailCheck = (e) => {
        e.preventDefault();
        const { t } = this.props;
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        if (document.getElementById("email_input").value === "") {
            this.handleWarningModalOpen(t("mypage.MemberMyPage.Change_Email_Alert"));
            return;
        }

        if (regExp.test(document.getElementById("email_input").value) === false) {
            this.handleWarningModalOpen(t("mypage.MemberMyPage.Email_Type_Alert"));
            return;
        }

        fetch(Common.backendUrl + "/api/useremail/" + document.getElementById("email_input").value)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                const isMemberEmailFound = data.length;
                isMemberEmailFound > 0
                    ? this.setState({
                          errors: {
                              duplicate_email: t("mypage.MemberMyPage.Dup_Email_Alert"),
                          },
                          isDuplicateEmail: true,
                          isDuplicateEmailCheck: false,
                      })
                    : this.setState({
                          errors: {
                              duplicate_email: t("mypage.MemberMyPage.Success_email"),
                          },
                          isDuplicateEmail: false,
                          isDuplicateEmailCheck: true,
                      });
            });
    };

    handleCancle = (e) => {
        e.preventDefault();
        this.props.history.goBack();
        //window.history.back();
    };

    handleTeam = (e) => {
        e.preventDefault();
        window.location.href = "/team_modification";
        //window.history.back();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { t } = this.props;
        let member = {
            member_text: "",
            member_phone: "",
            member_email: "",
        };

        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        let firstNum = document.getElementById("phone").value.slice(0, 3);
        let middleNum = document.getElementById("phone").value.slice(3, 7);
        let lastNum = document.getElementById("phone").value.slice(7, 11);
        const strPhone = firstNum + "-" + middleNum + "-" + lastNum;

        let form_data = new FormData();

        if (this.state.image_file !== null) {
            form_data.append("image", this.state.image_file.name);
            form_data.append("image_file", this.state.image_file);
        }

        if (this.state.isDuplicateEmailCheck === false) {
            if (this.state.profile.member_email !== document.getElementById("email_input").value) {
                this.handleWarningModalOpen("이메일 중복검사를 해주세요.");
                return;
            }
        }

        if (this.state.isDuplicateEmail === true) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Dup_Email_Alert"));
            return;
        } else if (regExp.test(document.getElementById("email_input").value) === false) {
            this.handleWarningModalOpen(t("signup.MemberSignUp.Email_Type_Alert"));
            return;
        } else {
            form_data.append("member_email", document.getElementById("email_input").value);
        }

        if (document.getElementById("phone").value.length !== 11) {
            this.handleWarningModalOpen(t("mypage.MemberMyPage.Phone_Num_Alert"));
            return;
        } else {
            form_data.append("member_phone", strPhone);
        }

        if (document.getElementById("member_id").value === "") {
            this.handleWarningModalOpen("선수자격번호를 입력하세요.");
            return;
        } else if (document.getElementById("member_id").value.search(/\s/) !== -1) {
            this.handleWarningModalOpen("선수자격번호의 공백을 제거해주세요.");
            return;
        }
        form_data.append("member_id", document.getElementById("member_id").value);

        // form_data.append("username", this.state)

        fetch(Common.backendUrl + "/api/profileupdate/" + this.state.member_pk, {
            method: "put",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    if (response.status === 406) {
                        throw new Error("이미 사용중인 선수자격번호입니다.");
                    }
                    return response.json();
                } else {
                    return 200;
                }
            })
            .then((json) => {
                if (json !== undefined) {
                    if (json === 200) {
                        this.handleWarningModalOpen(t("mypage.MemberMyPage.Succes_modifiy"));
                        this.setState({
                            warningLocation: true,
                        });
                        return;
                    }
                    this.handleWarningModalOpen("오류가 발생했습니다. 관리자에게 문의하세요.");
                } else {
                    this.handleWarningModalOpen("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    toDate(dateStr) {
        if (dateStr) {
            let date = dateStr.split("T");
            return date[0];
        } else {
            return;
        }
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
            warningLocation: false,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
            pwdChangeModalOpen: false,
        });

        if (this.state.warningLocation) {
            // document.location.href="/";
            window.location.reload();
        }
    };

    handleCertificateModalClose = () => {
        this.setState({
            certificateModalOpen: false,
        });
    };

    render() {
        const { t } = this.props;

        let eduHistorys = "";

        if (this.state.eduApplyHistory !== "") {
            eduHistorys = this.state.eduApplyHistory.map((eduHistory) => (
                <TableRow key={eduHistory.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                    <Table.Cell width="1" textAlign="center">
                        {eduHistory.id}
                    </Table.Cell>
                    <Table.Cell width="4" textAlign="center">
                        {eduHistory.board_name}
                    </Table.Cell>
                    <Table.Cell width="1" textAlign="center">
                        {eduHistory.create_at.split("T")[0]}
                    </Table.Cell>
                </TableRow>
            ));
        } else {
            eduHistorys = (
                <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                    <Table.Cell colSpan="3" textAlign="center">
                        신청내역이 없습니다.
                    </Table.Cell>
                </TableRow>
            );
        }

        return (
            <>
                <Topmenu />
                <div style={{ minHeight: "1900px", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className={styles.stars}></div>
                    <div className={styles.twinkling}></div>
                    {/* <div className={styles.clouds}></div> */}
                    {/*  */}
                    {/*  */}
                    <div className={styles.login_form_wrap} style={{ marginBottom: "10px", overflowY: "scroll" }}>
                        <div className={styles.login_form_div} style={{ width: "800px", maxWidth: "800px" }}>
                            <h1 style={{ fontSize: "2em", marginBottom: "50px", fontFamily: "Noto Sans KR", color: "white" }}>{t("mypage.MemberMyPage.Title")}</h1>

                            <Form onSubmit={this.handleSubmit}>
                                <h1 style={{ fontFamily: "Noto Sans KR", color: "#FFFFFF" }}>{t("mypage.MemberMyPage.ProfileText")}</h1>
                                <Segment>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Province")}</div>
                                            <div style={{ width: "90%" }}>{this.state.profile.province_text}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Region")}</div>
                                            <div style={{ width: "90%" }}>{this.state.profile.region_text}</div>
                                        </div>
                                    </Form.Field>

                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Team_Name")}</div>
                                            <div style={{ width: "90%" }}>{this.state.profile.team_text}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Name")}</div>
                                            <div style={{ width: "90%" }}>{this.state.profile.member_text}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Birth_Day")}</div>
                                            <div style={{ width: "90%" }}>{this.toDate(this.state.profile.birth_date)}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Id")}</div>
                                            <div style={{ width: "90%" }}>{this.state.user_name}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.General&Youth")}</div>
                                            {this.state.profile.kind === 1 && <div style={{ width: "90%" }}>{t("mypage.MemberMyPage.General")}</div>}
                                            {this.state.profile.kind === 2 && <div style={{ width: "90%" }}>{t("mypage.MemberMyPage.Youth")}</div>}
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.Position")}</div>
                                            <div style={{ width: "90%" }}>{this.state.profile.member_position.position_text}</div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.mypage}>
                                            <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.DSPlayerLicense")}</div>
                                            <div style={{ width: "90%" }}>
                                                {this.state.profile.auth_text} {this.state.profile.cert_grade}급
                                            </div>
                                        </div>
                                    </Form.Field>
                                    {this.state.profile.leader_certificate !== 0 && (
                                        <Form.Field>
                                            <div className={styles.mypage}>
                                                <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.LeaderCert")}</div>
                                                <div style={{ width: "90%" }}>{this.state.profile.leader_certificate}급</div>
                                            </div>
                                        </Form.Field>
                                    )}
                                    {this.state.profile.referee_certificate !== 0 && (
                                        <Form.Field>
                                            <div className={styles.mypage}>
                                                <div style={{ width: "170px", marginRight: "20px" }}>{t("mypage.MemberMyPage.RefereeCert")}</div>
                                                {this.state.profile.referee_certificate === 4 ? <div style={{ width: "90%" }}>국제심판</div> : <div style={{ width: "90%" }}>{this.state.profile.referee_certificate}급</div>}
                                            </div>
                                        </Form.Field>
                                    )}
                                    {/* 심판, 지도사 자격증 생길 시 추가 */}
                                </Segment>
                                <h1 style={{ fontFamily: "Noto Sans KR", color: "#FFFFFF" }}>{t("mypage.MemberMyPage.EditPofile")}</h1>
                                <Segment>
                                    <Form.Field required>
                                        <div className={styles.profileRow}>
                                            <label className={styles.modifiy}>
                                                <span>선수자격번호</span>
                                            </label>
                                            <Input placeholder="선수자격번호를 입력하세요." id="member_id" defaultValue={this.state.profile.member_id} required />
                                        </div>
                                    </Form.Field>
                                    <Form.Field required>
                                        <div className={styles.profileRow}>
                                            <label className={styles.modifiy}>
                                                <span>{t("mypage.MemberMyPage.Phone_Number")}</span>
                                            </label>
                                            <Input
                                                placeholder="-없이 입력하세요."
                                                type="number"
                                                id="phone"
                                                name="phone"
                                                maxLength="20"
                                                // onChange={this.handlePhoneChange}
                                                defaultValue={this.state.profile.member_phone.split("-").join("")}
                                                required
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field required>
                                        <div className={styles.profileRow}>
                                            <label className={styles.modifiy}>
                                                <span>{t("mypage.MemberMyPage.Email")}</span>
                                            </label>
                                            <Input
                                                className={styles.profileRow}
                                                placeholder="dronesoccer@naver.com"
                                                type="text"
                                                id="email_input"
                                                name="member_email"
                                                maxLength="40"
                                                defaultValue={this.state.profile.member_email}
                                                // onChange={this.handleEmailChange}
                                                required
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}>
                                            {this.state.isDuplicateEmail === true && <div>{this.state.errors.duplicate_email}</div>}
                                            {this.state.isDuplicateEmail === false && <div>{this.state.errors.duplicate_email}</div>}
                                            <a className={`${styles.style_a1} ${styles.mouse_hover} `} style={{ marginTop: "5px", width: "fit-content" }} onClick={this.handleDuplicateEmailCheck}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                {t("mypage.MemberMyPage.Dup_Email")}
                                            </a>
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div className={styles.profileRow}>
                                            <label className={styles.modifiy}>
                                                <span>{t("mypage.MemberMyPage.Profile")}</span>({t("mypage.MemberMyPage.LimitImage")})
                                            </label>
                                            <Input className={styles.profileRow} id="profileImage" type="file" name="profile" onChange={this.handleImageChange} accept="image/png, image/jpeg" />
                                        </div>
                                        {this.state.profile.image !== null && (
                                            <div className={styles.profileRow} style={{ marginTop: "7px" }}>
                                                <Segment className={styles.profileRow}>
                                                    <List.Item className={styles.Text}>{this.state.profile.image}</List.Item>
                                                </Segment>
                                            </div>
                                        )}
                                        {this.state.profile.image === null && (
                                            <div className={styles.profileRow} style={{ marginTop: "7px" }}>
                                                <Segment className={styles.profileRow}>
                                                    <List.Item className={styles.Text}>{t("mypage.MemberMyPage.No_Profile")}</List.Item>
                                                </Segment>
                                            </div>
                                        )}
                                    </Form.Field>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <a className={`${styles.style_a1} ${styles.mouse_hover} `} style={{ marginTop: "20px" }} onClick={() => this.setState({ pwdChangeModalOpen: true })}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("find_password.SendMail.Change_Password")}
                                        </a>
                                        <a className={`${styles.style_a2} ${styles.mouse_hover} `} style={{ marginTop: "20px" }} onClick={this.handleSubmit}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("mypage.MemberMyPage.Modifiy")}
                                        </a>
                                    </div>
                                </Segment>
                            </Form>

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ width: "100%", marginTop: "26px", marginBottom: "14px" }}>
                                    <h1 style={{ width: "100%", textAlign: "left", fontFamily: "Noto Sans KR", color: "rgb(255, 255, 255)" }}>{t("mypage.MemberMyPage.Qualification")}</h1>
                                </div>
                                <Segment style={{ margin: "0px" }}>
                                    <Table striped unstackable>
                                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                            <Table.Row style={{ width: "100%" }}>
                                                <Table.HeaderCell width="1" textAlign="center">
                                                    no
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width="4" textAlign="center">
                                                    {t("mypage.MemberMyPage.QualificationDetail")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width="1" textAlign="center">
                                                    {t("mypage.MemberMyPage.QualificationDate")}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>{eduHistorys}</Table.Body>
                                    </Table>
                                </Segment>
                            </div>

                            <div style={{ marginTop: "50px" }}>
                                <hr style={{ border: "1px solid white", width: "100%" }} />
                                <h1 style={{ color: "#FFFFFF", margin: "5px" }}>{t("mypage.MemberMyPage.Print")}</h1>
                                <div>
                                    <a className={`${styles.style_a1} ${styles.mouse_hover} `} onClick={() => this.setState({ certificateModalOpen: true })}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        {t("mypage.MemberMyPage.Certificate")}
                                    </a>
                                </div>
                            </div>
                            {sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("level") === "210" ? (
                                <div style={{ marginTop: "50px" }}>
                                    <hr style={{ border: "1px solid white", width: "100%" }} />
                                    <h1 style={{ color: "#FFFFFF", margin: "5px" }}>{t("mypage.MemberMyPage.Admin")}</h1>
                                    <div>
                                        <a className={`${styles.style_a1} ${styles.mouse_hover} `} onClick={() => (window.location.href = "/management")}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("mypage.MemberMyPage.AdminBtn")}
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
                <CertificateModal isOpen={this.state.certificateModalOpen} closeMethod={this.handleCertificateModalClose} />
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <PasswordChangeModal isOpen={this.state.pwdChangeModalOpen} profileId={this.state.member_pk} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default withTranslation()(MemberMyPage);
