import React, { Component } from 'react';
import { Button, Confirm, Header, Icon, Segment } from 'semantic-ui-react';
import styles from './EducationApply.module.css';
import Common from '../../Common.js';
import ModalChoiceOne from "../../warning_modal/ModalChoiceOne.js";

class EducationApply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText: '',
        }
    }
    
    postEduApplyData = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('profile_id', sessionStorage.getItem('id'))
        formData.append('board_id', this.props.boardId)

        fetch(Common.backendUrl + `/board/api/eduapply/`, {
            method: 'post',
            body: formData
        })
        .then(response => {
            return response.json();
        }).then(data => {
            this.setState({
                warningModalOpen: true,
                warningText: data.message,
            })
        })
    }




    warningModalCloseMethod = () => {
        this.setState({
            warningModalOpen: false,
        })
        window.location.reload();
    }

    render() {
        return (
            <>
            <Confirm className={styles.wrap_div}
                open={this.props.isOpen}
                header={
                    <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px" }}>
                        <Icon name='info' color="yellow" style={{ margin: "0px", fontSize: "15pt" }} />
                    </Header>
                }

                content={
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', padding: "21px" }}>
                        <p style={{ textAlign: "left", fontSize: "10pt", fontFamily: "Noto Sans KR", fontWeight: "bold", color: "black" }}>
                            이름 : {this.props.applicantInfo.member_text}<br/><br/>
                            전화번호 : {this.props.applicantInfo.member_phone}<br/><br/>
                            이메일 : {this.props.applicantInfo.member_email}<br/><br/>
                        </p>
                        <p>
                            위 정보로 신청하시겠습니까??<br />
                        다른 정보가 있다면 정보 갱신 후 신청하세요.
                    </p>
                    </div>
                }

                confirmButton={
                    // <Button color='red' inverted onClick={() => this.props.confirmMethd()}>
                    <Button color='red' inverted onClick={(e) => this.postEduApplyData(e)}>
                        <Icon name='remove' /> 확인
                    </Button>
                }

                cancelButton={
                    <Button color='green' inverted onClick={() => this.props.closeMethod()}>
                        <Icon name='remove' /> 닫기
                    </Button>
                }
                size="mini"
            />
            <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.warningModalCloseMethod} />
            </>
        );
    }
}

export default EducationApply;