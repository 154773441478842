import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';

class BoardFilesMobile extends Component{
    constructor(props) {
        super(props);
    }
    
    render() {
        const { t } = this.props;
        return (
            <div style={{ margin: "5px", fontSize: "12px", width: "100vw"}}>
                <p style={{ margin: "0px", paddingLeft: "5px", width: "100%" }}>{t('board.notice.Attachments')}</p>
                {this.props.files}
            </div>
        );
    }
}

export default withTranslation()(BoardFilesMobile);