import React, { Component } from 'react';
import styles from "./TestPage.module.css";

class TestPage extends Component {
    render() {
        return (
            <div className={styles.wrap_div}>
            <img className={styles.back_img} src="/images/payment/certificate_logo.png" alt="back image" />

    
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
            <p>testPage testPage testPage</p>
          </div>
        );
    }
}

export default TestPage;