import React, { Component } from "react";
import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";
import styles from "./NationalPlayerMain.module.css";
import Common from "../Common";
import { Card, Icon, Image } from "semantic-ui-react";

class NationalPlayerMain extends Component {
  constructor() {
    super();
    this.state = {
      profiles: "",
    };
  }

  componentDidMount = () => {
    if (!this.props?.noPageTitle) {
      document.title = "국가대표 - 대한드론축구협회";
    }
    this.getNationalProfileData();
  };

  getNationalProfileData = () => {
    fetch(Common.backendUrl + "/api/player/national/profile")
      .then((res) => {
        if (res.status > 400) {
          this.handleWarningModalOpen("error❗❗");
        }
        return res.json();
      })
      .then((profiles) => {
        this.setState({
          profiles,
        });
      });
  };

  render() {
    const { profiles } = this.state;
    const crntYear = new Date().getFullYear();

    return (
      <div className={styles.wrap_div}>
        <div className={styles.title_div}>
          <h1>국가대표선수</h1>
        </div>
        <div>
          <div className={styles.content_div}>
            {profiles.length !== 0 ? (
              profiles.map((profile) => (
                <div className={styles.card_wrap}>
                  <div className={styles.card_content}>
                    <div>
                      <p
                        className={styles.card_description}
                        style={{ marginBottom: "7px" }}
                      >
                        Player
                      </p>
                      <p className={styles.card_player_name_text}>
                        {profile.member_text}
                      </p>
                    </div>
                    <div>
                      <p
                        className={styles.card_description}
                        style={{ marginBottom: "5px" }}
                      >
                        {profile.member_position.position_text}
                      </p>
                      <p className={styles.card_description}>
                        {crntYear} | 국가대표
                      </p>
                    </div>
                  </div>
                  {profile.image_file === null ? (
                    <Image
                      src="/images/default_profile.png"
                      alt="profile image"
                      width={220}
                      height={270}
                      style={{
                        border: "1px solid #0E1B37",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    />
                  ) : (
                    <Image
                      src={profile.image_file}
                      width={220}
                      height={270}
                      alt="profile image"
                    />
                  )}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NationalPlayerMain;
