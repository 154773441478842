import React, { Component } from 'react';
import AuthLevelTab from "./AuthLevelTab";
import PlayerLevelTimeRank from "./PlayerLevelTimeRank.js"
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./RankPlayer.module.css";
import { Grid } from 'semantic-ui-react';
import Common from '../Common';
import {withTranslation} from 'react-i18next';

class RankPlayer extends Component {    
      constructor(props) {
        super(props)
          this.state = {
            levelType: 3,
            authOptions: [],
            competitions: [],
            radioGroup: {
              pro: true,
              amature: false,
              rookie: false
            },
          }
        this.handleRadio = this.handleRadio.bind(this);
      }
      
      getAuthLevel(){
        fetch(Common.backendUrl + "/api/authlevel/")
          .then(response => {
            if (response.status > 400) {
              return this.setState(() => {
                return { placeholder: "Something went wrong!" };
              });
            }
            return response.json();
          })
          .then(data=>{
            this.setState({authOptions: data});
          })
      }

      selLevelTpyeHandler(selNum) {
        this.setState({ levelType: selNum });
      }

      componentDidMount(){
        this.getAuthLevel();
      }

      handleRadio(event) {
        let obj = {}
        obj[event.target.value] = event.target.checked // true
        
        if (event.target.value === 'pro') {
          this.setState({ levelType: this.state.authOptions[2].id })
        }

        if (event.target.value === 'amature') {
          this.setState({ levelType: this.state.authOptions[1].id })
        }

        if (event.target.value === 'rookie') {
          this.setState({ levelType: this.state.authOptions[0].id })
        }
        this.setState({radioGroup: obj})
      }
      
      render() {
        window.scrollTo(0, 0);
        const { t } = this.props;
        return (
          <>
            <Topmenu />
            <div className={styles.rank_div}>
              <div className={styles.rank_wrap}>
                <h1 style={{ textAlign:'center', marginBottom: '50px', fontSize: "56px" }}>{t('rank.RankPlayer.Personal_Ranking')}</h1>
                <div className={styles.levelWrap}>
                  <AuthLevelTab onSellevelType={(selNum) => this.selLevelTpyeHandler(selNum)} />
                </div>
              <Grid>
                <Grid.Row centered>
                  <PlayerLevelTimeRank level_type_id={this.state.levelType} />
                </Grid.Row>
              </Grid> 
             </div>
            </div>
            <Footer />
          </>
        )
      }
}

export default withTranslation()(RankPlayer);