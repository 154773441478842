import React, { Component } from "react";
import { Button, Form, Grid, Header, Confirm, Segment } from 'semantic-ui-react'
import styles from "./FindPassword.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer"
import Common from "../Common";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import {withTranslation} from 'react-i18next';

class FindPassword extends Component {
    constructor(props) {
        super(props);
         this.state = {
            member_text: "",
            member_email: "",
            member_id: "",
            warningModalOpen: false,
            warningText: "",
            warningLocation: false,
        };
    }

    handleMemberEmailChange = (e, { value }) => {
      this.setState({ member_email: value });
    };

    handleMemberIdChange = (e, { value }) => {
      this.setState({ member_id: value });
    };

    handleMemberTextChange = (e, { value }) => {
      this.setState({ member_text: value });
    };

    SendEmail(){
      let form_data = new FormData();
      const { t } = this.props;
      form_data.append('email', this.state.member_email);
      
      fetch(Common.backendUrl + '/api/password_reset/', {
        method: 'POST',
        body: form_data
      })
      .then(response => {
        if (response.status == 200) {
          this.setState({
            warningLocation: true,
          })
          
        } else {
          this.handleWarningModalOpen(t('find_password.FindPassword.fail_send'))
        }
      })
    };

    handleFindPassword = e => {
      e.preventDefault();
      const { t } = this.props;
      if(this.state.member_id === ""){
        this.handleWarningModalOpen(t('find_password.FindPassword.Id_Alert'));
        return;
      }

      if(this.state.member_text === ""){
          this.handleWarningModalOpen(t('find_password.FindPassword.Name_Alert'));
          return;
      }

      if(this.state.member_email === ""){
        this.handleWarningModalOpen(t('find_password.FindPassword.Email_Alert'));
        return;
    }

      let form_data = new FormData();

      form_data.append('username', this.state.member_id);
      form_data.append('member_text', this.state.member_text);
      form_data.append('email', this.state.member_email);
      
      fetch( Common.backendUrl + '/api/findpassword/', {
        method: 'post',
        body: form_data
      })
      .then(response => {
        if (response.status >= 400) {
          this.handleWarningModalOpen("잘못된 정보입니다.");
          return;
        } else if (response.status === 200) {
          this.handleWarningModalOpen(t('find_password.FindPassword.Send_Maill'));
          this.setState({
            warningLocation: true,
          })
          this.SendEmail();
          return;
        }
      })
    };

    handleWarningModalOpen = (text) => {
      this.setState({
        warningModalOpen: true,
        warningText: text,
      })
    }
  
    
  handleWarningModalClose = () => {
      this.setState({
          warningModalOpen: false,
      })

      if (this.state.warningLocation){
        document.location.href = '/sendemail/';
      }
  }

    render() {
      const { t } = this.props;
      return (
        <>
          <Topmenu />
          <div style={{minHeight: "1080px", backgroundColor: "red", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <div className={styles.stars}></div>
          <div className={styles.twinkling}></div>
          <div className={styles.clouds}></div>

          <div className={styles.login_form_wrap}>
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450 }}>
                <Form size='large' >
                  <Segment className={styles.login_form_div} textAlign='left'>
                    <Header as='h2' inverted color='grey' textAlign='center' style={{marginBottom: "50px"}}>
                    {t('find_password.FindPassword.Find_Password')}
                    </Header>
                    <label className={styles.label}><span>{t('find_password.FindPassword.Id')}</span></label>
                    <Form.Input
                      fluid
                      icon='user'
                      iconPosition='left'
                      placeholder={t('find_password.FindPassword.Id_Alert')}
                      style={{marginTop: '5px'}}
                      onChange={this.handleMemberIdChange}
                    />
                    <label className={styles.label}><span>{t('find_password.FindPassword.Name')}</span></label>
                     <Form.Input
                      fluid
                      icon='user md'
                      iconPosition='left'
                      placeholder={t('find_password.FindPassword.Name_Alert')}
                      style={{marginTop: '5px'}}
                      onChange={this.handleMemberTextChange}
                    />
                    <label className={styles.label}><span>{t('find_password.FindPassword.Email')}</span></label>
                     <Form.Input
                      fluid
                      icon='mail'
                      iconPosition='left'
                      placeholder={t('find_password.FindPassword.Email_Alert')}
                      style={{marginTop: '5px'}}
                      onChange={this.handleMemberEmailChange}
                    />
                    <a className={`${styles.style_a} ${styles.mouse_hover} `} onClick={this.handleFindPassword} style={{textAlign:'center', width: "100%"}} >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      {t('find_password.FindPassword.Next')}
                    </a>
                  </Segment>
                </Form>

              </Grid.Column>
            </Grid>
          </div>
          </div>
          <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
          <Footer styles={{ backgroundColor: "red" }} />
        </>
      );
    }
}   

export default withTranslation()(FindPassword);
