import React, { Component } from "react";
import {
  Header,
  Segment,
  Table,
  TableRow,
} from 'semantic-ui-react'

class CompetitionRank extends Component {

  render() {
    let list = []
    let i = 1
    list[0] = this.props.competitionRankData.map(
      (info, index) => (
        index === this.props.competitionRankData.length -1 ?
          <TableRow>
            <Table.Cell textAlign='center'>{i++}</Table.Cell>
            <Table.Cell textAlign='center'>{info.team.team_text}</Table.Cell>
            <Table.Cell textAlign='center'>{info.ds_point}</Table.Cell>
          </TableRow>
          :
          <TableRow>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{i++}</Table.Cell>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.team.team_text}</Table.Cell>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.ds_point}</Table.Cell>
          </TableRow>
      )
    )

    return (
      <div>
          <Header as='h3' textAlign='center'>최종순위</Header>
          <Table striped unstackable style={{border: "1px solid #000000"}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>순위</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>팀명</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>DS포인트</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {list[0]}
            </Table.Body>
          </Table>
      </div>
    )
  }
}

export default CompetitionRank