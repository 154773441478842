import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

function PrivateRoute({ component: Component, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);

  const closeMothod = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Route
        {...rest}
        render={props =>
          sessionStorage.getItem('token') ? (
            <Component {...props} />
          ) : (
              <>
                {setIsOpen(true)}
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location }
                  }}
                />
              </>
            )
        }
      />
      <ModalChoiceOne isOpen={isOpen} warningText="로그인이 필요합니다." closeMethod={closeMothod} />
    </>
  );
}


export default PrivateRoute;