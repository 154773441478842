import React, { Component } from "react";
import ProvinceDetail from "./ProvinceDetail.js";
import ProvinceModalForm from "./ProvinceModalForm.js";
import RegionDetail from "./RegionDetail.js";

import { Container, Dropdown, Grid, Button } from "semantic-ui-react";
import Common from "../../../Common";

class ProvinceRegionManager extends Component {
    constructor() {
        super();
        this.state = {
            provinceData: "",
            selProvinceData: "",
            provinceOptions: "",
            selRegionData: "",
            selProvinceId: 1001,
            selRegionId: 1001,
        };
    }

    getAllProvinceData() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ provinceData: data });
                let arr = [];
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getProvinceData = (provinceId) => {
        fetch(Common.backendUrl + `/api/province/detail/${provinceId}`)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ selProvinceData: data });
                this.getRegionData(provinceId);
            });
    };

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    selRegionData: data,
                });
            });
    }

    provinceChange = (e, { value }) => {
        this.setState({ selProvinceId: value });
        this.getProvinceData(value);
    };

    // regionChange = (e, { value }) => {
    //     this.setState({ selRegionId: value });
    // };

    componentDidMount() {
        this.getAllProvinceData();
        this.getProvinceData(1001);
        // this.getRegionData(1001);
    }

    render() {
        return (
            <div>
                <Container style={{ paddingLeft: "5em", paddingRight: "5em", height: "100%" }} fluid>
                    <Grid divided="vertically">
                        <Grid.Row columns={"equal"} divided>
                            <Grid.Column textAlign="center">
                                <ProvinceModalForm />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.provinceChange} options={this.state.provinceOptions} defaultValue={1001} selection placeholder="지회목록" fluid />
                            </Grid.Column>
                            {/* <Grid.Column >
                                <Dropdown onChange={this.regionChange} options={this.state.regionOptions} defaultValue={1001} selection placeholder='지부목록' fluid />
                            </Grid.Column> */}
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                {/* <ProvinceDetail province_id={this.state.selProvinceId} reloadPage={this.getAllProvinceData} /> */}
                                <ProvinceDetail selProvinceData={this.state.selProvinceData} reloadData={() => this.getProvinceData(this.state.selProvinceId)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <RegionDetail selRegionData={this.state.selRegionData} reloadData={() => this.getRegionData(this.state.selProvinceId)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default ProvinceRegionManager;
