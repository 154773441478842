import React, { Component } from 'react';
import Common from '../Common.js';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { parse } from 'query-string';

class NiceSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modalText: '',
            isSuccessLocation: false,
        }
    }
    
    componentDidMount = () => {
        // alert(this.props.location.search.split('?EncodeData=')[1])
        // alert("here is react")
        // alert(window.location.href)
        // alert(window.location.search)
        // alert(window.location.pathname)
        // alert(this.props.location.search.split("?EncodeData=")[1])
        // alert(this.props.history.location.search.split("?EncodeData=")[1]);
        this.postSuccessDataforGet(decodeURIComponent(window.location.href.split("?EncodeData=")[1]))
    }

    postSuccessDataforGet = (encodeData) => {
        let formData = new FormData();
        formData.append('EncodeData', encodeData)
        
        fetch(Common.backendUrl + `/api/checkplus_success/`, {
            method: "POST",
            body: formData
        })
            .then(response => {
                if (response.status >= 400) {
                    alert('not success');
                    return;
                } else {
                    // alert('success');
                    return response.json();
                }
            })
            .then(json => {
                // console.log(json)
                // sessionStorage.setItem("enc_data", json.enc_data)
                if (json.results.responsenumber.length > 0){
                    this.setState({isOpen: true})
                    const today = new Date();
                    const crntYear = today.getFullYear();
                    const userBirth = new Date(json.results.birthdate.slice(0,4), json.results.birthdate.slice(4,6), json.results.birthdate.slice(6,8));
                    const userYear = userBirth.getFullYear();
                    const todayMonth = parseInt(today.getMonth())+1;
                    
                    if (crntYear - userYear <= 14) {
                        if (todayMonth > parseInt(json.results.birthdate.slice(4,6))) {
                            this.setState({
                                isOpen: true,
                                modalText: '인증에 실패하였습니다.'
                            })
                        }
                    }else {
                        document.cookie = 'userinfo=' + json.results.name + '/'  + json.results.mobileno + '/' + json.results.dupinfo + '/' + json.results.birthdate;
                        this.setState({
                            isOpen: true,
                            modalText: '인증되었습니다.',
                            isSuccessLocation: true,
                        })
                    }
                    
                }
            })

    }

    closeMethod = () => {
        this.setState({
            isOpen: false,
        })
        if (this.state.isSuccessLocation){
            window.opener.location.href='/signup'
            window.close();
        } else{
            window.opener.location.href='/signup/check'
            window.close();
        }
        
    }



    render() {

        return (
            <Modal
            open={this.state.isOpen}
            size='mini'
          >
            
            <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px" }}>
              <Icon name='info' color="yellow" style={{ margin: "0px", fontSize: "15pt" }} />
            </Header>
            <Modal.Content>
              <p style={{ textAlign: "center", fontSize: "10pt", fontFamily: "Noto Sans KR", fontWeight: "bold", color: "black" }}>
                {this.state.modalText}
              </p>
            </Modal.Content>
            <Modal.Actions style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Button color='green' inverted onClick={() => this.closeMethod()}>
                <Icon name='remove' /> 닫기
              </Button>
            </Modal.Actions>
          </Modal>
        );
    }
}

export default NiceSuccess;