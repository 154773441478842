import React, { Component } from 'react';
import styles from "./competition.module.css";
import {
  Tab,
  Table,
} from 'semantic-ui-react'



class CompetitionFirstRoundResultTap extends Component {

  setGameListTap() {
    const panes = [
      {
        menuItem: '예선 경기 결과',
        render: () =>
          <Tab.Pane key='tab1'>
            <Table striped unstackable style={{border: "1px solid #000000"}}>
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1}>경기분류</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'><div className={styles.redHederText}>RED팀</div></Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'><div className={styles.blueHederText}>BLUE팀</div></Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>1세트</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>2세트</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>3세트</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>승리팀</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>경기시간</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>경기장소</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>경기상태</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>경기관리</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width={1} textAlign='center'>점수관리</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.props.list}
              </Table.Body>
            </Table>
          </Tab.Pane>
      },
      {
        menuItem: '조별 순위',
        render: () =>
          <Tab.Pane key='tab2'>
            
            {this.props.result}
            
          </Tab.Pane>
      },
    ]
    return panes
  }

  render() {
    return (
      <div>
        <Tab panes={this.setGameListTap()} />
      </div>
    )
  }
}

export default CompetitionFirstRoundResultTap