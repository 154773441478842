import React, { Component } from "react";

import {
    Button,
    Modal,
    Input,
    Dropdown,
    Form,
} from 'semantic-ui-react'
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from '../../../Common';

class ProvinceModalForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            modal_open: false,
            province_text: '',
            chief_name: '',
            phone_text: '',
            email_text: '',
            isReload: false,
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        const form_data = new FormData();

        form_data.append('province_text', this.state.province_text)
        form_data.append('chief_name', this.state.chief_name)
        form_data.append('phone_text', this.state.phone_text)
        form_data.append('email_text', this.state.email_text)

        fetch(Common.backendUrl + `/api/province/`, {
            method: 'post',
            body: form_data,
        })
            .then(response => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen("error");
                    return;
                } else {
                    this.handleWarningModalOpen("지회 등록 완료");
                    this.setState({ modal_open: false, isReload: true });
                    return response.json();
                }
            })
    };


    handleProvinceTextChange = (e, {value}) => {
        e.preventDefault();
        this.setState({
            province_text: value
        })
    }
    handleChiefNameChange = (e, {value}) => {
        e.preventDefault();
        this.setState({
            chief_name: value
        })
    }
    handlePhoneTextChange = (e, {value}) => {
        e.preventDefault();
        this.setState({
            phone_text: value
        })
    }
    handleEmailChange = (e, {value}) => {
        e.preventDefault();
        this.setState({
            email_text: value
        })
    }



    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
        if(this.state.isReload) {
            window.location.reload();
        }
    }

    render() {

        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button primary>지회등록</Button>}
                >
                    <Modal.Header>지회정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field required>
                                <label className="label">지회명</label>
                                    <Input
                                        type="text"
                                        name="province_text"
                                        maxLength='50'
                                        placeholder="지회명을 입력하세요."
                                        onChange={this.handleProvinceTextChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field required>
                                <label className="label">지회장</label>
                                    <Input
                                        type="text"
                                        name="chief_name"
                                        maxLength='50'
                                        placeholder="지회장을 입력하세요."
                                        onChange={this.handleChiefNameChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field required>
                                <label className="label">연락처</label>
                                    <Input
                                        type="text"
                                        name="phone_text"
                                        maxLength='50'
                                        placeholder="전화번호를 입력해주세요(-생략)"
                                        onChange={this.handlePhoneTextChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field required>
                                <label className="label">e-mail</label>
                                    <Input
                                        type="text"
                                        name="email_text"
                                        maxLength='50'
                                        placeholder="dronesoccer@naver.com"
                                        onChange={this.handleEmailChange}
                                        required
                                    />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">등록</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default ProvinceModalForm