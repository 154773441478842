import React, { Component } from "react";

import SemanticDatepicker from "react-semantic-ui-datepickers";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

import { Button, Modal, Input, Dropdown, Form, TextArea } from "semantic-ui-react";

import Common from "../Common";

class CompetitionModalForm extends Component {
    state = {
        selCompetitionType: 1,
        competitionTypeOptions: [],
        competition_text: "",
        registration_start_date: "",
        registration_end_date: "",
        competition_start_date: "",
        competition_end_date: "",
        competition_state_id: "",
        image_file: "",
        modal_open: false,
        warningModalOpen: false,
        warningText: "",
    };

    componentDidMount() {
        this.getCompetitionStateList();
        this.getCompetitionTypeOptions();
    }
    getCompetitionTypeOptions = () => {
        fetch(Common.backendUrl + "/api/competition/type/", {
            method: "get",
        })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요");
                }
                return res.json();
            })
            .then((json) => {
                const competitionTypeOptions = [];
                json.map((type) => {
                    if (type.competition_type === "종합") {
                        return;
                    }
                    competitionTypeOptions.push({ key: type.id, text: type.competition_type, value: type.id });
                });

                this.setState({
                    competitionTypeOptions,
                    selCompetitionType: competitionTypeOptions[0].key,
                });
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    getCompetitionStateList() {
        fetch(Common.backendUrl + "/api/competitionstate/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((state) => arr.push({ key: state.id, text: state.state_text, value: state.id }));
                this.setState({ competitionStateOptions: arr });
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    dateHandleChange1 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate();
            this.setState({
                competition_start_date: strDate,
            });
        }
    };

    dateHandleChange2 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate();
            this.setState({
                competition_end_date: strDate,
            });
        }
    };

    registDateHandleChange1 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "00:00:00.000000";
            this.setState({
                registration_start_date: strDate,
            });
        }
    };

    registDateHandleChange2 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "23:59:59.000000";
            this.setState({
                registration_end_date: strDate,
            });
        }
    };

    handleCompetitionStateChange = (e, { value }) => {
        this.setState({ competition_state_id: value });
    };

    handleCompetitionTypeChange = (e, { value }) => {
        this.setState({ selCompetitionType: value });
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.competition_state_id === "") {
            this.handleWarningModalOpen("대회진행상태를 선택해주세요.");
            return;
        } else if (this.state.image_file === "") {
            this.handleWarningModalOpen("대회포스터를 등록해주세요.");
            return;
        }

        let form_data = new FormData();

        form_data.append("competition_image", this.state.image_file.name);
        form_data.append("competition_image_file", this.state.image_file);

        form_data.append("competition_text", this.state.competition_text);
        form_data.append("registration_start_date", this.state.registration_start_date);
        form_data.append("registration_end_date", this.state.registration_end_date);
        form_data.append("competition_start_date", this.state.competition_start_date);
        form_data.append("competition_end_date", this.state.competition_end_date);
        form_data.append("competition_state_id", this.state.competition_state_id);
        form_data.append("place", document.getElementById("competition_place").value);
        if (document.getElementById("competition_info").value !== "") {
            form_data.append("competition_info", document.getElementById("competition_info").value);
        }
        form_data.append("competition_type_id", this.state.selCompetitionType);

        fetch(Common.backendUrl + "/api/competition/make/", {
            method: "post",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    if (response.status === 403) {
                        throw new Error("1500x1500 이하의 포스터를 업로드해주세요.");
                    } else if (response.status === 406) {
                        throw new Error("허용되지 않는 포스터 이미지 형식입니다.");
                    } else {
                        throw new Error("오류가 발생했습니다.\n관리자에게 문의해주세요.");
                    }
                }

                return response.json();
            })
            .then((data) => {
                this.handleWarningModalOpen("대회등록 완료");
                this.setState({ modal_open: false });
                this.props.reloadPage(data.id);
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        // const { competition_text } = this.state;
        return (
            <>
                <Modal size={"tiny"} onClose={() => this.setState({ modal_open: false })} onOpen={() => this.setState({ modal_open: true })} open={this.state.modal_open} trigger={<Button primary>대회등록</Button>}>
                    <Modal.Header>대회정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">대회종류</label>
                                    <Dropdown value={this.state.selCompetitionType} selection options={this.state.competitionTypeOptions} name="competition_type" fluid onChange={this.handleCompetitionTypeChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회명</label>
                                    <Input
                                        type="text"
                                        name="competition_text"
                                        onChange={this.handleChange}
                                        // value={competition_text}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회접수 시작일</label>
                                    <SemanticDatepicker name="competition_start_date" onChange={this.registDateHandleChange1} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회접수 종료일</label>
                                    <SemanticDatepicker name="competition_end_date" onChange={this.registDateHandleChange2} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회시작일</label>
                                    <SemanticDatepicker name="competition_start_date" onChange={this.dateHandleChange1} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회종료일</label>
                                    <SemanticDatepicker name="competition_end_date" onChange={this.dateHandleChange2} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회안내</label>
                                    <TextArea id="competition_info" />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회장소</label>
                                    <Input id="competition_place" required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회진행상태</label>
                                    <Dropdown placeholder="대회진행상태를 입력하세요." selection options={this.state.competitionStateOptions} name="competition_state" fluid onChange={this.handleCompetitionStateChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회 이미지</label>
                                    <Input type="file" name="profile" onChange={this.handleImageChange} accept="image/*" />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    등록하기
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionModalForm;
