import React, { Component } from "react";

import {
    Button,
    Modal,
    Form,
    TableRow,
    Table
} from 'semantic-ui-react'
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from '../Common';

class CompetitionSecondRoundResultModalForm extends Component {
    state = {
        modal_open: false,
        teamData: "",
        teamScore: [],
        teamGoal: [],
        joinTeam: "",
        warningModalOpen: false,
        warningText: "",
    };

    handleTeamScore(e, id) {
        let teams = document.querySelectorAll(".team_scores");
        let score = []
        for (let i = 0; i < teams.length; i++) {
            score.push({second_round_score:teams[i].value})
        }
        this.setState({
            teamScore : score});
    }

    handleTeamGoal(e, id) {
        let teams = document.querySelectorAll(".team_goals");
        let goal = []
        for (let i = 0; i < teams.length; i++) {
            goal.push({id:teams[i].id, second_round_goal:teams[i].value})
        }
        this.setState({
            teamGoal : goal});
    }


    handleSubmit = e => {
        e.preventDefault();
        
        let form_data = new FormData();

        let second_round_team = this.props.joinTeamList.filter(data => data.second_round_group === 1 || data.second_round_group === 2 )
        
        if (this.state.teamGoal.length > 0 && this.state.teamScore.length > 0 ){
            for (let i = 0; i < this.state.teamGoal.length; i++) {
                form_data.append('id', this.state.teamGoal[i].id);
                form_data.append('second_round_goal', this.state.teamGoal[i].second_round_goal);
                form_data.append('second_round_score', this.state.teamScore[i].second_round_score);    
            };
        }
        
        if (this.state.teamGoal.length === 0 && this.state.teamScore.length > 0) {
            let i = 0 ;
            second_round_team.map(
                info => {
                    form_data.append('id', info.id);
                    form_data.append('second_round_goal', info.second_round_goal);
                    form_data.append('second_round_score', this.state.teamScore[i].second_round_score);
                    i++; 
                }
            );    
        } 

        if (this.state.teamGoal.length > 0 &&  this.state.teamScore.length === 0){
            let i = 0 ;
            second_round_team.map(
                info => {
                    form_data.append('id', this.state.teamGoal[i].id);
                    form_data.append('second_round_goal', this.state.teamGoal[i].second_round_goal);
                    form_data.append('second_round_score', info.second_round_score);
                    i++;
                }
            );    
        }
        
        if (this.state.teamGoal.length === 0 && this.state.teamScore.length == 0) {
            second_round_team.map(
                info => {
                    form_data.append('id', info.id);
                    form_data.append('second_round_goal', info.second_round_goal);
                    form_data.append('second_round_score', info.second_round_score);
                }
            );
        }
        
        fetch(Common.backendUrl + "/api/competitionteam/result/update/",{
            method: 'put',
            body : form_data
        })
            .then(response => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen('error');
                    return;
                } else {
                    this.handleWarningModalOpen("8강 리그 결과 등록 완료");
                    this.setState({ modal_open: false });
                    this.props.reloadPage();
                    return response.json();
                }
            })

    };

    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        let list = []
       
        if (this.props.joinTeamList.length > 0 ) {
            
            let second_round_team = this.props.joinTeamList.filter(data => data.second_round_group === 1 || data.second_round_group === 2 ) 
            second_round_team.sort((a, b) => a['second_round_group'] > b['second_round_group'] ? 1 : ((a['second_round_group'] < b['second_round_group'] ? -1 : 0)))
            
            list[0] = second_round_team.map(
                info => (
                    <TableRow key={info.id}>
                        <Table.Cell textAlign='center'>{info.team.team_text}</Table.Cell>
                        <Table.Cell textAlign='center'>{info.second_round_group}조</Table.Cell>  
                        <Table.Cell>
                        <input
                            type="number"
                            className="team_scores"
                            name="second_round_score"
                            // min={0}
                            defaultValue={info.second_round_score}
                            onChange={(e)=>this.handleTeamScore(e)}
                            required
                        />
                        </Table.Cell>
                        <Table.Cell>
                        <input
                            type="number"
                            className="team_goals"
                            name="second_round_goal"
                            id={info.id}
                            // min={0}
                            defaultValue={info.second_round_goal}
                            onChange={(e)=>this.handleTeamGoal(e)}
                            required
                        />
                        </Table.Cell>
                    </TableRow>
                )
            );
        }
        
        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button size='medium' primary>8강 리그 결과 입력</Button>}
                >
                    <Modal.Header>8강리그 결과</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                            <Table striped unstackable>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={1} textAlign='center'>팀명</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'>조</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'>승점</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'>골득실</Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {list[0]}
                                </Table.Body>
                                </Table>
                                <Button positive type="submit" className="button is-info">등록하기</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionSecondRoundResultModalForm;